import { useMemo, useState } from "react";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { customFetchDelayedMiddleware } from "context/sseContext";
import { RequestMethods } from "utils/customFetch";
import { useSelector } from "react-redux";
import { currencySelectors } from "redux/currency";
import { ProductWithPricesDTO } from "typings/DTO/data-contracts";
import { events, useAmplitudeState } from "context/AmplitudeContext";

const useDownloadSdf = (catalogItemsWithPrices: ProductWithPricesDTO[]) => {
  const { amplitude } = useAmplitudeState();
  const currency = useSelector(currencySelectors.selectCurrent);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const catalogItemCodes = useMemo(
    () =>
      catalogItemsWithPrices
        ? catalogItemsWithPrices
            .map((catalogItem) => catalogItem.product.code)
            .join(" ")
        : "",
    [catalogItemsWithPrices]
  );

  const logDownloadSdf = () => {
    amplitude.logEvent(events.donwload_sdf_click);
  };

  const handleDownload = () => {
    setLoading(true);
    setError(false);
    logDownloadSdf();

    customFetchDelayedMiddleware({
      method: RequestMethods.POST,
      url: "/catalog/search/by/codes/as-file/SDF",
      data: {
        compounds: catalogItemCodes,
        currency: currency.abbr,
      },
    })
      .then((response) => {
        if (response[0]) {
          fileDownload(response[0], `result-search.sdf`);
        } else {
          toast.error(response[1] as string);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    isLoading,
    handleDownload,
    error,
  };
};

export default useDownloadSdf;
