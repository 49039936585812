const darkBlue = "#172B4E";
const darkBlueDisabled = "#7DB9DE";
const borderColor = "#D1D5DC";
const accentColor = "#0076BE";
const lightBlue = "#EBF7FF";
const loadingColor = "#97badb";
const lightBlueDisabled = "#F9FDFF";
const blue = "#51607A";
const lineColor = "#DDDFE2";
const successColor = "#14B571";
const lightSuccess = "#F3FBF8";
const hoverSuccessColor = "#119d62";
const dangerColor = "#D50029";
const hoverDangerColor = "#BD0024";
const lightDanger = "#FFFAFB";
const lightGray = "#F6F7F8";
const warningColor = "#FFC700";
const darkWarningColor = "#FF8A00";
const progressBarLight = "#d9e4f0";
const progressBarDark = "#004e9b";
const whiteColor = "#FFFFFF";
/**
 * @deprecated
 * used in CookieConsent component, which will be redesigned in future
 */
const hoverActiveLinkColor = "#1973aa";

const colors = {
  darkBlue,
  successColor,
  dangerColor,
  borderColor,
  loadingColor,
  blue,
  hoverSuccessColor,
  hoverDangerColor,
  lineColor,
  accentColor,
  lightSuccess,
  lightDanger,
  lightBlue,
  lightBlueDisabled,
  lightGray,
  darkBlueDisabled,
  warningColor,
  darkWarningColor,
  hoverActiveLinkColor,
  progressBarLight,
  progressBarDark,
  whiteColor,
};

export default colors;

export {
  darkBlue,
  borderColor,
  accentColor,
  loadingColor,
  lightBlue,
  blue,
  lineColor,
  successColor,
  hoverSuccessColor,
  lightSuccess,
  dangerColor,
  lightDanger,
  hoverDangerColor,
  lightGray,
  darkBlueDisabled,
  lightBlueDisabled,
  warningColor,
  darkWarningColor,
  hoverActiveLinkColor,
  progressBarLight,
  progressBarDark,
  whiteColor,
};
