import {
  ADD_ITEM,
  ALL_TO_CART,
  CLEAR_CART,
  DELETE_ITEM,
  DELETE_ITEMS,
  POST_CART_CONTENT,
  REQUEST_CART_DELETE_ITEMS,
  REQUEST_CART_INFO,
  REQUEST_ITEMS_AND_IMAGES,
  RESET_CART_INFO,
  SET_CART_INFO,
  SET_ITEMS,
  SET_SUPER_CART,
  SET_SUPER_CART_ERROR,
  SET_SUPER_CART_LOADING,
  UPDATE_ITEM,
} from "./types";
import { PayloadActionWithEvents } from "../../../redux/redux.types";

const setSuperCart = (payload) => {
  return {
    type: SET_SUPER_CART,
    payload,
  };
};

const addItem = (payload) => {
  return {
    type: ADD_ITEM,
    payload,
  };
};

const setItems = (payload) => {
  return {
    type: SET_ITEMS,
    payload,
  };
};

const updateItem = (payload) => {
  return {
    type: UPDATE_ITEM,
    payload,
  };
};

const allToCart = (payload) => {
  return {
    type: ALL_TO_CART,
    payload,
  };
};

const deleteItem = (payload) => {
  return {
    type: DELETE_ITEM,
    payload,
  };
};

const clear = (payload) => {
  return {
    type: CLEAR_CART,
    payload,
  };
};

const resetCartInfo = () => {
  return {
    type: RESET_CART_INFO,
  };
};

const requestCartInfo = (payload) => {
  return {
    type: REQUEST_CART_INFO,
    payload,
  };
};

const setCartInfo = (payload) => {
  return {
    type: SET_CART_INFO,
    payload,
  };
};

const postCartContent = (payload) => {
  return {
    type: POST_CART_CONTENT,
    payload,
  };
};

// V2
const requestItemsAndImages = (payload) => {
  return {
    type: REQUEST_ITEMS_AND_IMAGES,
    payload,
  };
};

// @TODO Specify type
const requestDeleteItems = (payload: PayloadActionWithEvents<any>) => {
  return {
    type: REQUEST_CART_DELETE_ITEMS,
    payload,
  };
};

const deleteItems = (payload) => {
  return {
    type: DELETE_ITEMS,
    payload,
  };
};

const setSuperCartLoading = (isLoading) => {
  return {
    type: SET_SUPER_CART_LOADING,
    payload: isLoading,
  };
};

const setSuperCartError = (isError) => {
  return {
    type: SET_SUPER_CART_ERROR,
    payload: isError,
  };
};

const superCartActions = {
  setSuperCart,
  addItem,
  updateItem,
  allToCart,
  deleteItem,
  setItems,
  clear,
  resetCartInfo,
  requestCartInfo,
  setCartInfo,
  postCartContent,
  // V2
  requestItemsAndImages,
  requestDeleteItems,
  deleteItems,
  setSuperCartLoading,
  setSuperCartError,
};

export default superCartActions;
