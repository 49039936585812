import styled from "@emotion/styled";
import { accentColor } from "ui/colors";

export const PurityWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${accentColor};
`;

export const PurityIconWrapper = styled.div`
  width: 18px;
  height: 18px;
  overflow: hidden;

  svg {
    transform: scale(18 / 22);
    transform-origin: center;
    width: 100%;
    height: 100%;
  }
`;
