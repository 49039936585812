import { Grid } from "@mui/material";
import { endpoints } from "constants";
import { customFetchDelayedMiddleware } from "context/sseContext";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ArrowRight2Icon } from "ui/icons";
import { RequestMethods } from "utils/customFetch";
import { CategoriesContainer, StyledButton } from "./Categories.styled";
import CategoriesCard from "./ui/CategoryCard/CategoryCard";

const MAX_CATEGORIES_TO_SHOW = 6;

type Category = {
  name: string;
  urlName: string;
  description?: string;
};

const Categories = () => {
  const history = useHistory();
  const [categories, setCategories] = useState<Category[] | null>(null);

  const fetchCategories = async () => {
    const response = await customFetchDelayedMiddleware({
      url: `${endpoints.CATALOG_CATEGORIES_HOME}`,
      method: RequestMethods.GET,
    });

    if (response[0]) {
      setCategories(response[0]);
    } else {
      toast.error(response[1] as string);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleViewAll = () => history.push("/categories");

  return (
    Boolean(categories?.length) && (
      <CategoriesContainer>
        <Grid container spacing={2.25} justifyContent={"flex-start"}>
          {categories
            .slice(0, MAX_CATEGORIES_TO_SHOW)
            .map(({ name, urlName, description }) => (
              <Grid
                key={name}
                item
                xs={12}
                sm={categories?.length === 1 ? 12 : 6}
                md={
                  categories?.length === 1
                    ? 12
                    : categories?.length === 2
                    ? 6
                    : categories?.length === 4
                    ? 6
                    : 4
                }
                display="flex"
                justifyContent="center"
              >
                <CategoriesCard
                  name={name}
                  urlName={urlName}
                  description={description}
                />
              </Grid>
            ))}
        </Grid>
        {categories?.length > MAX_CATEGORIES_TO_SHOW && (
          <StyledButton
            variant="outlined"
            endIcon={<ArrowRight2Icon />}
            onClick={handleViewAll}
            size="small"
          >
            View all
          </StyledButton>
        )}
      </CategoriesContainer>
    )
  );
};

export default Categories;
