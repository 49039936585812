import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import { borderColor, blue } from "../../../../ui/colors";

export const TitleTypography = styled(Typography)`
  color: ${borderColor};
`;

export const ImageWrapper = styled(Box)`
  margin-bottom: 56px;
`;

export const DescriptionWrapper = styled(Box)`
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${borderColor};
  border-radius: 8px;
  padding: 100px 40px;
`;

export const SubTitleTypography = styled(Typography)`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: ${blue};
  margin-bottom: 18px;
`;

export const ButtonsWrapper = styled(Box)`
  display: flex;
  gap: 24px;
  & svg path {
    fill: #fff;
  }
`;
