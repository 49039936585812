import styled from "styled-components";

export const PageHeader = styled.div`
  padding: 28px 0;
`;
PageHeader.displayName = "PageHeader";

export const PageSidebar = styled.div`
  box-sizing: border-box;
  width: 416px;
  padding: 24px;
  border-radius: 16px;
  background-color: white;
  margin-bottom: 18px;
`;
PageSidebar.displayName = "PageSidebar";
