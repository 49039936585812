import { events, useAmplitudeState } from "context/AmplitudeContext";
import superCartActions from "features/super-cart/redux/actions";
import { FormikProps, useFormik } from "formik";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { currencySelectors } from "redux/currency";
import { userSelectors } from "redux/user";
import { Price, Weight } from "shared/types/cart";
import { BucketItem } from "shared/types/cartItem";
import { ProductPriceIssue } from "shared/types/pricing";
import { ProductWeightStandard } from "shared/types/product";
import { getCurrentPrice } from "shared/utils/getCurrentPrice";
import { ProductWithPricesDTO } from "typings/DTO/data-contracts";
import { getButtonLabel, makeParams, toastNoCartModify, uuid } from "utils";
import convertCatalogToCat from "utils/convetCatalogToCat";
import { isItemShouldHaveMockImage } from "../lib/REMOVE.utils";

type UseProductCardPropsProps = {
  item: Omit<BucketItem, "addedToBucket">;
  isRelated: boolean;
  proposal: any;
};

export const useProductCardProps = ({
  item,
  isRelated,
  proposal,
}: UseProductCardPropsProps) => {
  const { product, prices } = item;
  const { amplitude } = useAmplitudeState();
  const currency = useSelector(currencySelectors.selectCurrent);
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const dispatch = useDispatch();

  const [isAddingToCart, setAddingToCart] = useState(false);

  const href = useMemo(() => {
    const params = makeParams({ cat: convertCatalogToCat(product?.catalog) });
    return `/catalog/${product?.code}?${params}`;
  }, [product?.code, product?.catalog]);

  const handleAddToCart = useCallback(
    (itemToAdd) => {
      const isCustomWeight =
        itemToAdd?.weight?.type ===
        ProductWeightStandard.WEIGHT_IS_NON_STANDARD;

      logAmplitudeEvent(events.catalog_item_add, {
        amount: itemToAdd.count,
        ...(isCustomWeight && {
          Custom: itemToAdd.weight.amount,
          CustomUOM: itemToAdd.weight.measure,
        }),
      });
      if (isAuth) {
        setAddingToCart(true);
        dispatch(
          superCartActions.postCartContent({
            items: [itemToAdd],
            currency,
            onSuccess: (response) => {
              setAddingToCart(false);
              if (response?.[0]) {
                toast("Product was added to cart!");
              } else if (response?.[2] === 400) {
                toastNoCartModify();
              } else {
                toast.error("Issue with adding product to cart");
              }
            },
          })
        );
      } else {
        const itemLocalStorage = {
          addedToBucket: {
            count: itemToAdd.count,
            weight: itemToAdd.weight,
            uuid: uuid(),
            pay:
              itemToAdd?.weight?.type ===
                ProductWeightStandard.WEIGHT_IS_ENAMINE_STANDARD &&
              prices?.all?.some(
                (price) =>
                  price?.weight?.measure === itemToAdd?.weight?.measure &&
                  price?.weight?.amount === itemToAdd?.weight?.amount &&
                  price?.price !== 0
              )
                ? {
                    fullyCalculated: true,
                    pricePerOneCount: currentPrice?.price,
                    total: currentPrice?.price * itemToAdd.count,
                  }
                : {
                    fullyCalculated: false,
                    cannotCalculate: {
                      reason:
                        itemToAdd?.weight?.type ===
                        ProductWeightStandard.WEIGHT_IS_ENAMINE_STANDARD
                          ? ProductPriceIssue.SOME_PRODUCTS_PRICES_NOT_FOUND
                          : ProductPriceIssue.SOME_PRODUCTS_HAVE_NON_STANDARD_WEIGHT,
                    },
                    total: 0,
                  },
          },
          product,
          prices,
          // @TODO REMOVE THIS LOGIC AFTER DEMO!
          image: isItemShouldHaveMockImage(product.code)
            ? `/images/demoReplaceImages/${product.code}.png`
            : `/api/v2/catalog/all/images/by-code/${product.code}`,
        };
        dispatch(superCartActions.addItem(itemLocalStorage));
        toast("Product was added to cart!");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuth, currency, dispatch, prices, product]
  );

  const formik: FormikProps<{
    counter: number;
    currentWeight: Weight;
    customWeight: Omit<Weight, "type">;
  }> = useFormik({
    enableReinitialize: true,
    initialValues: {
      counter: 1,
      currentWeight: prices?.default?.weight,
      customWeight: {
        measure: "g",
        amount: null,
        type: ProductWeightStandard.WEIGHT_IS_NON_STANDARD,
      },
    },
    validate: (values) => {
      const errors: Partial<{ customWeight?: { amount?: string } }> = {};

      if (
        values.currentWeight.type ===
        ProductWeightStandard.WEIGHT_IS_NON_STANDARD
      ) {
        if (
          values.customWeight.amount === null ||
          values.customWeight.amount <= 0 ||
          values.customWeight.amount > 9999999999
        ) {
          errors.customWeight = {
            amount: "Amount must be between 1 and 9,999,999,999",
          };
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      const weight =
        values.currentWeight.type ===
        ProductWeightStandard.WEIGHT_IS_NON_STANDARD
          ? {
              ...values.customWeight,
              type: prices?.all?.some(
                (price) =>
                  price?.weight?.measure === values.customWeight?.measure &&
                  price?.weight?.amount === values.customWeight?.amount
              )
                ? ProductWeightStandard.WEIGHT_IS_ENAMINE_STANDARD
                : ProductWeightStandard.WEIGHT_IS_NON_STANDARD,
            }
          : values.currentWeight;
      handleAddToCart({
        code: product.code,
        catalog: product.catalog,
        weight,
        count: values.counter,
      });
    },
  });

  const logAmplitudeEvent = useCallback(
    (event: string, additionalData = {}) => {
      amplitude.logEvent(event, {
        itemtype: convertCatalogToCat(product?.catalog),
        related: isRelated,
        Proposal: proposal,
        ...additionalData,
      });
    },
    [amplitude, isRelated, proposal, product?.catalog]
  );

  const currentPrice = useMemo(
    () => getCurrentPrice(prices, formik.values?.currentWeight),
    [prices, formik.values?.currentWeight]
  );

  const readyToCart = useMemo(() => !!currentPrice, [currentPrice]);

  const buttonLabel = useMemo(
    () =>
      getButtonLabel({
        catalog: product?.catalog,
        currentPrice,
      }),
    [currentPrice, product?.catalog]
  );

  const handleAddListItemToCart = useCallback(
    (cardItem: ProductWithPricesDTO, selectedCardItemPrice: Price) =>
      handleAddToCart({
        code: cardItem.product.code,
        catalog: cardItem.product.catalog,
        count: 1,
        weight: selectedCardItemPrice.weight,
      }),
    [handleAddToCart]
  );

  const handleClick = useCallback(() => {
    if (isRelated) logAmplitudeEvent(events.related_click);
    if (proposal) logAmplitudeEvent(events.subscribe_click);
  }, [isRelated, proposal, logAmplitudeEvent]);

  return {
    buttonLabel,
    currentPrice,
    currency,
    readyToCart,
    href,
    handleClick,
    isAddingToCart,
    handleAddListItemToCart,
    formik,
    amplitude,
  };
};
