import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Radio } from "@mui/material";
import { FormikProps } from "formik";
import { FormikValues } from "formik/dist/types";
import { CatalogFilterFormGroup } from "../../CatalogFilters.styled";
import { sstypeOptions } from "../../../../../model/Filters.constants";

type FilterSsTypesProps = {
  fieldName: string;
  sstype: string;
  formik: FormikProps<FormikValues>;
};

const FilterSsTypes = ({ fieldName, sstype, formik }: FilterSsTypesProps) => {
  const [list, setList] = useState([...sstypeOptions]);

  useEffect(() => {
    setList((list) =>
      list.map((item) => ({
        ...item,
        active: sstype === item.id,
      }))
    );
  }, [sstype]);

  return (
    <CatalogFilterFormGroup>
      {list?.map(({ id, title }, index) => (
        <FormControlLabel
          key={index}
          control={
            <Radio
              name={fieldName}
              onChange={formik?.handleChange}
              checked={formik?.values[fieldName] === id}
              value={id}
            />
          }
          label={title}
        />
      ))}
    </CatalogFilterFormGroup>
  );
};

export default FilterSsTypes;
