import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useProductCardProps } from "shared/hooks/useProductCardProps";
import { ProductTypesV2 } from "../../constants/productTypes";
import { events } from "../../context/AmplitudeContext";
import { useQuery } from "../../hooks";
import { productActions, productSelectors } from "../../redux/product";
import { getCatalogType, getTextSearchType } from "../../utils";
import convertCatalogToCat from "../../utils/convetCatalogToCat";
import Product from "./Product";
import ProductReplacementDialog from "./ui/ProductReplacementDialog/ProductReplacementDialog";
import { isItemShouldHaveMockImage } from "../../shared/lib/REMOVE.utils";

const initialBreadcrumbs: any = [
  {
    href: "/search",
    title: "Search",
  },
];

const ProductContainer = () => {
  const query = useQuery();
  const { id: code } = useParams<{ id: string }>();
  const catQuery = query.get("cat");
  const type = getTextSearchType(code);
  const cat = catQuery || getCatalogType(code);
  const dispatch = useDispatch();
  const productWithPrices = useSelector((state) =>
    productSelectors.selectProduct(state, {
      catalog: ProductTypesV2[cat],
      code,
    })
  );

  const { product, prices, image } = productWithPrices;

  const [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs);
  const [loading, setLoading] = useState(true);

  const {
    currency,
    currentPrice,
    formik,
    amplitude,
    buttonLabel,
    isAddingToCart,
  } = useProductCardProps({
    item: {
      product: {
        catalog: ProductTypesV2[cat],
        code,
      },
      prices,
    },
  });

  const [isReplacementsDialogOpen, setIsReplacementsDialogOpen] =
    useState(false);

  const productReplacements = useMemo(() => {
    return productWithPrices?.included?.forms
      ?.filter((includedItem: any) =>
        includedItem?.inclusion?.details?.includes("SALT_FORMS_AND_TAUTOMERS")
      )
      .map((includedItem) => ({
        ...includedItem,
        // @TODO REMOVE THIS LOGIC AFTER DEMO!
        image: isItemShouldHaveMockImage(includedItem.product.code)
          ? `/images/demoReplaceImages/${includedItem.product.code}.png`
          : `/api/v2/catalog/all/images/by-code/${includedItem.product.code}`,
      }));
  }, [productWithPrices]);

  const showProductReplacements = useMemo(() => {
    return (
      Boolean(productReplacements?.length) &&
      currentPrice?.weight?.available === "SYNTHESIS"
    );
  }, [productReplacements, currentPrice]);

  const fetchProduct = () => {
    dispatch(
      productActions.requestProduct({
        code,
        type,
        cat,
        includeAdditionalResults: true,
        onSuccess: () => {
          setLoading(false);
        },
      })
    );
  };

  const handleOpenProductReplacements = () => {
    setIsReplacementsDialogOpen(true);
    amplitude.logEvent(events.product_show_replacement);
  };

  useEffect(() => {
    if (currency && code) {
      fetchProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, currency]);

  useEffect(() => {
    if (code) {
      setLoading(true);

      setBreadcrumbs([
        ...initialBreadcrumbs,
        {
          title: code,
        },
      ]);
    }
  }, [code]);

  useEffect(() => {
    if (product?.code === code) {
      amplitude.logEvent(events.product_details_opened, {
        itemtype: convertCatalogToCat(product?.catalog),
      });
    }
  }, [product, amplitude, code]);

  return (
    <>
      <Product
        loading={loading}
        item={product}
        image={image}
        buttonLabel={buttonLabel}
        formik={formik}
        breadcrumbs={breadcrumbs}
        price={{
          currentPrice,
          currency,
          prices,
        }}
        isAddingToCart={isAddingToCart}
        code={code}
        cat={cat}
        currency={currency}
        showProductReplacements={showProductReplacements}
        onOpenProductReplacements={handleOpenProductReplacements}
      />
      <ProductReplacementDialog
        productCode={product?.code}
        title="In-Stock Alternative available"
        open={isReplacementsDialogOpen}
        onClose={() => setIsReplacementsDialogOpen(false)}
        productReplacements={productReplacements}
      />
    </>
  );
};

export default ProductContainer;
