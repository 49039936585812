import { Step } from "react-joyride";

export const SPOTLIGHT_PADDING = 18;

export const PROFILE_ONBOARDING_STEPS: Step[] = [
  {
    target: "#root",
    title: "Welcome to your profile!",
    content:
      "Would you like a quick tour to get familiar with everything? You can start now or skip and explore on your own.",
    disableBeacon: true,
    placement: "center",
  },
  {
    target: "#orders",
    title: "Orders",
    content:
      "Track your order progress in one place, view order details, check statuses and review contents. Orders older than 01.01.2025 available under Archived Orders tab.",
    placement: "bottom-start",
    styles: {
      tooltip: {
        left: -SPOTLIGHT_PADDING,
      },
    },
  },
  {
    target: "#profile",
    title: "Profile",
    content:
      "Keep your account up to date. Here, you can manage your personal details essential for EnamineStore access.",
    placement: "bottom-start",
    styles: {
      tooltip: {
        left: -SPOTLIGHT_PADDING,
      },
    },
  },
  {
    target: "#address-book",
    title: "Addresses",
    content:
      "Save and manage multiple shipping and billing addresses to speed up checkout. Easily add, edit, or delete addresses for your orders.",
    placement: "bottom-start",
    styles: {
      tooltip: {
        left: -SPOTLIGHT_PADDING,
      },
    },
  },
  {
    target: "#contact-persons",
    title: "Contact Persons",
    content:
      "Add and manage key contacts for your company. Ensure smooth communication and quick processing by keeping your contact list updated.",
    placement: "bottom-start",
    styles: {
      tooltip: {
        left: -SPOTLIGHT_PADDING,
      },
    },
  },
  {
    target: "#tax-free-certificates",
    title: "Tax Exemption Certificates",
    content:
      "US Tax Exemption Certificates can be uploaded and stored in this section. You certificate first must be validated by our team before it becomes active.",
    placement: "bottom-start",
    styles: {
      tooltip: {
        left: -SPOTLIGHT_PADDING,
      },
    },
  },
  {
    target: "#billing",
    title: "Billing",
    content:
      "Use thins interface to easily review and pay for Enamine invoices. You can also process multiple invoices at once for more convenience.",
    placement: "bottom-start",
    styles: {
      tooltip: {
        left: -SPOTLIGHT_PADDING,
      },
    },
  },
  {
    target: "#certificates-coa",
    title: "CoA Certificates",
    content:
      "Find and download Certificates of Analysis (CoA) for your purchased compounds. Ensure compliance and quality assurance with just a few clicks.",
    placement: "bottom-start",
    styles: {
      tooltip: {
        left: -SPOTLIGHT_PADDING,
      },
    },
  },
];
