import { call, put, select } from "redux-saga/effects";
import { runIfIsFunction } from "utils";
import superCartActions from "./actions";
import cartAPI, { fetchDeleteItems } from "./api";
import { calculateTotal } from "./utils";
import { toast } from "react-toastify";
import { isItemShouldHaveMockImage } from "../../../shared/lib/REMOVE.utils";

export function* requestCartInfo(payloadParam) {
  const payload = payloadParam.payload;
  const state = yield select();
  const isAuth = state.user.isAuth;

  try {
    const currency = state.currency.current;
    yield put(
      superCartActions.setCartInfo({
        loading: true,
      })
    );

    if (isAuth) {
      const res = yield call(() => cartAPI.fetchCartInfo(currency?.abbr));

      if (res[1]) {
        if (typeof payload?.onError === "function") {
          yield payload?.onError();
        }
      } else {
        if (res[0]) {
          yield put(
            superCartActions.setCartInfo({
              loading: false,
              data: res[0],
              error: "",
            })
          );
        }

        if (typeof payload?.onSuccess === "function") {
          yield payload?.onSuccess(res[0]);
        }
      }
    } else {
      const calculatedTotalSum = calculateTotal(
        state.superCart.itemsWithImages
      );

      yield put(
        superCartActions.setCartInfo({
          loading: false,
          data: {
            subtotal: calculatedTotalSum,
          },
          error: "",
        })
      );
    }
  } catch (e) {
    console.log("error", e);
  } finally {
  }
}

export function* postCartContent({ payload }) {
  const { items, currency, onSuccess } = payload;
  try {
    const response = yield cartAPI.fetchAddItemToCart(items, currency.abbr);

    if (response[0]) {
      yield put(superCartActions.allToCart(response[0].added));
    }

    if (response[2] === 400) {
      toastNoCartModify();
      return;
    }

    if (onSuccess && typeof onSuccess === "function") {
      onSuccess(response);
    } else toast.error(response[1]);
  } catch (e) {
    console.log("Something went wrong!", e);
  }
}

// V2
export function* requestItemsAndImages({ payload }) {
  const state = yield select();
  const superCart = state.superCart;
  const currencyAbbr = state.currency.current.abbr;

  yield put(superCartActions.setSuperCartError(false));
  const response = yield cartAPI.fetchCartInfo(currencyAbbr);

  if (response[0]) {
    const cartItems = response[0]?.items;

    const summary = cartItems?.reduce(
      (acc, nextCartItem) => {
        return {
          ...acc,
          total: acc?.total + nextCartItem?.addedToBucket?.pay?.total,
        };
      },
      { total: 0 }
    );

    yield put(
      superCartActions.setSuperCart({
        ...superCart,
        itemsWithImages: cartItems.map((cartItem) => ({
          ...cartItem,
          // @TODO REMOVE THIS LOGIC AFTER DEMO!
          image: isItemShouldHaveMockImage(cartItem.product.code)
            ? `/images/demoReplaceImages/${cartItem.product.code}.png`
            : `/api/v2/catalog/all/images/by-code/${cartItem.product.code}`,
        })),
        summary,
      })
    );

    runIfIsFunction(payload?.onSuccess);
  } else {
    yield put(superCartActions.setSuperCartError(true));

    runIfIsFunction(payload?.onError);
  }
}

export function* requestCartDeleteItems({ payload: { payload, onSuccess } }) {
  try {
    const response = yield fetchDeleteItems(payload);
    if (response[0]) {
      yield put(superCartActions.deleteItems(payload));
      if (onSuccess) {
        onSuccess();
      }
    } else toast.error(response[1]);
  } catch (error) {
    // @TODO Rethink how to handle errors in a better way
    console.error("Something went wrong! Delete cart items", error);
  }
}
