import { FormControl, TextField } from "@mui/material";
import { endpoints } from "constants";
import useLoaderFullPage from "features/Loaders/hooks/useLoaderFullPage";
import { useFormik } from "formik";
import React, { useState } from "react";
import BaseNativeSelect from "shared/ui/BaseNativeSelect/BaseNativeSelect";
import {
  InputLabelTypography,
  InputWrapper,
} from "shared/ui/CreatePerson/CreatePerson.styled";
import { Button, Paper } from "ui";
import { darkBlue } from "ui/colors";
import { customFetch } from "utils";
import { RequestMethods } from "utils/customFetch";
import {
  ButtonWrapper,
  ErrorMessage,
  FlexWrapper,
  TitleTypography,
} from "./CertificatesCOA.styled";

const LANGUAGE_OPTIONS = [
  { title: "English", value: "ENGLISH" },
  { title: "German", value: "GERMAN" },
  { title: "French", value: "FRENCH" },
];

const initialValues = {
  lotNumber: "",
  language: "",
};

const CertificatesCOA = () => {
  const [error, setError] = useState("");
  const { showLoaderFillPage, hideLoaderFillPage } = useLoaderFullPage();

  const fetchCoaCertificate = async (certificateData: {
    lotNumber: string;
    language: string;
  }) => {
    return customFetch({
      method: RequestMethods.POST,
      url: endpoints.CERTIFICATES_COA,
      data: {
        lotNumber: certificateData.lotNumber,
        language: certificateData.language,
      },
      config: {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
      },
    });
  };

  const handleSubmit = async (certificateData: {
    lotNumber: string;
    language: string;
  }) => {
    try {
      setError("");
      showLoaderFillPage();

      const response = await fetchCoaCertificate(certificateData);
      const { lotNumber, language } = certificateData;

      if (response[0]) {
        const dirHandle = await window.showDirectoryPicker();
        const fileHandle = await dirHandle.getFileHandle(
          `${lotNumber}_${language}.zip`,
          {
            create: true,
          }
        );

        const writable = await fileHandle.createWritable();
        await writable.write(response[0]);
        await writable.close();
      } else {
        setError(response[1]);
      }
    } catch (err) {
      if (err?.name === "AbortError") {
        return;
      } else {
        console.log(err);
      }
    } finally {
      hideLoaderFillPage();
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (formData) => {
      await handleSubmit(formData);
    },
  });

  return (
    <Paper>
      <TitleTypography variant="h3" color={darkBlue}>
        CoA Certificates
      </TitleTypography>
      <form onSubmit={formik.handleSubmit}>
        <FlexWrapper>
          <InputWrapper>
            <InputLabelTypography variant="body1">
              LOT number
            </InputLabelTypography>
            <TextField
              fullWidth
              id="lotNumber"
              name="lotNumber"
              placeholder="Enter number"
              value={formik.values.lotNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabelTypography variant="body1">
              Language
            </InputLabelTypography>
            <FormControl
              error={formik.touched.language && Boolean(formik.errors.language)}
              sx={{ width: "100%" }}
            >
              <BaseNativeSelect
                options={LANGUAGE_OPTIONS}
                formik={formik}
                fieldName="language"
              />
            </FormControl>
          </InputWrapper>
        </FlexWrapper>
        <ButtonWrapper>
          <Button
            type="success"
            fullWidth
            disabled={
              formik.isSubmitting ||
              !formik.values.lotNumber ||
              !formik.values.language
            }
            dataTestAttr="button-download"
          >
            Download
          </Button>
        </ButtonWrapper>
        {Boolean(error) && <ErrorMessage>{error}</ErrorMessage>}
      </form>
    </Paper>
  );
};

export default CertificatesCOA;
