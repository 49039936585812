import { compiler, RuleType } from "markdown-to-jsx";

export function updateToc(arr, item) {
  const lastItem = arr[arr.length - 1];

  if (lastItem.level === item.level) {
    return [...arr, item];
  } else if (lastItem.level + 1 === item.level) {
    return [
      ...arr.slice(0, arr.length - 1),
      { ...lastItem, items: [...(lastItem.items ?? []), item] },
    ];
  } else if (lastItem.level < item.level) {
    const items = updateToc(lastItem.items ?? [], item);
    lastItem.items = items;
    return [...arr.slice(0, arr.length - 1), { ...lastItem, items: items }];
  }
}

export const buildToc = function (arr) {
  return arr.reduce((acc, item) => {
    if (acc.length === 0) {
      return [item];
    } else {
      return updateToc(acc, item);
    }
  }, []);
};

export const defaultSlugify = function (str) {
  return str
    .replace(/[ÀÁÂÃÄÅàáâãäåæÆ]/g, "a")
    .replace(/[çÇ]/g, "c")
    .replace(/[ðÐ]/g, "d")
    .replace(/[ÈÉÊËéèêë]/g, "e")
    .replace(/[ÏïÎîÍíÌì]/g, "i")
    .replace(/[Ññ]/g, "n")
    .replace(/[øØœŒÕõÔôÓóÒò]/g, "o")
    .replace(/[ÜüÛûÚúÙù]/g, "u")
    .replace(/[ŸÿÝý]/g, "y")
    .replace(/[^a-z0-9- ]/gi, "")
    .replace(/ /gi, "-")
    .toLowerCase();
};

export const slugify = (prefix) => (str) => {
  const slug = defaultSlugify(str);
  return prefix !== slug ? `${prefix}-${slug}` : slug;
};

export const fetchDocumentation = ({ id: fileId, doc: mdFile }) => {
  return fetch(mdFile)
    .then((data) => data.text())
    .then((doc) => {
      const navigation = [];
      compiler(doc, {
        slugify: slugify(fileId),
        renderRule: (next, node) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          if (node.type === RuleType.heading) {
            const [childElement] = node.children;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if (childElement?.type === RuleType.text) {
              const title = childElement.text;
              navigation.push({ id: node.id, level: node.level, title });
            }
          }
          return next();
        },
      });

      return { navigation: buildToc(navigation), document: doc, id: fileId };
    });
};

export const fetchAllDocuments = (list) => {
  return Promise.all(list.map(fetchDocumentation));
};
