import styled from "styled-components";
import { lineColor } from "ui/colors";
import { Box, Typography } from "@mui/material";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Group = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${lineColor};
  }
  padding-bottom: 20px;
  margin-bottom: 20px;
  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

export const TitleTypography = styled(Typography)`
  flex-grow: 1;
  margin: 0;
`;

export const TextRow = styled.p`
  margin: 0 0 5px;
  font-size: 16px;
`;

export const AdditionalWrapper = styled(Box)`
  margin-top: 28px;
`;
