import { Grid } from "@mui/material";
import { ReactComponent as ArrowRightIconImage } from "icons/arrow-right-2.svg";
import {
  DescriptionTypography,
  ContainerSupportWrapperGrid,
  StyledContactsPaper,
  SubHeadingTypography,
} from "pages/Contacts/Contacts.styled";
import { SUPPORT_OPTIONS } from "pages/Contacts/model/Contacts.contants";
import React from "react";

export const SupportList = () => (
  <ContainerSupportWrapperGrid container rowSpacing={2.25} columnSpacing={3.5}>
    {SUPPORT_OPTIONS.map((option, index) => (
      <Grid item xs={12} sm={6} key={index}>
        <a
          href={option.link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <StyledContactsPaper elevation={0}>
            <div>
              <SubHeadingTypography variant="h6">
                {option.title}
              </SubHeadingTypography>
              <DescriptionTypography variant="body2">
                {option.description}
              </DescriptionTypography>
            </div>
            <ArrowRightIconImage />
          </StyledContactsPaper>
        </a>
      </Grid>
    ))}
  </ContainerSupportWrapperGrid>
);
