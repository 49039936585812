import styled from "styled-components";
import { Textarea, Input } from "ui";
import { Box } from "@mui/material";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const InputStyled = styled(Input)`
  line-height: 14px;
`;

export const TextareaStyled = styled(Textarea)`
  line-height: 14px;
  textarea {
    min-height: 90px;
  }
`;

export const ImageWrapper = styled(Box)`
  border-radius: 8px;
  border: 1px solid #d1d5dc;
  background: #fff;
  margin-bottom: 18px;
`;
