import { Chip } from "@mui/material";
import PriceSelect from "parts/price-select/PriceSelect";
import React from "react";
import { ProductWeightStandard } from "shared/types/product";
import CustomWeight from "shared/ui/CustomWeight/CustomWeight";
import { CartIcon } from "ui/icons";
import { Button } from "ui/index";
import { convertDataByConfig } from "ui/info-list/info-list";
import {
  Code,
  CustomWeightWrapper,
  DetailsListItem,
  DetailsListWrapper,
  Image,
  ImageWrapper,
  InfoChipWrapper,
  InfoWrapper,
  PriceList,
  Wrapper,
} from "./GridCard.styled";
import { ProductPurity } from "../../ProductPurity/ProductPurity";

export const GridCard = ({
  item,
  infoListConfig,
  code,
  handleClick,
  href,
  buttonLabel,
  readyToCart,
  isAddingToCart,
  currency,
  formik,
}) => {
  const isCustomWeight =
    formik.values.currentWeight.type ===
    ProductWeightStandard.WEIGHT_IS_NON_STANDARD;

  return (
    <Wrapper>
      <InfoChipWrapper>
        {item?.inclusion?.bindWith && (
          <Chip
            label={`SALT OF ${item?.inclusion?.bindWith}`}
            size="small"
            color="primary"
          />
        )}
      </InfoChipWrapper>

      <ImageWrapper>
        <Image
          style={{
            backgroundImage: `url(${item.image})`,
          }}
          onClick={handleClick}
          to={href}
        />
      </ImageWrapper>

      <InfoWrapper>
        <Code to={href} onClick={handleClick}>
          {code}
        </Code>
        <PriceList isGridCard={isCustomWeight}>
          <PriceSelect
            prices={item?.prices}
            currency={currency}
            label={false}
            fullWidth
            formik={formik}
          />
        </PriceList>
        {isCustomWeight && (
          <CustomWeightWrapper>
            <CustomWeight formik={formik} />
          </CustomWeightWrapper>
        )}
        <Button
          iconLeft={<CartIcon />}
          fullWidth
          size="small"
          onClick={formik.handleSubmit}
          isLoading={isAddingToCart}
          disabled={!readyToCart || !!formik.errors.customWeight?.amount}
          data-test="add_to_cart_button"
        >
          {buttonLabel}
        </Button>
        <DetailsList
          className="list-wrapper"
          item={item?.product}
          config={infoListConfig}
        />
      </InfoWrapper>
    </Wrapper>
  );
};

const DetailsList = ({ item, config, className }) => {
  const list = convertDataByConfig(item, config);
  return (
    <DetailsListWrapper className={className}>
      {list.map((product) => (
        <DetailsListItem key={product.key}>
          <span>{product.title}</span>
          {product.key === "purity" ? (
            <span title={product.raw}>
              <ProductPurity purityValue={product.raw} />
            </span>
          ) : (
            <span title={product.raw}>{product.value || "-"}</span>
          )}
        </DetailsListItem>
      ))}
    </DetailsListWrapper>
  );
};
