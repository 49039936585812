import React from "react";
import { ReactComponent as ListViewIcon } from "../../../../icons/list-view.svg";
import { ReactComponent as CardsViewIcon } from "../../../../icons/cards-view.svg";
import { Wrapper, SmallTitle, BoxButton } from "./CatalogHeader.styled";
import { catalogViewModes } from "../../../../constants";

const CatalogHeader = ({
  pageSize,
  onHandleSetPageSize,
  onHandleSetViewMode,
  viewMode,
}) => {
  return (
    <Wrapper>
      <>
        <SmallTitle>Show by</SmallTitle>
        {[27, 51, 99].map((count) => {
          return (
            <BoxButton
              key={count}
              onClick={() => {
                onHandleSetPageSize(count);
              }}
              active={pageSize === count}
            >
              {count}
            </BoxButton>
          );
        })}
      </>
      <BoxButton
        active={viewMode === catalogViewModes.SIMPLE}
        onClick={() => onHandleSetViewMode(catalogViewModes.SIMPLE)}
      >
        <ListViewIcon />
      </BoxButton>
      <BoxButton
        active={viewMode === catalogViewModes.LIST}
        onClick={() => onHandleSetViewMode(catalogViewModes.LIST)}
      >
        <ListViewIcon style={{ transform: "rotate(90deg)" }} />
      </BoxButton>
      <BoxButton
        active={viewMode === catalogViewModes.CARD}
        onClick={() => onHandleSetViewMode(catalogViewModes.CARD)}
      >
        <CardsViewIcon />
      </BoxButton>
    </Wrapper>
  );
};

export default CatalogHeader;
