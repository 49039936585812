import React, { useContext, useEffect, useMemo } from "react";
import { SectionWrapper } from "../../Checkout.styled";
import { useDispatch, useSelector } from "react-redux";
import superCartSelectors from "../../../../features/super-cart/redux/selectors";
import superCartActions from "../../../../features/super-cart/redux/actions";
import {
  CartItemsListWrapper,
  CheckoutAdditionalInfo,
  EditCardWrapper,
  Footer,
  PlaceOrderButton,
} from "./Sidebar.styled";
import { checkoutSelectors } from "../../../../redux/checkout";
import { CheckoutContext } from "../../lib/Checout.context";
import CartItemsList from "./CartItemsList/CartItemsList";
// @TODO Rewrite this code, use MUI
import LikeLinkButton from "../../../../components/like-link-button/LikeLinkButton";
import { useHistory } from "react-router-dom";
import { rootPages } from "constants";
import useHandleConfirmCheckout from "../../lib/hooks/useHandleConfirmCheckout";
import { InfoIcon2 } from "../../../../ui/icons";
import { currencySelectors } from "../../../../redux/currency";
import OrderPricesDetailsV2 from "shared/ui/OrderPricesDetailsV2/OrderPricesDetailsV2";
import PromoCode from "./PromoCode/PromoCode";
import { CHECKOUT_MODES } from "../../model/Checkout.contants";
import { CanNotCalculateReasons } from "../../../../shared/types/Orders.types";
import { getCompoundTooltip } from "shared/utils/Cart.utils";

const Sidebar = () => {
  const { isReadyToConfirm, setCurrentMode, currentMode } =
    useContext(CheckoutContext);
  const { handleConfirmOrder } = useHandleConfirmCheckout({ setCurrentMode });
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedCurrency = useSelector(currencySelectors.selectCurrent);
  const checkoutRequestData = useSelector(checkoutSelectors.selectRequestData);

  const checkoutCalculations = useSelector(
    checkoutSelectors.selectCalculations
  );

  const cartSummary = useSelector(superCartSelectors.selectCartSummary);
  const checkoutSummary = useSelector(
    checkoutSelectors.selectCalculatedSummary
  );

  const inStockCartItems = useSelector(
    superCartSelectors.selectCartItemsInStock
  );

  const synthesisItems = useSelector(
    superCartSelectors.selectCartItemsSynthesis
  );

  const tooltipText = getCompoundTooltip(synthesisItems);

  const isQuoteOrder = checkoutRequestData?.type === "QUOTE";

  const isShippingHandlingNotFound = useMemo(() => {
    return (
      checkoutCalculations?.pay?.cannotCalculate?.reason ===
      CanNotCalculateReasons.SHIPPING_HANDLING_FEE_NOT_FOUND_BY_ADDRESSES
    );
  }, [checkoutCalculations?.pay?.cannotCalculate?.reason]);

  const isProductPricesNotFound = useMemo(() => {
    return (
      checkoutCalculations?.pay?.cannotCalculate?.reason ===
      CanNotCalculateReasons.SOME_PRODUCTS_PRICES_NOT_FOUND
    );
  }, [checkoutCalculations?.pay?.cannotCalculate?.reason]);

  const isProductRequireManagerDecision = useMemo(() => {
    return (
      checkoutCalculations?.pay?.cannotCalculate?.reason ===
      CanNotCalculateReasons.DANGEROUS_GOODS_REQUIRE_MANAGER_DECISION
    );
  }, [checkoutCalculations?.pay?.cannotCalculate?.reason]);

  useEffect(
    () => {
      dispatch(superCartActions.requestItemsAndImages());
    },
    // eslint-disable-next-line
    [selectedCurrency]
  );

  const handleSubmitOrder = () => {
    void handleConfirmOrder();
  };

  const handleEditCart = () => {
    history.push(`/${rootPages.CART}`);
  };

  return (
    <>
      {currentMode === CHECKOUT_MODES.CHECK_ORDER && (
        <SectionWrapper>
          <PromoCode />
        </SectionWrapper>
      )}

      <SectionWrapper>
        <CartItemsListWrapper>
          {Boolean(inStockCartItems?.length) && (
            <CartItemsList title="In Stock" cartItems={inStockCartItems} />
          )}
          {Boolean(synthesisItems?.length) && (
            <CartItemsList
              title="Synthesis"
              cartItems={synthesisItems}
              isSynthesis={Boolean(tooltipText)}
            />
          )}
        </CartItemsListWrapper>

        <EditCardWrapper>
          <LikeLinkButton onClick={handleEditCart}>Edit cart</LikeLinkButton>
        </EditCardWrapper>

        <Footer>
          {/* @TODO Recheck logic with price=... */}
          <OrderPricesDetailsV2
            price={checkoutSummary ? checkoutSummary : cartSummary}
            tooltipText={tooltipText}
            promoCode={checkoutCalculations?.pay?.promoCode}
          />

          {isProductPricesNotFound && (
            <CheckoutAdditionalInfo>
              <InfoIcon2 width={18} height={18} /> Price for one or more
              products needs to be confirmed. Our team will contact you with
              details.
            </CheckoutAdditionalInfo>
          )}

          {isShippingHandlingNotFound && (
            <CheckoutAdditionalInfo>
              <InfoIcon2 width={18} height={18} /> Our sales team will contact
              you regarding shipping and handling fees for your address.
            </CheckoutAdditionalInfo>
          )}

          {isProductRequireManagerDecision && (
            <CheckoutAdditionalInfo>
              <InfoIcon2 width={18} height={18} /> Our sales team will need to
              contact you regarding dangerous goods shipping costs.
            </CheckoutAdditionalInfo>
          )}

          <PlaceOrderButton
            onClick={handleSubmitOrder}
            variant="contained"
            color="success"
            style={{
              display: isReadyToConfirm ? "inline-flex" : "none",
            }}
          >
            Place {isQuoteOrder ? "Quote" : "Order"}
          </PlaceOrderButton>
        </Footer>
      </SectionWrapper>
    </>
  );
};

export default Sidebar;
