import ListItemText from "@mui/material/ListItemText";
import { ReactNode, useState } from "react";
import React from "react";
import {
  CatalogCollapse,
  CatalogFilterListItemWrapper,
  SectionTitle,
} from "./CatalogFilters.styled";
import Divider from "@mui/material/Divider";
import { DownArrowIcon } from "ui/icons";

type CatalogFilterProps = {
  title: string;
  children: ReactNode;
  filterListItemWrapperClassName?: string;
  disabled?: boolean;
};
const CatalogFilter = ({
  title,
  children,
  filterListItemWrapperClassName,
  disabled,
}: CatalogFilterProps) => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div style={{ marginTop: "18px" }}>
      <CatalogFilterListItemWrapper
        onClick={handleClick}
        className={filterListItemWrapperClassName}
      >
        <ListItemText
          sx={{ margin: 0 }}
          primary={<SectionTitle>{title}</SectionTitle>}
        />
        {open ? (
          <DownArrowIcon
            style={{
              transform: "rotate(0.5turn)",
              width: "24px",
              height: "24px",
            }}
          />
        ) : (
          <DownArrowIcon
            style={{
              width: "24px",
              height: "24px",
            }}
          />
        )}
      </CatalogFilterListItemWrapper>
      <CatalogCollapse
        disabled={disabled}
        in={open}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </CatalogCollapse>
      <Divider sx={{ marginTop: "24px" }} />
    </div>
  );
};
export default CatalogFilter;
