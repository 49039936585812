import { ProductDTO } from "../../typings/DTO/data-contracts";
import BeautifyValue from "../../utils/beautifyValue";
import { DetailsListConfig } from "./ProductDetailsList/ProductDetailsList.types";

export const convertItemValue = (key: string, value: string) => {
  return !value || value.length === 0 ? "-" : BeautifyValue(key, value);
};

export const prepareListData = (
  catalogSearchResult: ProductDTO,
  detailsListConfig: DetailsListConfig
) => {
  const selectedDetailsListConfig =
    detailsListConfig[
      Object.prototype.hasOwnProperty.call(
        detailsListConfig,
        catalogSearchResult?.catalog
      )
        ? catalogSearchResult?.catalog
        : "DEFAULT"
    ];

  if (catalogSearchResult) {
    return Object.entries(catalogSearchResult).reduce((acc, [key, value]) => {
      const keyExists = Object.prototype.hasOwnProperty.call(
        selectedDetailsListConfig,
        key
      );

      if (keyExists) {
        const resObj = {
          key: key,
          title: selectedDetailsListConfig[key]?.title,
          value: convertItemValue(key, value),
          rawValue: value,
        };

        acc.push(resObj);
      }

      if (key === "dangerousGroup") {
        acc.push({
          key: "dangerousGood",
          title: "Dangerous good",
          value: "Yes",
          rawValue: "Yes",
        });
      }

      return acc;
    }, []);
  }
};

export const splitToColumns = (list = []) => {
  const rowsNumber = Math.ceil(list.length / 2);
  const columns = [];
  columns.push(list.slice(0, rowsNumber));
  columns.push(list.slice(rowsNumber));
  const result = [];

  for (let i = 0; i < rowsNumber; i++) {
    const cell1 = columns[0][i] ?? null;
    const cell2 = columns[1][i] ?? null;
    result.push([cell1, cell2]);
  }

  return result;
};

export const formatToTitleCase = (str: string) => {
  if (str === str.toUpperCase()) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  return str;
};
