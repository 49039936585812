import styled from "styled-components";
import { accentColor, borderColor, darkBlue, lightBlue } from "ui/colors";
export const Wrapper = styled.div<{
  fullWidth?: boolean;
  variant?: string;
  isLoading?: boolean;
}>`
  font-size: 20px;
  user-select: none;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  ${({ variant }) =>
    variant === "white" &&
    `
    ${Cells} {
      background-color: transparent;
    }
    ${ControlButton} {
      border-color: white;
      background-color: white;
    }
    ${Input} {
      border-color: white;
      margin-right: 2px;
      margin-left: 2px;
    }
  `}
  ${({ isLoading }) =>
    isLoading &&
    `
    pointer-events: none;
    ${Loader} {
      display: block;
    }
  `}
`;
export const Cells = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  height: 48px;
  box-sizing: border-box;
  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;
  `}
`;
export const Input = styled.input`
  flex-grow: 1;
  width: 50px;
  padding: 0;
  height: 100%;
  border: none;
  color: ${darkBlue};
  box-sizing: border-box;
  text-align: center;
  font-size: 20px;
  margin: 0;
  &:hover {
    border: 1px solid ${accentColor};
    z-index: 10;
  }
  &:focus {
    outline: none;
    border: 1px solid ${accentColor};
    z-index: 10;
  }
`;
export const ControlButton = styled.button<{
  position?: "left" | "right";
  disabled?: boolean;
}>`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 45px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid ${borderColor};
  ${({ position }) =>
    position === "left" &&
    `
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  `}
  ${({ position }) =>
    position === "right" &&
    `
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  `}
  svg path {
    fill: ${darkBlue};
  }
  &:hover {
    border-color: ${accentColor};
    svg path {
      fill: ${accentColor};
    }
  }
  &:active {
    background-color: ${lightBlue};
  }
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    svg path {
      fill: ${borderColor};
    }
  `}
`;
export const Loader = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
  opacity: 0.6;
`;
