import React from "react";
import Breadcrumbs from "./Breadcrumbs";

const initialItems = [
  {
    title: "Homepage",
    href: "/",
  },
];

const BreadcrumbsContainer = ({ items }) => {
  const finalItems = [...initialItems, ...items];

  return <Breadcrumbs breadcrumbItems={finalItems} />;
};

export default BreadcrumbsContainer;
