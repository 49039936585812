import { BucketItem } from "shared/types/cartItem";
import { ProductPriceIssue } from "shared/types/pricing";
import { ProductWeightStandard } from "shared/types/product";

const getPricePerOneCount = (cartItem: BucketItem) => {
  return cartItem.prices.all.find(
    (priceItem) =>
      priceItem?.weight?.amount === cartItem?.addedToBucket?.weight?.amount &&
      priceItem?.weight?.measure === cartItem?.addedToBucket?.weight?.measure
  )?.price;
};

export const prepareUpdatedItem = (cartItem: BucketItem) => {
  const pricePerOneCount = getPricePerOneCount(cartItem);
  return {
    ...cartItem,
    addedToBucket: {
      ...cartItem.addedToBucket,
      weight: {
        ...cartItem.addedToBucket.weight,
        type:
          pricePerOneCount >= 0
            ? ProductWeightStandard.WEIGHT_IS_ENAMINE_STANDARD
            : ProductWeightStandard.WEIGHT_IS_NON_STANDARD,
      },
      pay:
        pricePerOneCount && pricePerOneCount !== 0
          ? {
              fullyCalculated: true,
              pricePerOneCount: pricePerOneCount,
              total: cartItem.addedToBucket.count * pricePerOneCount,
            }
          : {
              cannotCalculate: {
                reason:
                  pricePerOneCount === 0
                    ? ProductPriceIssue.SOME_PRODUCTS_PRICES_NOT_FOUND
                    : ProductPriceIssue.SOME_PRODUCTS_HAVE_NON_STANDARD_WEIGHT,
              },
              total: 0,
              fullyCalculated: false,
            },
    },
  };
};
