import animateScrollTo from "animated-scroll-to";
import { SSEContext } from "context/sseContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { currencySelectors } from "redux/currency";
import { events, useAmplitudeState } from "../../context/AmplitudeContext";
import useAllToCart from "../../features/super-cart/hooks/useAllToCart";
import { useDownloadSdf, usePagination, useQuery } from "../../hooks";
import { catalogActions, catalogSelectors } from "../../redux/catalog";
import { encode, makeParams } from "../../utils";
import Catalog from "./Catalog";
import { convertSearchStatusIntoMessage } from "./lib/Catalog.utils";
import { Breadcrumb } from "shared/types/breadcrumb";

export const listErrorTypes = {
  NOT_FOUND: "NOT_FOUND",
  DISABLED: "DISABLED",
};

type CatalogContainerProps = {
  productCodes?: string;
  breadcrumbs?: Breadcrumb[];
};

const CatalogContainer = ({
  productCodes,
  breadcrumbs,
}: CatalogContainerProps) => {
  const { amplitude } = useAmplitudeState();
  const query = useQuery();
  const { id = "" } = useParams();
  const [q, setQ] = useState(query.get("q") || productCodes || "");
  const [listName, setListName] = useState("");
  const [listErrorType, setListErrorType] = useState("");
  const type = query.get("type");
  const cat = query.get("cat");
  const sstype = query.get("sstype");
  const searchBy = query.get("searchBy");
  const drawSearch = query.get("drawSearch");
  const inclusions = query.get("inclusions");
  const curPage = parseInt(query.get("curPage")) || 1;
  const dispatch = useDispatch();
  const history = useHistory();
  const currency = useSelector(currencySelectors.selectCurrent);
  const isLoading = useSelector(catalogSelectors.selectLoadingItems);
  const catalogFilters = useSelector(catalogSelectors.selectCatalogFilters);
  const pageSize = useSelector(catalogSelectors.selectPageSize);
  const viewMode = useSelector(catalogSelectors.selectViewMode);
  const storeKey = `${q && id === "" ? q.toString() : id}_${sstype}_${cat}`;
  const { searchState, restartSSEConnection } = useContext(SSEContext);
  const { pathname } = useLocation();

  const catalogItemsWithPrices = useSelector((state) =>
    catalogSelectors.selectCatalogItemsWithPrices(state, storeKey)
  );

  const catalogItemsAll = useSelector(catalogSelectors.selectCatalogItemsAll);

  const [total, setTotal] = useState(0);
  const [isNothingFound, setNothingFound] = useState(false);

  // @TODO Check pagination to handle it on FE side
  const [pagination] = usePagination({
    total,
    pageSize,
    curPage,
  });

  const searchStateMessage = useMemo(() => {
    return convertSearchStatusIntoMessage(searchState?.completionState);
  }, [searchState]);

  const { isLoading: isLoadingSdf, handleDownload: handleDownloadSdf } =
    useDownloadSdf(catalogItemsAll);

  const { handleAllToCart, isLoading: isLoadingAllToCart } = useAllToCart({
    total,
    code: encode(q),
    searchedType: type,
    cat,
    list: catalogItemsWithPrices,
    searchBy: searchBy,
    catalogFilters,
  });

  const onHandleSetPageSize = (size) => {
    amplitude.logEvent(events.show_by, { number: size });
    // @TODO Ideally it has to be two separate actions to set pageSize and pagination
    // curPage should be 1, because when we set catalogActions.setPageSize, we want to reset curPage to the first page.
    dispatch(catalogActions.setPageSize({ curPage: 1, size, storeKey }));
    updateQueryParamsWithPage(1);
  };

  const onHandleSetViewMode = (mode) => {
    amplitude.logEvent(events.change_view_click, { type: mode });
    dispatch(catalogActions.setViewMode(mode));
  };

  const onHandleClickPagination = (page) => {
    amplitude.logEvent(events.pagination_click, { number: page });
    animateScrollTo(0);
    updateQueryParamsWithPage(page);
    // @TODO Ideally it has to be two separate actions to set pageSize and pagination
    dispatch(catalogActions.setPageSize({ curPage: page, storeKey }));
  };

  const updateQueryParamsWithPage = (page) => {
    let queryParams = "";

    if (id) {
      queryParams = makeParams({
        curPage: page,
      });

      history.push(`/product-lists/${id}?${queryParams}`);
    } else {
      queryParams = makeParams({
        q: encode(q),
        cat,
        type,
        sstype,
        drawSearch,
        curPage: page,
      });

      history.push(`${pathname}?${queryParams}`);
    }
  };

  const fetchMoreData = () => {
    dispatch(
      catalogActions.requestProductsWithPrices({
        code: encode(q),
        type,
        cat,
        sstype,
        inclusions,
        storeKey,
        curPage,
        setNothingFound,
        setTotal,
        pageSize,
        searchBy,
      })
    );
  };

  const getProductList = () => {
    dispatch(
      catalogActions.getProductList({
        id,
        storeKey,
        setQ,
        setListName,
        setListErrorType,
        curPage,
        pageSize,
        setTotal,
      })
    );
  };

  useEffect(() => {
    restartSSEConnection();
    if (drawSearch) {
      if (sstype && cat) {
        fetchMoreData();
      }
    } else if (id === "" && q !== "") {
      fetchMoreData();
    } else if (id !== "") {
      getProductList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sstype, cat, currency, catalogFilters, q]);

  return (
    <Catalog
      items={catalogItemsWithPrices}
      isLoading={isLoading}
      encoded={encode(q)}
      q={q}
      id={id}
      sstype={sstype}
      cat={cat}
      inclusions={inclusions}
      drawSearch={drawSearch}
      isNothingFound={isNothingFound}
      onHandleSetPageSize={onHandleSetPageSize}
      onHandleSetViewMode={onHandleSetViewMode}
      onHandleDownloadSdf={handleDownloadSdf}
      isLoadingSdf={isLoadingSdf}
      pageSize={pageSize}
      viewMode={viewMode}
      pagination={pagination}
      onHandleClickPagination={onHandleClickPagination}
      onHandleAllToCart={handleAllToCart}
      isLoadingAllToCart={isLoadingAllToCart}
      listName={listName}
      listErrorType={listErrorType}
      completionState={searchStateMessage}
      completionPercent={searchState.completionPercent}
      breadcrumbs={breadcrumbs}
    />
  );
};

export default CatalogContainer;
