import { ReactNode } from "react";
import { CanNotCalculateReasons } from "../../../shared/types/Orders.types";

export interface StepperCheckoutStepItem {
  title: string;
  open: boolean;
  valid: boolean;
  disabled: boolean;

  // @TODO Check do we have to interact use component
  component?: ReactNode | any;
}

export type DefaultPaymentMethod = {
  id: PaymentMethodIds;
  default?: boolean;
  title: string;
  text: string;
  icon: ReactNode;
  disabled: boolean;
  disabledReason?: CanNotCalculateReasons;
};

export enum PaymentMethodIds {
  WIRE = "WIRE",
  CHECK = "CHECK",
  CARD = "CARD",
}

export type StepperStep = {
  open: boolean;
  valid: boolean;
  disabled: boolean;
};
