import styled from "styled-components";
import { blue, darkBlue } from "ui/colors";
import { Box, Typography } from "@mui/material";

export const SectionWrapper = styled(Box)`
  margin-bottom: 28px;
`;

export const Wrapper = styled.div`
  ${({ disable }) => {
    if (disable) {
      return `
        opacity: 0.8;
        pointer-events: none;
      `;
    }
  }}
`;

export const InputWrapper = styled.div`
  padding-top: 20px;
`;
export const AlertWrapper = styled.div`
  margin-top: 18px;
`;

export const TitleTypography = styled(Typography)`
  font-weight: 500;
  margin: 14px 0 18px;
  line-height: 1;
  color: ${darkBlue};
`;

export const TextAsLink = styled.span`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:active {
    color: ${blue};
  }
`;

export const InputLabelTypography = styled(Typography)`
  font-size: 14px;
  margin-bottom: 6px;
`;
