import React from "react";
import { Grid, Typography } from "@mui/material";
import {
  StyledPaper,
  LocationContainer,
  LocationIconWrapper,
  InfoWrapper,
  OfficeLocationTypography,
  OfficeNameTypography,
  OfficeDescriptionTypography,
  OfficeDetailsTypography,
  USADetails,
  DistributorsOfficesWrapper,
  HeadingTypography,
  StyledLink,
} from "pages/Contacts/Contacts.styled";
import { ReactComponent as LocationIcon } from "icons/location.svg";
import { OFFICE_LOCATIONS } from "../../model/Contacts.contants";

export const OfficeList = () => (
  <>
    <HeadingTypography variant="h1">Our Offices</HeadingTypography>
    <DistributorsOfficesWrapper>
      <Grid container spacing={3.5} mb={7.5}>
        {OFFICE_LOCATIONS.map((office, index) => (
          <Grid item xs={12} md={4} key={index}>
            <StyledPaper elevation={0}>
              <LocationContainer>
                <LocationIconWrapper>
                  <LocationIcon />
                </LocationIconWrapper>
                <InfoWrapper>
                  <OfficeLocationTypography variant="body2">
                    {office.region}
                  </OfficeLocationTypography>
                  <OfficeNameTypography variant="h6">
                    {office.name}
                  </OfficeNameTypography>
                </InfoWrapper>
              </LocationContainer>
              <div>
                <OfficeDescriptionTypography variant="body2">
                  Product or Service Inquiries{" "}
                  {office?.tollFree && (
                    <USADetails>
                      <StyledLink href={`tel:${office.phone}`}>
                        {office.phone}
                      </StyledLink>
                    </USADetails>
                  )}
                </OfficeDescriptionTypography>
                {!office?.tollFree && (
                  <OfficeDetailsTypography>
                    <StyledLink href={`tel:${office.phone}`}>
                      {office.phone}
                    </StyledLink>
                  </OfficeDetailsTypography>
                )}
              </div>
              {office?.tollFree && (
                <div>
                  <Typography variant="body2">
                    USA & Canada Toll Free{" "}
                    <USADetails>
                      <StyledLink href={`tel:${office.tollFree}`}>
                        {office?.tollFree}{" "}
                      </StyledLink>
                    </USADetails>
                  </Typography>
                </div>
              )}
              <div>
                <OfficeDescriptionTypography variant="body2">
                  Email{" "}
                  {office?.tollFree ? (
                    <USADetails>
                      <StyledLink href={`mailto:${office.email}`}>
                        {office.email}
                      </StyledLink>
                    </USADetails>
                  ) : (
                    <OfficeDetailsTypography>
                      <StyledLink href={`mailto:${office.email}`}>
                        {office.email}
                      </StyledLink>
                    </OfficeDetailsTypography>
                  )}
                </OfficeDescriptionTypography>
              </div>
            </StyledPaper>
          </Grid>
        ))}
      </Grid>
    </DistributorsOfficesWrapper>
  </>
);
