import { Title } from "ui";
import { Wrapper } from "./styles";
import ModifyCartSelect from "../modifyCartSelect";

const Header = ({
  handleClearCart,
  downloadSdf,
  showSelect,
  handleGroupUpdate,
}) => {
  return (
    <Wrapper>
      <Title variant="h1">Shopping cart</Title>
      {Boolean(showSelect) && (
        <ModifyCartSelect
          handleClearCart={handleClearCart}
          handleDownloadSdf={downloadSdf.handleDownload}
          isSdfLoading={downloadSdf.isLoading}
          handleGroupUpdate={handleGroupUpdate}
        />
      )}
    </Wrapper>
  );
};

export default Header;
