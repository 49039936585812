import { ReactComponent as CartIcon } from "../icons/cart.svg";
import { ReactComponent as CartEmptyIcon } from "../icons/cart-empty.svg";
import { ReactComponent as UserIcon } from "../icons/user.svg";
import { ReactComponent as LogoutIcon } from "../icons/logout.svg";
import { ReactComponent as DownArrowIcon } from "../icons/down-arrow.svg";
import { ReactComponent as FacebookIcon } from "../icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../icons/twitter.svg";
import { ReactComponent as XIcon } from "../icons/xlogo.svg";
import { ReactComponent as InstagramIcon } from "../icons/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../icons/linkedin.svg";
import { ReactComponent as ArrowRightIcon } from "../icons/arrow-right.svg";
import { ReactComponent as ArrowRight2Icon } from "../icons/arrow-right-2.svg";
import { ReactComponent as PencilIcon } from "../icons/pencil.svg";
import { ReactComponent as PenIcon } from "../icons/pen.svg";
import { ReactComponent as DeleteIcon } from "../icons/delete.svg";
import { ReactComponent as FileUploadIcon } from "../icons/file-upload.svg";
import { ReactComponent as EnamineLogoIcon } from "../icons/enamine.svg";
import { ReactComponent as DhlLogoIcon } from "../icons/dhl.svg";
import { ReactComponent as FedexLogoIcon } from "../icons/fedex.svg";
import { ReactComponent as UpsLogoIcon } from "../icons/ups.svg";
import { ReactComponent as CheckIcon } from "../icons/check.svg";
import { ReactComponent as Check2Icon } from "../icons/check-2.svg";
import { ReactComponent as CircleCheckIcon } from "../icons/circle-check.svg";
import { ReactComponent as BeakerCheckIcon } from "../icons/beaker-check.svg";
import { ReactComponent as BeakerAlertIcon } from "../icons/beaker-alert.svg";
import { ReactComponent as CloseIcon } from "../icons/close.svg";
import { ReactComponent as SettingsIcon } from "../icons/settings.svg";
import { ReactComponent as DownloadIcon } from "../icons/download.svg";
import { ReactComponent as DownloadDocIcon } from "../icons/download-doc.svg";
import { ReactComponent as FileDownloadIcon } from "../icons/file-download.svg";
import { ReactComponent as MinusIcon } from "../icons/minus.svg";
import { ReactComponent as PlusIcon } from "../icons/plus.svg";
// DEPRECATED
import { ReactComponent as InfoIcon } from "../icons/info.svg";
import { ReactComponent as InfoIcon2 } from "../icons/info2.svg";
import { ReactComponent as SearchIcon } from "../icons/search.svg";
import { ReactComponent as NotFoundIcon } from "../icons/not-found.svg";
import { ReactComponent as NoImageIcon } from "../icons/no-image.svg";
import { ReactComponent as RocketIcon } from "../icons/rocket.svg";
import { ReactComponent as DangerIcon } from "../icons/danger.svg";
import { ReactComponent as CartSuccessIcon } from "../icons/cart-success.svg";
import { ReactComponent as CartWarningIcon } from "../icons/cart-warning.svg";
import { ReactComponent as MarkSuccessIcon } from "../icons/mark-success.svg";
import { ReactComponent as MarkWarningIcon } from "../icons/mark-warning.svg";
import { ReactComponent as PaidSuccessIcon } from "../icons/paid-success.svg";
import { ReactComponent as FlaskIcon } from "../icons/flask.svg";
import { ReactComponent as DeliveryIcon } from "../icons/delivery.svg";
import { ReactComponent as FolderIcon } from "../icons/folder.svg";
import { ReactComponent as LibraryIcon } from "../icons/library.svg";
import { ReactComponent as DnaIcon } from "../icons/dna.svg";
import { ReactComponent as EnvelopeIcon } from "../icons/envelope.svg";
import { ReactComponent as CharmEyeSlashIcon } from "../icons/charm-eye-slash.svg";
import { ReactComponent as CharmEyeIcon } from "../icons/charm-eye.svg";
import { ReactComponent as MarkDangerIcon } from "../icons/mark-danger.svg";
import { ReactComponent as EmailIcon } from "../icons/email.svg";
import { ReactComponent as SafeLockIcon } from "../icons/safe-lock.svg";
import { ReactComponent as BankCardsIcon } from "../icons/bank-cards.svg";
import { ReactComponent as BankIcon } from "../icons/bank.svg";
import { ReactComponent as MoneyIcon } from "../icons/money.svg";
import { ReactComponent as TableWeight } from "../icons/tabler-weight.svg";
import { ReactComponent as DrawSearchIcon } from "../icons/draw-search.svg";
import { ReactComponent as LogoIcon } from "../icons/logo.svg";
import { ReactComponent as ArrowLeftIcon } from "../icons/arrow-left.svg";
import { ReactComponent as InfinityIcon } from "../icons/infinity.svg";
import { ReactComponent as DangerMarkIcon } from "../icons/danger-icon.svg";
import { ReactComponent as UnionIcon } from "../icons/union.svg";
import { ReactComponent as ReplacementIcon } from "../icons/replacement.svg";
import { ReactComponent as CrossIcon } from "../icons/cross.svg";

const icons = {
  CartIcon,
  CartEmptyIcon,
  UserIcon,
  LogoutIcon,
  DownArrowIcon,
  FacebookIcon,
  TwitterIcon,
  XIcon,
  InstagramIcon,
  LinkedinIcon,
  ArrowRightIcon,
  PenIcon,
  ArrowRight2Icon,
  PencilIcon,
  DeleteIcon,
  FileUploadIcon,
  EnamineLogoIcon,
  DhlLogoIcon,
  FedexLogoIcon,
  UpsLogoIcon,
  CheckIcon,
  Check2Icon,
  CircleCheckIcon,
  BeakerAlertIcon,
  BeakerCheckIcon,
  CloseIcon,
  SettingsIcon,
  DownloadIcon,
  DownloadDocIcon,
  FileDownloadIcon,
  MinusIcon,
  PlusIcon,
  InfoIcon,
  InfoIcon2,
  SearchIcon,
  NotFoundIcon,
  NoImageIcon,
  RocketIcon,
  FlaskIcon,
  LibraryIcon,
  DnaIcon,
  DeliveryIcon,
  FolderIcon,
  EnvelopeIcon,
  DangerIcon,
  CartSuccessIcon,
  CartWarningIcon,
  MarkSuccessIcon,
  MarkWarningIcon,
  PaidSuccessIcon,
  CharmEyeSlashIcon,
  CharmEyeIcon,
  MarkDangerIcon,
  EmailIcon,
  SafeLockIcon,
  BankCardsIcon,
  BankIcon,
  MoneyIcon,
  TableWeight,
  DrawSearchIcon,
  LogoIcon,
  ArrowLeftIcon,
  InfinityIcon,
  UnionIcon,
  DangerMarkIcon,
  ReplacementIcon,
  CrossIcon,
};

export default icons;

export {
  CartIcon,
  CartEmptyIcon,
  UserIcon,
  LogoutIcon,
  DownArrowIcon,
  FacebookIcon,
  TwitterIcon,
  XIcon,
  InstagramIcon,
  LinkedinIcon,
  ArrowRightIcon,
  ArrowRight2Icon,
  PenIcon,
  PencilIcon,
  DeleteIcon,
  FileUploadIcon,
  EnamineLogoIcon,
  DhlLogoIcon,
  FedexLogoIcon,
  UpsLogoIcon,
  CheckIcon,
  Check2Icon,
  BeakerAlertIcon,
  BeakerCheckIcon,
  CircleCheckIcon,
  CloseIcon,
  SettingsIcon,
  DownloadIcon,
  DownloadDocIcon,
  FileDownloadIcon,
  MinusIcon,
  PlusIcon,
  InfoIcon,
  InfoIcon2,
  SearchIcon,
  NotFoundIcon,
  NoImageIcon,
  RocketIcon,
  FlaskIcon,
  LibraryIcon,
  DnaIcon,
  DeliveryIcon,
  FolderIcon,
  EnvelopeIcon,
  DangerIcon,
  CartSuccessIcon,
  CartWarningIcon,
  MarkSuccessIcon,
  MarkWarningIcon,
  PaidSuccessIcon,
  CharmEyeSlashIcon,
  CharmEyeIcon,
  MarkDangerIcon,
  EmailIcon,
  SafeLockIcon,
  BankCardsIcon,
  BankIcon,
  MoneyIcon,
  TableWeight,
  DrawSearchIcon,
  LogoIcon,
  DangerMarkIcon,
  ArrowLeftIcon,
  InfinityIcon,
  UnionIcon,
  CrossIcon,
  ReplacementIcon,
};
