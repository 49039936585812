import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { utilSelectors } from "redux/util";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Radio } from "@mui/material";
import { FormikProps } from "formik";
import { FormikValues } from "formik/dist/types";
import { CatalogFilterFormGroup } from "../../CatalogFilters.styled";
import { generateList } from "../../../../../lib/Catalog.utils";

type FilterCategoryProps = {
  fieldName: string;
  category: string;
  formik: FormikProps<FormikValues>;
  handleChangeCategory: (value: string) => void;
};

const FilterCategory = ({
  fieldName,
  category,
  handleChangeCategory,
}: FilterCategoryProps) => {
  const catalogs = useSelector(utilSelectors.selectCatalogs);

  const [list, setList] = useState([]);

  useEffect(() => {
    setList(() => generateList(catalogs, category));
  }, [catalogs, category]);

  return (
    <CatalogFilterFormGroup>
      {list?.map(({ id, title }, index) => (
        <FormControlLabel
          key={index}
          control={
            <Radio
              name={fieldName}
              onChange={(e) => {
                handleChangeCategory(e?.target?.value);
              }}
              checked={category === id}
              value={id}
            />
          }
          label={title}
        />
      ))}
    </CatalogFilterFormGroup>
  );
};

export default FilterCategory;
