import { Grid, Typography } from "@mui/material";
import { ReactComponent as DownArrowIcon } from "icons/down-arrow.svg";
import {
  DetailsWrapper,
  DistributorsOfficesWrapper,
  HeadingTypography,
  IconWrapper,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledLink,
  SubHeadingTypography,
  WebsiteTypography,
} from "pages/Contacts/Contacts.styled";
import React from "react";
import { DISTRIBUTORS } from "../../model/Contacts.contants";

export const DistributorList = () => (
  <>
    <HeadingTypography variant="h1">
      Our Distributors and Sites
    </HeadingTypography>
    <DistributorsOfficesWrapper>
      <Grid container spacing={3.5}>
        {DISTRIBUTORS.map((distributor, index) => (
          <Grid item xs={12} sm={6} key={index} gap={6}>
            <StyledAccordion>
              <StyledAccordionSummary
                expandIcon={
                  <IconWrapper>
                    <DownArrowIcon />
                  </IconWrapper>
                }
              >
                <SubHeadingTypography variant="h6">
                  {distributor.name}
                </SubHeadingTypography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <DetailsWrapper>
                  <Typography variant="body1">{distributor.address}</Typography>
                  {distributor.companyNumber && (
                    <Typography variant="body1">
                      Company No {distributor?.companyNumber}
                    </Typography>
                  )}
                  {distributor.vatNumber && (
                    <Typography variant="body1">
                      VAT No {distributor?.vatNumber}
                    </Typography>
                  )}
                  {distributor?.website && (
                    <WebsiteTypography variant="body1">
                      <StyledLink
                        href={`https://${distributor?.website}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={distributor?.website}
                      >
                        {distributor?.website}
                      </StyledLink>
                    </WebsiteTypography>
                  )}
                </DetailsWrapper>
              </StyledAccordionDetails>
            </StyledAccordion>
          </Grid>
        ))}
      </Grid>
    </DistributorsOfficesWrapper>
  </>
);
