import styled from "styled-components";
import { darkBlue, lightBlue } from "ui/colors";

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
  padding: 0;
`;

export const ImageWrapper = styled.div`
  margin: 24px auto;
  padding: 24px 0;
`;

export const Tag = styled.div`
  display: inline-block;
  background-color: ${lightBlue};
  font-size: 14px;
  font-weight: 400;
  color: ${darkBlue};
  border-radius: 8px;
  padding: 4px 10px;
`;

export const BulkWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
