import { Container } from "@mui/material";
import { NewsletterSubscribeContainer } from "features/newsletter-subscribe/NewsletterSubscribe.container";
import React from "react";
import { Breadcrumbs } from "ui";
import {
  BodyTextTypography,
  BreadCrumbsWrapper,
  HeadingTypography,
  OfficesImageWrapper,
} from "./Contacts.styled";
import { BREADCRUMBS_ITEMS } from "./model/Contacts.contants";
import { DistributorList } from "./ui/DistributorList/DistributorList";
import { OfficeList } from "./ui/OfficeList/OfficeList";
import { SupportList } from "./ui/SupportList/SupportList";
import officesImage from "icons/offices.svg";

export const Contacts = () => {
  return (
    <>
      <BreadCrumbsWrapper>
        <Breadcrumbs items={BREADCRUMBS_ITEMS} />
      </BreadCrumbsWrapper>
      <Container>
        <HeadingTypography variant="h1">Contact Us</HeadingTypography>
        <BodyTextTypography variant="body1">
          Welcome! You can raise a request with us using the sections below.
        </BodyTextTypography>
        <SupportList />
        <OfficeList />
        <OfficesImageWrapper>
          <img src={officesImage} alt="Global Office Network" />
        </OfficesImageWrapper>
        <DistributorList />
      </Container>
      <NewsletterSubscribeContainer />
    </>
  );
};
