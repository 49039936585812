import { SelectHTMLAttributes, useMemo } from "react";
import { Select } from "../../ui";
import { AvailabilityStatus } from "shared/types/delivery";
import { ProductWeightStandard } from "shared/types/product";
import { Prices } from "../../typings/DTO/data-contracts";
import { Currency } from "../../shared/types/pricing";
import { FormikProps } from "formik";
import { FormikValues } from "formik/dist/types";

type PriceSelectProps = {
  isCartItem?: boolean;
  prices: Prices;
  currency: Currency;
  formik: FormikProps<FormikValues>;
  isCustomWeightEnabled: boolean;
  // @TODO Remove this property, poor usage
  variant: string;
  handleChange?: (value: any) => void;
} & SelectHTMLAttributes<HTMLSelectElement>;

const PriceSelect = ({
  isCartItem,
  prices,
  currency,
  variant,
  formik,
  isCustomWeightEnabled = true,
  handleChange,
  ...rest
}: PriceSelectProps) => {
  const { all: allProductPrices } = prices;

  const options = useMemo(() => {
    return [
      ...(allProductPrices?.map(({ weight, delivery, price }) => {
        const measureText = `${weight.amount} ${weight.measure}`;
        const priceText = ` - ${currency.symbol}${price}`;
        const stockText =
          delivery.available === AvailabilityStatus.IN_STOCK
            ? "In stock"
            : "Synthesis";

        const stockLocations = delivery?.stockLocations?.join(", ");

        const title = `${measureText}${priceText} - ${stockText}${
          stockLocations?.length ? ` - ${stockLocations}` : ""
        }`;

        return {
          title,
          selectedTitle: measureText,
          value: JSON.stringify({
            ...weight,
            type: ProductWeightStandard.WEIGHT_IS_ENAMINE_STANDARD,
          }),
        };
      }) || []),
      ...(isCustomWeightEnabled
        ? [
            {
              title: "Custom weight - Inquiry",
              value: JSON.stringify({
                measure: "g",
                amount: null,
                type: ProductWeightStandard.WEIGHT_IS_NON_STANDARD,
              }),
              selectedTitle: "Custom weight - Inquiry",
            },
          ]
        : []),
    ];
  }, [allProductPrices, currency.symbol, isCustomWeightEnabled]);

  if (!options?.length) return <div style={{ height: "50px" }}></div>;

  const onHandleChange = (event: any) => {
    const selectedValue = JSON.parse(event.target.value);
    formik.setFieldValue("currentWeight", selectedValue);
    if (
      selectedValue?.type ===
        ProductWeightStandard.WEIGHT_IS_ENAMINE_STANDARD &&
      handleChange
    ) {
      handleChange(selectedValue);
    }
  };

  return (
    <Select
      isCartItem={isCartItem}
      options={options}
      value={JSON.stringify(formik?.values?.currentWeight)}
      variant={variant}
      formik={formik}
      name="currentWeight"
      handleChange={onHandleChange}
      {...rest}
    />
  );
};

export default PriceSelect;
