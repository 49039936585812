import { FormikProps, useFormik } from "formik";
import React from "react";
import { BucketItemWithImage } from "shared/types/cartItem";
import { Currency } from "shared/types/pricing";
import { ProductWeight, ProductWeightStandard } from "shared/types/product";
import useCartHandlers from "../hooks/useCartHandlers";
import CartItem from "./CartItem";

type CartItemContainerProps = {
  cartItem: BucketItemWithImage;
  currency: Currency;
  isCartLoading: boolean;
};

const CartItemContainer = ({
  cartItem,
  currency,
  isCartLoading,
}: CartItemContainerProps) => {
  const { handleDelete, handleChangeCount, handleChangeWeight } =
    useCartHandlers();

  const handleItemCount = (value: number) => {
    handleChangeCount({
      ...cartItem,
      addedToBucket: {
        ...cartItem.addedToBucket,
        count: value,
      },
    });
  };

  const updateItemWeight = (newWeight: Partial<ProductWeight>) => {
    handleChangeWeight({
      ...cartItem,
      addedToBucket: {
        ...cartItem.addedToBucket,
        weight: {
          ...cartItem.addedToBucket.weight,
          ...newWeight,
        },
      },
    });
  };

  const formik: FormikProps<{
    currentWeight: ProductWeight;
    customWeight: Omit<ProductWeight, "type">;
    counter: number;
  }> = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentWeight:
        cartItem?.addedToBucket?.weight?.type ===
        ProductWeightStandard.WEIGHT_IS_NON_STANDARD
          ? {
              measure: "g",
              amount: null,
              type: ProductWeightStandard.WEIGHT_IS_NON_STANDARD,
            }
          : cartItem.addedToBucket.weight,
      customWeight: {
        measure: cartItem.addedToBucket?.weight?.measure,
        amount: cartItem.addedToBucket?.weight?.amount,
      },
      counter: cartItem.addedToBucket?.count,
    },
    onSubmit: () => {},
  });

  return (
    <CartItem
      cartItem={cartItem}
      handleUpdateItemWeight={updateItemWeight}
      handleItemRemove={handleDelete}
      currency={currency}
      handleItemCount={handleItemCount}
      isCartLoading={isCartLoading}
      formik={formik}
    />
  );
};

export default CartItemContainer;
