import { endpoints } from "constants";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BucketItem } from "shared/types/cartItem";
import common from "../../../constants/common";
import { CircleCheckIcon } from "../../../ui/icons";
import { customFetch } from "../../../utils";
import { RequestMethods } from "../../../utils/customFetch";
import superCartActions from "../redux/actions";
import ModalGroupUpdate from "./ModalGroupUpdate";
import { ModalModes } from "./ModalGroupUpdate.model";
import { ProductWeightStandard } from "shared/types/product";
import {
  GroupUpdateFormikProps,
  GroupUpdateFormValues,
} from "../model/SuperCart.types";

type ModalGroupUpdateContainerProps = {
  cartItems: BucketItem[];
  isOpen: boolean;
  onClose: () => void;
};

const initParams = {
  retry: 5,
  retryDelay: 300,
};

const ModalGroupUpdateContainer = ({
  cartItems,
  isOpen,
  onClose,
}: ModalGroupUpdateContainerProps) => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState(ModalModes.DEFAULT);

  const cartItemsCatalogs = useMemo(() => {
    return cartItems?.reduce((acc, next) => {
      const catalog = next?.product?.catalog;
      if (acc && Object.keys(acc).includes(catalog)) {
        return acc;
      }

      if (next?.prices) {
        return {
          ...acc,
          [catalog]: {
            weight: {
              amount: "",
              measure:
                next?.prices?.default?.weight?.measure ||
                next?.prices?.all[0]?.weight?.measure,
            },
            customWeight: {
              amount: 0,
              measure: "g",
            },
            count: 0,
            availableWeightOptions: [
              { title: "Not selected", value: "" },
              ...(next &&
                next.prices.all.map((price) => ({
                  value: JSON.stringify(price.weight),
                  title: `${price.weight.amount} ${price.weight.measure}`,
                  type: ProductWeightStandard.WEIGHT_IS_ENAMINE_STANDARD,
                }))),
              {
                value: JSON.stringify({
                  type: ProductWeightStandard.WEIGHT_IS_NON_STANDARD,
                  amount: null,
                  measure: "g",
                }),
                title: "Custom weight",
                type: ProductWeightStandard.WEIGHT_IS_NON_STANDARD,
              },
            ],
          },
        };
      }
      return acc;
    }, {});
  }, [cartItems]);

  const formik: GroupUpdateFormikProps = useFormik<GroupUpdateFormValues>({
    initialValues: {} as GroupUpdateFormValues,
    onSubmit: async (values) => {
      setMode(ModalModes.SENDING);

      const groupUpdatePayload = Object.entries(values).reduce(
        (acc, [key, category]) => {
          if (category.count) {
            acc[key] = {
              count: category.count,
              weight:
                category.currentWeight.type ===
                ProductWeightStandard.WEIGHT_IS_NON_STANDARD
                  ? category.customWeight
                  : category.currentWeight,
            };
          }
          return acc;
        },
        {}
      );

      dispatch(superCartActions.setSuperCartLoading(true));
      const response = await customFetch(
        {
          url: `${endpoints.GROUP_UPDATE}?includeBucketInResponse=false&currency=USD`,
          method: RequestMethods.PATCH,
          data: groupUpdatePayload,
        },
        { ...initParams, baseURL: common.API_V2_URL }
      );

      if (response[0]) {
        onClose();
        toast.success("Your cart has updated", {
          icon: <CircleCheckIcon />,
          autoClose: 6000,
        });
        dispatch(
          superCartActions.requestItemsAndImages({
            onSuccess: () =>
              dispatch(superCartActions.setSuperCartLoading(false)),
            onError: () =>
              dispatch(superCartActions.setSuperCartLoading(false)),
          })
        );

        return;
      } else {
        toast.error(response[1]);
      }
      return;
    },
  });

  useEffect(() => {
    if (cartItemsCatalogs) {
      formik.resetForm({
        values: cartItemsCatalogs,
      });
      formik.setTouched(false);
    }
    // eslint-disable-next-line
  }, [cartItemsCatalogs]);

  const resetForm = () => {
    formik.resetForm({
      values: cartItemsCatalogs,
    });
    formik.setTouched(false);
  };

  return (
    <ModalGroupUpdate
      open={isOpen}
      handleClose={() => {
        resetForm();
        onClose();
      }}
      formik={formik}
      mode={mode}
      modes={ModalModes}
    />
  );
};

export default ModalGroupUpdateContainer;
