import Collapse from "@mui/material/Collapse";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { borderColor } from "ui/colors";

export const TitleTypography = styled(Typography)`
  margin: 0;
  font-weight: 600;
`;

export const CatalogFiltersSubmitWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const CatalogFilterListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 12px;
  &.smallMarginBottom {
    margin-bottom: 6px;
  }
`;

export const CatalogFiltersForm = styled.form`
  & > div:nth-last-child(2) hr {
    display: none;
  }
`;

export const CatalogFilterFormGroup = styled(FormGroup)`
  padding: 0 5px;
`;

export const CatalogCollapse = styled(Collapse)`
  ${(props) => {
    if (props.disabled) {
      return `
      & * {
        color: ${borderColor} !important;
        pointer-events: none;
      }`;
    }
  }}
`;

export const InputWrapper = styled.div`
  .input__label {
    font-size: 16px;
    margin-bottom: 12px;
    font-weight: 600;
  }
  & > .input {
    margin-top: 24px;
  }
`;
