import { Container } from "@mui/material";
import { SearchAsTypes } from "constants/searchAsTypes";
import React, { useMemo } from "react";
import { useLocation } from "react-router";
import { Breadcrumb } from "shared/types/breadcrumb";
import { Breadcrumbs, CatalogHeader, Flex, Pagination, Title } from "ui";
import LinearProgressBar from "ui/LinearProgress/LinearProgressBar";
import { PageHeader, PageSidebar } from "ui/page-header/page-header.styles";
import { CatalogViewModes } from "../../constants/catalogViewModes";
import RequestAnalogsCatalog from "../CatalogV2/ui/RequestAnalogsCatalog/RequestAnalogsCatalog";
import { listErrorTypes } from "./Catalog.container";
import {
  Group,
  GroupItem,
  List,
  Page,
  PaginationWrapper,
  Paper,
} from "./Catalog.styled";
import { CatalogPagination } from "./Catalog.types";

import { ViewModes } from "./ui/Content/ViewModes/ViewModes";
import Sidebar from "./ui/Sidebar/Sidebar";
import NotFound from "./ui/NotFound/NotFound";
import { ActionBar } from "./ui/ActionBar/ActionBar";

type CatalogProps = {
  q: string;
  id: string;
  items: any[];
  isLoading: boolean;
  drawSearch: string;
  cat: string;
  sstype: SearchAsTypes;
  inclusions: string;
  isNothingFound: boolean;
  onHandleSetPageSize: (size: number) => void;
  onHandleSetViewMode: (mode: CatalogViewModes) => void;
  pageSize: number;
  viewMode: CatalogViewModes;
  pagination: CatalogPagination[];
  onHandleClickPagination: (id: number) => void;
  onHandleDownloadSdf: () => Promise<void>;
  isLoadingSdf: boolean;
  onHandleAllToCart: () => Promise<void>;
  isLoadingAllToCart: boolean;
  listName: string;
  listErrorType: string;
  completionState: string;
  completionPercent: number;
  breadcrumbs?: Breadcrumb[];
};

const Catalog = ({
  q,
  id,
  items = [],
  isLoading,
  drawSearch,
  cat,
  sstype,
  inclusions,
  isNothingFound,
  onHandleSetPageSize,
  onHandleSetViewMode,
  pageSize,
  viewMode,
  pagination,
  onHandleClickPagination,
  onHandleDownloadSdf,
  isLoadingSdf,
  onHandleAllToCart,
  isLoadingAllToCart,
  listName,
  listErrorType,
  completionState,
  completionPercent,
  breadcrumbs,
}: CatalogProps) => {
  const { pathname } = useLocation();

  const pathTokens: Breadcrumb[] = useMemo(() => {
    if (breadcrumbs) return breadcrumbs;

    if (pathname === "/" || !pathname.match(/categories/g)?.length)
      return undefined;

    const paths = [];

    pathname.split("/").reduce((prev, curr) => {
      if (curr) {
        const urlTitle = curr.split("-").join(" ");
        const title = `${urlTitle.charAt(0).toUpperCase()}${urlTitle.slice(1)}`;
        const currPath = `${prev}/${curr}`;

        paths.push({
          href: currPath + "/" === pathname ? "" : currPath,
          title,
        });

        return currPath;
      } else {
        return prev;
      }
    });

    return paths;
  }, [pathname, breadcrumbs]);

  const catalogTitle = useMemo(() => {
    return pathTokens?.length
      ? pathTokens[pathTokens.length - 1].title
      : id
      ? listName
      : "Search results";
  }, [id, listName, pathTokens]);

  const backToSearchHref = drawSearch ? "/draw-search" : "/";

  return (
    <Page>
      {pathTokens && (
        <div style={{ marginBottom: "-14px" }}>
          <Breadcrumbs items={pathTokens} />
        </div>
      )}
      <Container>
        {listErrorType ? (
          <>
            <Paper style={{ marginTop: "50px", paddingTop: "30px" }}>
              <NotFound
                message={
                  listErrorType === listErrorTypes.DISABLED
                    ? "Product list disabled"
                    : "Product list not found"
                }
              />
            </Paper>
          </>
        ) : (
          <>
            <PageHeader className="catalog-header">
              <Flex type="center-between">
                <Title variant="h1">{catalogTitle}</Title>
                {!isLoading && (
                  <ActionBar
                    onHandleDownloadSdf={onHandleDownloadSdf}
                    isLoadingSdf={isLoadingSdf}
                    onHandleAllToCart={onHandleAllToCart}
                    isLoadingAllToCart={isLoadingAllToCart}
                    isNothingFound={isNothingFound}
                    backToSearchHref={backToSearchHref}
                  />
                )}
              </Flex>
            </PageHeader>
            <Group>
              <GroupItem sidebar>
                <PageSidebar>
                  <Sidebar
                    q={q}
                    sstype={sstype}
                    cat={cat}
                    drawSearch={drawSearch}
                    inclusions={inclusions}
                  />
                </PageSidebar>

                {drawSearch && <RequestAnalogsCatalog />}
              </GroupItem>
              <GroupItem main>
                <Paper contentBlock hasMinHeight={isLoading || isNothingFound}>
                  {!isLoading && !isNothingFound && (
                    <CatalogHeader
                      pageSize={pageSize}
                      onHandleSetPageSize={onHandleSetPageSize}
                      onHandleSetViewMode={onHandleSetViewMode}
                      viewMode={viewMode}
                    />
                  )}
                  {isLoading && (
                    <LinearProgressBar
                      progressValue={completionPercent}
                      progressStatus={completionState}
                    />
                  )}
                  {items && Boolean(items?.length) && !isLoading && (
                    <List
                      hasFooter={
                        !pagination && viewMode === CatalogViewModes.LIST
                      }
                    >
                      {items.map((productWithPrices, index) => {
                        const { product } = productWithPrices;
                        return (
                          <ViewModes
                            key={product?.code || index}
                            productWithPrices={productWithPrices}
                            viewMode={viewMode}
                          />
                        );
                      })}
                    </List>
                  )}
                  {isNothingFound && <NotFound />}
                  {pagination && (
                    <PaginationWrapper>
                      <Pagination
                        pagination={pagination}
                        onHandleClickPagination={onHandleClickPagination}
                      />
                    </PaginationWrapper>
                  )}
                </Paper>
              </GroupItem>
            </Group>
          </>
        )}
      </Container>
    </Page>
  );
};

export default Catalog;
