import React, { useState, useEffect } from "react";
import { NavHashLink } from "react-router-hash-link";

export const NavItem = ({ data, activeId }) => {
  const { id, items, title } = data;

  const isActive =
    activeId === id || items?.some((item) => item.id === activeId);

  return (
    <li>
      {items && items.length ? (
        <details open={isActive}>
          <summary>
            <NavHashLink to={`#${id}`} className={isActive ? "activeLink" : ""}>
              {title}
            </NavHashLink>
          </summary>
          <NavList items={items} activeId={activeId} />
        </details>
      ) : (
        <NavHashLink to={`#${id}`} className={isActive ? "activeLink" : ""}>
          {title}
        </NavHashLink>
      )}
    </li>
  );
};

export const NavList = ({ items = [], activeId }) => {
  return (
    <ul>
      {items.map((item) => (
        <NavItem key={item.id} data={item} activeId={activeId} />
      ))}
    </ul>
  );
};

export const Navigation = ({ items = [] }) => {
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 130; // Offset for better detection

      // Get all H1, H2
      const headings = document.querySelectorAll("h1[id], h2[id]");
      let currentSectionId = null;

      headings.forEach((heading) => {
        const { top, bottom } = heading.getBoundingClientRect();
        const sectionTop = top + window.scrollY;

        if (
          scrollPosition >= sectionTop &&
          scrollPosition < bottom + window.scrollY
        ) {
          currentSectionId = heading.getAttribute("id");
        }
      });

      if (currentSectionId) {
        setActiveId(currentSectionId);
      }
    };

    document.addEventListener("scroll", handleScroll);
    handleScroll(); // Run on mount

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav>
      <NavList items={items} activeId={activeId} />
    </nav>
  );
};
