import { FormControl } from "@mui/material";
import { FormikProps } from "formik";
import PriceSelect from "parts/price-select/PriceSelect";
import React from "react";
import { customWeightOptions } from "shared/constants/Pricing.constants";
import { BucketItemWithImage, DeliveryDecision } from "shared/types/cartItem";
import { AvailabilityStatus } from "shared/types/delivery";
import { Currency, ProductPriceIssue } from "shared/types/pricing";
import { ProductWeight, ProductWeightStandard } from "shared/types/product";
import BaseNativeSelect from "shared/ui/BaseNativeSelect/BaseNativeSelect";
import { getPriceText } from "shared/utils/Cart.utils";
import { CustomizedTooltip, IconButton } from "ui";
import { CloseIcon, DangerMarkIcon, InfoIcon2 } from "ui/icons";
import DebouncedInput from "../DebouncedInput/DebouncedInput";
import {
  Code,
  Column,
  CustomWeightWrapper,
  DebouncedInputWrapper,
  DiscountPrice,
  ImageLink,
  InfoIconWrapper,
  Price,
  PriceContainer,
  PriceSelectWrapper,
  PriceWrapper,
  StockStatusWrapper,
  Wrapper,
} from "./CartItem.styled";

type CartItemProps = {
  cartItem: BucketItemWithImage;
  handleUpdateItemWeight: (newWeight: Partial<ProductWeight>) => void;
  handleItemRemove: (uuid: string) => void;
  currency: Currency;
  handleItemCount: (count: number) => void;
  isCartLoading: boolean;
  formik: FormikProps<{
    currentWeight: ProductWeight;
    customWeight: Omit<ProductWeight, "type">;
    counter: number;
  }>;
};

const CartItem = ({
  cartItem,
  currency,
  isCartLoading,
  handleUpdateItemWeight,
  handleItemRemove,
  handleItemCount,
  formik,
}: CartItemProps) => {
  const currentPriceAvailability =
    cartItem.prices?.all.find(
      (price) =>
        price?.weight?.amount === cartItem?.addedToBucket?.weight?.amount
    )?.delivery?.available === AvailabilityStatus.IN_STOCK
      ? "In Stock"
      : "Synthesis";

  const isCustomWeightItem =
    cartItem?.addedToBucket?.pay?.cannotCalculate?.reason ===
    ProductPriceIssue.SOME_PRODUCTS_HAVE_NON_STANDARD_WEIGHT;

  const isFeasibleItem =
    cartItem?.addedToBucket?.pay?.cannotCalculate?.reason ===
    ProductPriceIssue.SOME_PRODUCTS_PRICES_NOT_FOUND;

  const discount =
    Boolean(cartItem?.addedToBucket.pay.discountToPricePerOneCount) &&
    cartItem?.addedToBucket.pay.pricePerOneCount -
      cartItem?.addedToBucket.pay.discountToPricePerOneCount;

  const isDangerousGood =
    cartItem.addedToBucket?.deliveryDecision === DeliveryDecision.DANGEROUS;

  return (
    <Wrapper>
      <Column>
        <ImageLink
          style={{
            backgroundImage: `url(${cartItem?.image})`,
          }}
          alt="product"
          to={`/catalog/${cartItem.product.code}`}
        />
        {!isCustomWeightItem && (
          <StockStatusWrapper>{currentPriceAvailability}</StockStatusWrapper>
        )}
      </Column>

      <Column>
        <Code to={`/catalog/${cartItem.product.code}`}>
          <span>{cartItem.product.code}</span>
          {isDangerousGood && (
            <CustomizedTooltip
              title="This compounds is considered a Dangerous good for shipping. 
Our team will contact you with details on lead time and costs "
              placement="bottom"
            >
              <DangerMarkIcon width={18} height={18} />
            </CustomizedTooltip>
          )}
        </Code>
        <PriceContainer>
          <PriceSelectWrapper>
            <PriceSelect
              isCartItem
              prices={cartItem?.prices}
              currency={currency}
              formik={formik}
              disabled={isCartLoading}
              handleChange={handleUpdateItemWeight}
            />
          </PriceSelectWrapper>

          {Boolean(cartItem?.prices?.all?.length) && (
            <DebouncedInput
              onHandleChange={handleItemCount}
              count={formik.values.counter}
              hideButtons
              disabled={isCartLoading}
            />
          )}
        </PriceContainer>
        {formik.values?.currentWeight?.type ===
          ProductWeightStandard.WEIGHT_IS_NON_STANDARD && (
          <CustomWeightWrapper>
            <DebouncedInputWrapper>
              <DebouncedInput
                onHandleChange={(event) =>
                  handleUpdateItemWeight({ amount: event })
                }
                count={formik.values.customWeight.amount}
                hideButtons
                disabled={isCartLoading}
                allowDecimal={true}
                min={0.01}
                delay={3000}
                step={0.01}
              />
            </DebouncedInputWrapper>
            <FormControl
              error={
                formik.touched?.customWeight?.measure &&
                Boolean(formik.errors?.customWeight?.measure)
              }
              sx={{ width: "100%" }}
            >
              <BaseNativeSelect
                fieldName="customWeight.measure"
                formik={formik}
                options={customWeightOptions}
                onChange={(event) => {
                  const newMeasure = event.target.value;
                  formik.setFieldValue("customWeight.measure", newMeasure);
                  handleUpdateItemWeight({ measure: newMeasure });
                }}
                value={formik.values.customWeight.measure}
                disabled={isCartLoading}
              />
            </FormControl>
          </CustomWeightWrapper>
        )}
      </Column>

      <Column>
        <PriceWrapper>
          <Price isDiscount={discount}>
            {cartItem.addedToBucket.pay.fullyCalculated
              ? getPriceText(
                  cartItem.addedToBucket.pay.pricePerOneCount,
                  currency?.abbr
                )
              : getPriceText(0, currency?.abbr)}
          </Price>
          {discount && (
            <DiscountPrice>
              {getPriceText(discount, currency?.abbr)}
            </DiscountPrice>
          )}
          {isCustomWeightItem && (
            <CustomizedTooltip
              title={
                "Custom weight item. Price will be confirmed by our sales representative"
              }
              placement="top"
            >
              <InfoIconWrapper>
                <InfoIcon2 width={20} height={20} />
              </InfoIconWrapper>
            </CustomizedTooltip>
          )}
          {isFeasibleItem && (
            <CustomizedTooltip
              title="Feasible compound. Price will be 
confirmed by our sales representative"
              placement="top"
            >
              <InfoIconWrapper>
                <InfoIcon2 width={20} height={20} />
              </InfoIconWrapper>
            </CustomizedTooltip>
          )}
        </PriceWrapper>
      </Column>

      {/* @TODO style=... Temporary solution to fix issues on design */}
      <Column>
        <IconButton
          icon={<CloseIcon />}
          onClick={() => handleItemRemove(cartItem?.addedToBucket?.uuid)}
          size="24px"
          iconSize="24px"
          disabled={isCartLoading}
          style={{ position: "relative", top: "-3px" }}
        />
      </Column>
    </Wrapper>
  );
};

export default CartItem;
