import { SearchAsTypes, SearchAsTypesV2 } from "constants/searchAsTypes";
import { SearchByTypes } from "pages/home/parts/search-form/SearchForm.types";
import { uuid } from "utils";
import { isItemShouldHaveMockImage } from "../../../../shared/lib/REMOVE.utils";

export const prepareList = (list) => {
  return list.map(({ product, prices }) => {
    return {
      addedToBucket: {
        count: 1,
        weight: prices?.default?.weight,
        uuid: uuid(),
        pay: {
          priceExists: Boolean(prices?.default?.price),
          pricePerOneCount: prices?.default?.price,
          total: prices?.default?.price * 1,
        },
      },
      product,
      prices,
      // @TODO REMOVE THIS LOGIC AFTER DEMO!
      image: isItemShouldHaveMockImage(product.code)
        ? `/images/demoReplaceImages/${product.code}.png`
        : `/api/v2/catalog/all/images/by-code/${product.code}`,
    };
  });
};

export const getSearchType = (
  type?: string,
  searchBy?: SearchByTypes,
  sstype?: SearchAsTypes
) => {
  switch (true) {
    case !type && !searchBy:
      return "BY_CODES";

    case !type && searchBy === SearchByTypes.MFCDs:
      return "BY_MFCDS";

    case !type && searchBy === SearchByTypes.CASs:
      return "BY_CASES";

    case type && sstype && !searchBy:
      return `BY_SMILES_${SearchAsTypesV2[sstype]}`;

    default:
      return null;
  }
};
