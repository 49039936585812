import React, { useEffect, useState } from "react";
import { SectionWrapper } from "../../Checkout.styled";
import StepperCheckoutContainer from "./StepperCheckout/StepperCheckout.container";
import { checkoutActions, checkoutSelectors } from "../../../../redux/checkout";
import { useDispatch, useSelector } from "react-redux";
import RestrictionDialogContainer from "./RestrictionDialog/RestrictionDialog.container";
import PostalCodeIssueDialogContainer from "./PostalCodeIssueDialog/PostalCodeIssueDialog.container";
import { currencyActions, currencySelectors } from "../../../../redux/currency";
import { currencies } from "../../../../constants";
import { CanNotCalculateReasons } from "../../../../shared/types/Orders.types";

const Content = () => {
  const dispatch = useDispatch();

  const [isRestrictionDialogOpen, setIsRestrictionDialogOpen] = useState(false);
  const [isPostalCodeIssueDialogOpen, setIsPostalCodeIssueDialogOpen] =
    useState(false);

  const checkoutConditions = useSelector(
    checkoutSelectors.selectCheckoutConditions
  );

  const checkoutCalculations = useSelector(
    checkoutSelectors.selectCalculations
  );

  const selectedCurrency = useSelector(currencySelectors.selectCurrent);

  /*  
    SECTION. 
    Show restriction dialog if restrictions
  */
  useEffect(
    () => {
      if (
        !checkoutConditions?.allowed &&
        checkoutConditions?.notAllowedProductsInBucket?.length
      ) {
        dispatch(checkoutActions.openAccordion("step4"));
        setIsRestrictionDialogOpen(true);
      }
    },
    // eslint-disable-next-line
    [checkoutConditions]
  );
  /* === End === */

  /*
    SECTION.
    Show postal code issue dialog if USA_TAX_NOT_CALCULATED
  */
  useEffect(
    () => {
      if (
        checkoutCalculations?.pay?.cannotCalculate?.reason ===
        CanNotCalculateReasons.USA_TAX_NOT_CALCULATED
      ) {
        dispatch(checkoutActions.openAccordion("step4"));
        setIsPostalCodeIssueDialogOpen(true);
      }
    },
    // eslint-disable-next-line
    [checkoutCalculations]
  );
  /* === End === */

  /*
    SECTION.
    After calculations if we have not allowed currency that currently selected switch it to another from default currencies
  */
  useEffect(
    () => {
      const selectedCurrencyAbbr = selectedCurrency.abbr;
      const allowedCurrenciesMap =
        checkoutCalculations?.pay?.currencies?.allowed;

      if (allowedCurrenciesMap && !allowedCurrenciesMap[selectedCurrencyAbbr]) {
        dispatch(currencyActions.setDisableSelect(true));
        dispatch(
          currencyActions.setCurrent(
            currencies[Object.keys(allowedCurrenciesMap)[0]]
          )
        );
      }
    },
    // eslint-disable-next-line
    [checkoutCalculations, selectedCurrency]
  );
  /* === End === */

  return (
    <>
      <SectionWrapper>
        <StepperCheckoutContainer />
      </SectionWrapper>

      <RestrictionDialogContainer
        open={isRestrictionDialogOpen}
        onClose={() => setIsRestrictionDialogOpen(false)}
      />

      <PostalCodeIssueDialogContainer
        open={isPostalCodeIssueDialogOpen}
        onClose={() => setIsPostalCodeIssueDialogOpen(false)}
      />
    </>
  );
};

export default Content;
