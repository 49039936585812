import { DeliveryOptions } from "./delivery";
import { ProductWeight } from "./product";

export type PaymentDetails = {
  discountPercent: null | number;
  discountToPricePerOneCount: null | number;
  discountToTotal: null | number;
  fullyCalculated: boolean;
  cannotCalculate: CannotCalculate;
  pricePerOneCount: number;
  total: number;
};

export type CannotCalculate = {
  reason: ProductPriceIssue;
  details: string;
};

export enum ProductPriceIssue {
  SOME_PRODUCTS_PRICES_NOT_FOUND = "SOME_PRODUCTS_PRICES_NOT_FOUND",
  SOME_PRODUCTS_HAVE_NON_STANDARD_WEIGHT = "SOME_PRODUCTS_HAVE_NON_STANDARD_WEIGHT",
}

export type Currency = {
  abbr: string;
  symbol: string;
};

export type PricingDetails = {
  currency: CurrencyType;
  all: PriceDetails[];
  default: DefaultPrice;
};

export type PriceDetails = {
  weight: ProductWeight;
  price: number;
  delivery: DeliveryOptions;
};

export type DefaultPrice = {
  weight: ProductWeight;
  price: number;
  delivery: DeliveryOptions;
};

export enum CurrencyType {
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
}
