import { FormikProps } from "formik";
import React from "react";
import {
  ProductDetails,
  ProductWeight,
  ProductWeightStandard,
} from "shared/types/product";
import CustomWeight from "shared/ui/CustomWeight/CustomWeight";
import { ProductTypesV2 } from "../../constants/productTypes";
import { ReactComponent as CartIcon } from "../../icons/cart.svg";
import Counter from "../../pages/Product/ui/Counter/Counter";
import PriceSelect from "../../parts/price-select/PriceSelect";
import {
  ProductPriceAndSamples,
  ProductSample,
} from "../../typings/DTO/data-contracts";
import { Button, InfoList } from "../index";
import {
  CustomWeightWrapper,
  ListWrapper,
  Name,
  PriceSelectWrapper,
  PriceWrapper,
  StockAndCounterGroup,
  Wrapper,
} from "./one-product-card.styles";

const infoListConfig = {
  [ProductTypesV2.SCR]: {
    config: {
      catalog: { title: "Product class", order: 1 },
      molecularWeight: { title: "MW", order: 6 },
      purity: { title: "Purity", order: 11 },
      stocks: { title: "Stock Availability", order: 12 },
      leadTime: { title: "Lead time", order: 13 },
    },
    separators: [11],
    limit: null,
  },
  DEFAULT: {
    config: {
      catalog: { title: "Product class", order: 1 },
      CAS: { title: "CAS number", order: 4 },
      molecularWeight: { title: "MW", order: 6 },
      purity: { title: "Purity", order: 11 },
      stocks: { title: "Stock Availability", order: 12 },
      leadTime: { title: "Lead time", order: 13 },
    },
    separators: [11],
    limit: null,
  },
};

const getConfigByCatalog = (catalog: ProductTypesV2) => {
  return infoListConfig[catalog] || infoListConfig["DEFAULT"];
};

type OneProductCardProps = {
  isAddingToCart: boolean;
  productItem: ProductDetails;
  price: {
    prices: ProductPriceAndSamples;
    currentPrice: ProductSample;
    currency: { abbr: string; symbol: string };
  };
  buttonLabel: string;
  formik: FormikProps<{
    counter: number;
    currentWeight: ProductWeight;
    customWeight: Omit<ProductWeight, "type">;
  }>;
};

const OneProductCard = ({
  productItem,
  isAddingToCart,
  buttonLabel,
  price: { prices, currentPrice, currency },
  formik,
}: OneProductCardProps) => {
  const isCustomWeight =
    formik.values?.currentWeight?.type ===
    ProductWeightStandard.WEIGHT_IS_NON_STANDARD;
  return (
    <Wrapper className="one-product-card">
      <Name>{productItem?.code}</Name>
      <ListWrapper>
        <InfoList
          item={productItem}
          config={getConfigByCatalog(productItem.catalog)}
        />
      </ListWrapper>
      <form onSubmit={formik.handleSubmit}>
        <PriceWrapper>
          {!currentPrice || prices?.currency !== currency?.abbr ? (
            <div>Load prices...</div>
          ) : (
            <PriceSelectWrapper>
              <PriceSelect
                prices={prices}
                currency={currency}
                variant="white"
                formik={formik}
              />
            </PriceSelectWrapper>
          )}
        </PriceWrapper>
        {isCustomWeight && (
          <CustomWeightWrapper>
            <CustomWeight formik={formik} isOneProductCard />
          </CustomWeightWrapper>
        )}
        <StockAndCounterGroup>
          <Counter formik={formik} variant="white" />
        </StockAndCounterGroup>
        <Button
          iconLeft={<CartIcon />}
          onClick={formik.handleSubmit}
          fullWidth
          loading={isAddingToCart}
          disabled={!currentPrice || !!formik.errors?.customWeight?.amount}
        >
          {buttonLabel}
        </Button>
      </form>
    </Wrapper>
  );
};

export default OneProductCard;
