import { Prices, Weight } from "shared/types/cart";

export const getCurrentPrice = (prices: Prices, selectedWeight: Weight) => {
  if (!prices) return "empty";
  const { default: defaultPrice, all: allPrices } = prices;
  if (!allPrices?.length) return "empty";
  const matchedPrice = allPrices.find(
    ({ weight }) =>
      weight.measure === selectedWeight?.measure &&
      weight.amount === selectedWeight?.amount
  );
  return matchedPrice || defaultPrice || allPrices[0];
};
