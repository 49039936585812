import React, { useEffect, useState } from "react";
import { RequestAnalogsDialog } from "./RequestAnalogsDialog/RequestAnalogsDialog";
import { Button } from "@mui/material";
import { useAmplitudeState } from "../../../context/AmplitudeContext";
import { AmplitudeEvents } from "../../../context/AmplitudeEvents";

type RequestAnalogsProps = {
  fullWidth?: boolean;
  code?: string;
};

const RequestAnalogs = ({ fullWidth, code }: RequestAnalogsProps) => {
  const { amplitude } = useAmplitudeState();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (isDialogOpen) {
      amplitude.logEvent(AmplitudeEvents.Analogs.ANALOGS_OPEN);
    }
  }, [isDialogOpen, amplitude]);

  return (
    <>
      <Button
        variant="outlined"
        fullWidth={fullWidth}
        onClick={() => setIsDialogOpen(true)}
      >
        Request Analogs
      </Button>
      <RequestAnalogsDialog
        code={code}
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
      />
    </>
  );
};

export default RequestAnalogs;
