import { useQuery } from "@tanstack/react-query";
import endpoints from "constants/endpoints";
import { customFetchDelayedMiddleware } from "../../../context/sseContext";
import { RequestMethods } from "utils/customFetch";

export const getFilterClasses = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery({
    queryKey: [endpoints.CATALOG_FILTER_CLASSES],
    queryFn: () => {
      return customFetchDelayedMiddleware({
        method: RequestMethods.GET,
        url: endpoints.CATALOG_FILTER_CLASSES,
      });
    },
  });
};

export const getFilterSubClasses = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery({
    queryKey: [endpoints.CATALOG_FILTER_SUBCLASSES],
    queryFn: () => {
      return customFetchDelayedMiddleware({
        method: RequestMethods.GET,
        url: endpoints.CATALOG_FILTER_SUBCLASSES,
      });
    },
  });
};

export const getFilterCollections = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery({
    queryKey: [endpoints.CATALOG_FILTER_COLLECTIONS],
    queryFn: () => {
      return customFetchDelayedMiddleware({
        method: RequestMethods.GET,
        url: endpoints.CATALOG_FILTER_COLLECTIONS,
      });
    },
  });
};
