export const customWeightOptions = [
  {
    title: "g",
    value: "g",
  },
  {
    title: "mg",
    value: "mg",
  },
  {
    title: "mmol",
    value: "mmol",
  },
];
