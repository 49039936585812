import { Box, Container } from "@mui/material";
import { FullWidthLoader } from "parts";
import React from "react";
import { BucketItem, BucketItemWithImage } from "shared/types/cartItem";
import { Currency } from "shared/types/pricing";
import { EmptyCartView, Pagination, Paper, Section, Title } from "ui";
import CartItemContainer from "../CartItem/CartItem.container";
import ModalGroupUpdateContainer from "../ModalGroupUpdate/ModalGroupUpdateContainer";
import Summary from "../Summary/Summary";
import Header from "../header";
import {
  CartItemsWrapper,
  Column,
  PaginationWrapper,
  Wrapper,
} from "./CartPage.styled";
import { getCompoundTooltip } from "shared/utils/Cart.utils";
import { CartErrorView } from "../ui/CartErrorView/CartErrorView";

type PaginationItem = {
  id: number;
  title: string | number;
  active: boolean;
};

type CartPageProps = {
  currency: Currency;
  downloadSdf: {
    isLoading: boolean;
    handleDownload: () => void;
    error: boolean;
  };
  isCartEmpty: boolean;
  pageItems: BucketItemWithImage[];
  allItems: BucketItem[];
  pagination: PaginationItem[] | null;
  isLoading: boolean;
  loadingRest: boolean;
  subtotal: string;
  handleClickPagination: (page: number) => void;
  handleClearCart: () => Promise<void>;
  handleGroupUpdate: () => void;
  quote: {
    open: boolean;
    setOpen: (open: boolean) => void;
    handleRequestQuote: () => void;
  };
  isCartLoading: boolean;
  isModalGroupUpdateOpen: boolean;
  discount: number;
  isCartError?: boolean;
};

const CartPage = ({
  currency,
  downloadSdf,
  isCartEmpty,
  pageItems,
  allItems,
  pagination,
  isLoading,
  loadingRest,
  subtotal,
  handleClickPagination,
  handleClearCart,
  handleGroupUpdate,
  isCartLoading,
  discount,
  isModalGroupUpdateOpen,
  isCartError,
}: CartPageProps) => {
  const tooltipText = getCompoundTooltip(allItems);

  if (isLoading) return <FullWidthLoader />;
  if (isCartError) return <CartErrorView />;
  if (isCartEmpty) return <EmptyCartView />;

  return (
    <Section>
      <Container>
        <ModalGroupUpdateContainer
          cartItems={allItems}
          isOpen={isModalGroupUpdateOpen}
          onClose={handleGroupUpdate}
        />
        <Header
          handleClearCart={handleClearCart}
          downloadSdf={downloadSdf}
          showSelect={pageItems.length}
          handleGroupUpdate={handleGroupUpdate}
        />

        <Wrapper>
          <Column main>
            <Paper smallPaddings>
              <Title>Items</Title>

              <CartItemsWrapper>
                {pageItems.map((cartItem) => {
                  return (
                    <Box key={cartItem.addedToBucket.uuid}>
                      <CartItemContainer
                        cartItem={cartItem}
                        currency={currency}
                        isCartLoading={isCartLoading}
                      />
                    </Box>
                  );
                })}
              </CartItemsWrapper>

              {Boolean(pagination?.length) && (
                <PaginationWrapper mt>
                  <Pagination
                    pagination={pagination}
                    onHandleClickPagination={handleClickPagination}
                  />
                </PaginationWrapper>
              )}
            </Paper>
          </Column>

          <Column>
            <Summary
              tooltipText={tooltipText}
              subtotal={subtotal}
              isLoading={loadingRest || isCartLoading}
              currency={currency}
              discount={discount}
            />
          </Column>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default CartPage;
