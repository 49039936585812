import {
  FormWrapper,
  TextareaStyled,
} from "../RequestAnalogsDialog/RequestAnalogsDialog.styled";
import React from "react";

export const RegisteredForm = ({ formik }) => (
  <FormWrapper>
    <TextareaStyled
      label="Comment"
      name="text"
      formik={formik}
      dataTestAttr="request-comment"
    />
  </FormWrapper>
);
