import React from "react";
import { Box, Chip } from "@mui/material";
import BeautifyValue from "utils/beautifyValue";
import { NormalizedCatalogName } from "../../../../../../constants/productTypes";
import { Code } from "../ViewModes.styled";
import {
  Image,
  ImageWrapper,
  InfoChipWrapper,
  InfoWrapper,
  ProductInfo,
  ProductInfoItem,
  Wrapper,
} from "./ListCard.styled";
import ListCardTable from "./ListCardTable/ListCardTable";
import { ProductPurity } from "../../ProductPurity/ProductPurity";

export const ListCard = ({
  catalogItem,
  code,
  handleClick,
  href,
  isAddingToCart,
  currency,
  handleAddListItemToCart,
  formik,
}) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image
          style={{
            backgroundImage: `url(${catalogItem.image})`,
          }}
          onClick={handleClick}
          to={href}
        />
        <InfoChipWrapper>
          {catalogItem?.inclusion?.bindWith && (
            <Chip
              label={`SALT OF ${catalogItem?.inclusion?.bindWith}`}
              size="small"
              color="primary"
            />
          )}
        </InfoChipWrapper>
      </ImageWrapper>
      <InfoWrapper>
        <Code to={href} onClick={handleClick}>
          {code}
        </Code>
        <ProductInfo>
          <ProductInfoItem>
            <Box>Product class</Box>
            <Box>{NormalizedCatalogName[catalogItem?.product?.catalog]}</Box>
          </ProductInfoItem>
          <ProductInfoItem>
            <Box>Name</Box>
            <Box>{catalogItem?.product?.name}</Box>
          </ProductInfoItem>
          {catalogItem?.product?.purity && (
            <ProductInfoItem>
              <Box>Purity</Box>
              <Box>
                <ProductPurity purityValue={catalogItem?.product?.purity} />
              </Box>
            </ProductInfoItem>
          )}
          <ProductInfoItem>
            <Box>CAS number</Box>
            <Box>{catalogItem?.product?.CAS}</Box>
          </ProductInfoItem>
          <ProductInfoItem>
            <Box>Formula</Box>
            <Box>{BeautifyValue("formula", catalogItem?.product?.formula)}</Box>
          </ProductInfoItem>
        </ProductInfo>

        <ListCardTable
          formik={formik}
          catalogItem={catalogItem}
          currency={currency}
          handleAddListItemToCart={handleAddListItemToCart}
          isAddingToCart={isAddingToCart}
        />
      </InfoWrapper>
    </Wrapper>
  );
};
