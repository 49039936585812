export enum FilterTypes {
  sstype = "sstype",
  category = "category",
  LOCATION = "location",
  classes = "classes",
  subclasses = "subclasses",
  MW = "MW",
  CLogP = "CLogP",
  HBA = "HBA",
  HBD = "HBD",
  RotB = "RotB",
  TPSA = "TPSA",
  HAC = "HAC",
  Fsp3 = "Fsp3",
  collections = "collections",
  similarity = "similarity",
}

export enum SearchStatus {
  FIND_COMPOUNDS_STARTED = "Searching",
  FIND_COMPOUNDS_COMPLETED = "Getting Products",
  FIND_COMPOUNDS_PRICES_COMPLETED = "Getting Prices",
  FIND_COMPOUNDS_INFO_COMPLETED = "Loading Results",

  FIND_EQUIVALENTS_STARTED = "Find equivalents started",
  FIND_EQUIVALENTS_COMPLETED = "Find equivalents completed",
  FIND_EQUIVALENTS_PRICES_COMPLETED = "Find equivalents prices completed",
  FIND_EQUIVALENTS_INFO_COMPLETED = "Find equivalents info completed",

  FIND_FORMS_STARTED = "Find forms started",
  FIND_FORMS_COMPLETED = "Find forms completed",
  FIND_FORMS_PRICES_COMPLETED = "Find forms prices completed",
  FIND_FORMS_INFO_COMPLETED = "Find forms info completed",

  FIND_ANALOGS_STARTED = "Find analogs started",
  FIND_ANALOGS_COMPLETED = "Find analogs completed",
  FIND_ANALOGS_PRICES_COMPLETED = "Find analogs prices completed",
  FIND_ANALOGS_INFO_COMPLETED = "Find analogs info completed",
}

export type CatalogPagination = {
  id: number;
  title: number;
  active: boolean;
};

export type CatalogImage = {
  data: string;
  code: string;
};

export enum CatalogViewModes {
  LIST = "list",
  CARD = "card",
  SIMPLE = "SIMPLE",
}

export enum SearchType {
  STRUCTURE = "STRUCTURE",
  BULK = "BULK",
  PHRASE = "PHRASE",
}
