import React from "react";
import { CategoryItem } from "../SubCategoryTypes";
import {
  SubCategoryCardTitle,
  SubCategoryCardWrapper,
} from "./SubCategoryCard.styled";

type SubCategoryCardProps = {
  cardItem: CategoryItem;
  onCardClick: (subcategory: CategoryItem) => void;
};

const SubCategoryCard = ({ cardItem, onCardClick }: SubCategoryCardProps) => {
  return (
    <SubCategoryCardWrapper onClick={() => onCardClick(cardItem)}>
      <SubCategoryCardTitle>{cardItem?.name}</SubCategoryCardTitle>
    </SubCategoryCardWrapper>
  );
};

export default SubCategoryCard;
