import React from "react";
import {
  DocumentationWrapper,
  NavigationWrapper,
} from "./Documentation.styled";
import Markdown from "markdown-to-jsx";
import { Navigation } from "./Navigation/Navigation";
import { slugify } from "../../lib/Documentation.utils";
import { useDocumentation } from "../../lib/hooks/useDocumentation";

export const Documentation = ({ docList }) => {
  const { documents, navigation } = useDocumentation(docList);

  return (
    <DocumentationWrapper>
      <NavigationWrapper>
        <Navigation items={navigation} />
      </NavigationWrapper>
      <article>
        {documents.map(({ document, id }) => {
          return (
            <Markdown
              key={id}
              options={{
                wrapper: "section",
                slugify: slugify(id),
              }}
            >
              {document}
            </Markdown>
          );
        })}
      </article>
    </DocumentationWrapper>
  );
};
