import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { accentColor, blue, darkBlue, lightBlue } from "ui/colors";

export const BreadCrumbsWrapper = styled.div`
  margin-bottom: 12px;
`;

export const HeadingTypography = styled(Typography)`
  color: ${darkBlue};
  font-size: 36px;
  line-height: 100%;
  letter-spacing: 0;
  font-weight: 600;
`;

export const BodyTextTypography = styled(Typography)`
  margin-top: 18px;
  margin-bottom: 28px;
  color: ${blue};
`;

export const SubHeadingTypography = styled(Typography)`
  color: ${darkBlue};
  line-height: 100%;
  letter-spacing: 0;
  font-weight: 400;
`;

export const ContainerSupportWrapperGrid = styled(Grid)`
  margin-bottom: 60px;
`;

export const StyledContactsPaper = styled(Paper)`
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  background-color: ${lightBlue};
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid ${lightBlue};

  &:hover {
    border-color: ${accentColor};
  }
`;

export const DescriptionTypography = styled(Typography)`
  color: ${blue};
  line-height: 110%;
  letter-spacing: 0;
  font-weight: 400;
  margin-top: 10px;
`;

export const IconWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    path {
      fill: ${accentColor};
    }
  }
`;

export const DistributorsOfficesWrapper = styled.div`
  margin-top: 28px;
  margin-bottom: 60px;
`;

export const OfficesImageWrapper = styled.div`
  display: grid;
  margin-bottom: 60px;
  place-items: center;

  img {
    width: 100%;
    max-width: 970px;
    height: auto;
  }
`;

export const StyledPaper = styled(Paper)`
  padding: 24px;
  border-radius: 12px;
  display: grid;
  gap: 18px;
  border: 1px solid ${lightBlue};
  color: ${darkBlue};
`;

export const LocationContainer = styled.div`
  display: flex;
  justify-items: center;
  gap: 22px;
`;

export const OfficeDescriptionTypography = styled(Typography)`
  font-weight: 400;
`;

export const OfficeDetailsTypography = styled(Typography)`
  font-weight: 500;
  color: ${accentColor};
  line-height: 100%;
  margin-top: 8px;
`;

export const USADetails = styled.span`
  font-weight: 500;
  color: ${accentColor};
  font-size: 16px;
  font-weight: 500;
`;

export const LocationIconWrapper = styled.div`
  background-color: ${lightBlue};
  width: 64px;
  height: 64px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const OfficeLocationTypography = styled(Typography)`
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  color: ${blue};
`;

export const OfficeNameTypography = styled(Typography)`
  letter-spacing: 0;
  line-height: 32px;
  font-weight: 500;
  color: ${accentColor};
`;

export const WebsiteTypography = styled(Typography)`
  font-weight: 500;
  color: ${accentColor};
`;

export const StyledAccordion = styled(Accordion)`
  background-color: ${lightBlue};
  border-radius: 8px !important;
  box-shadow: none;
`;
export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0 24px 24px 24px;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  margin: 0;
  padding: 24px;

  &.MuiAccordionSummary-gutters {
    min-height: unset;
  }

  .MuiAccordionSummary-contentGutters {
    margin: 0;
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

export const DetailsWrapper = styled.a`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: ${blue};
`;
