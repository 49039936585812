import { Box } from "@mui/material";
import React from "react";
import { CustomizedTooltip } from "ui";
import { InfoIcon2 } from "ui/icons";
import {
  OrderDetailsItem,
  OrderDetailsItemTitleWrapper,
  OrderDetailsWrapper,
  SubtotalWithHintWrapper,
} from "./OrderPricesDetailsV2.styled";
import SummaryItem from "./SummaryItem/SummaryItem";
import { BoldWrapper } from "./SummaryItem/SummaryItem.styled";
// @TODO Check dependencies
import ZeroCostsCalculatedTooltip from "../../../pages/profile/pages/ArchivedOrder/parts/ZeroCostsCalculatedTooltip";
import { PromoCode } from "../../../pages/profile/pages/OrderV2/lib/Order.types";

type OrderDetailsProps = {
  price: any;
  currencyAbbr?: string;
  tooltipText: string | null;
  promoCode?: PromoCode;
  showPromoCode?: boolean;
  type?: string;
};

const OrderPricesDetailsV2 = ({
  price,
  currencyAbbr,
  tooltipText,
  promoCode,
  showPromoCode,
  type,
}: OrderDetailsProps) => {
  const showZeroCostsTooltip =
    price?.costsCalculated === false && type === "QUOTE";

  const showHandlingShipping = Boolean(price?.handling ?? price?.shipping);

  return (
    <OrderDetailsWrapper>
      <OrderDetailsItem>
        <Box>
          <SubtotalWithHintWrapper>
            <span>Subtotal</span>
            {tooltipText && (
              <CustomizedTooltip title={tooltipText} placement="right">
                <InfoIcon2 width={18} height={18} />
              </CustomizedTooltip>
            )}
          </SubtotalWithHintWrapper>
          <SummaryItem currencyAbbr={currencyAbbr} price={price?.products} />
        </Box>
      </OrderDetailsItem>
      {showHandlingShipping && (
        <OrderDetailsItem>
          <Box>
            <span>
              Handling
              {showZeroCostsTooltip && <ZeroCostsCalculatedTooltip />}
            </span>
            <SummaryItem currencyAbbr={currencyAbbr} price={price?.handling} />
          </Box>

          <Box>
            <span>
              Shipping
              {showZeroCostsTooltip && <ZeroCostsCalculatedTooltip />}
            </span>
            <SummaryItem currencyAbbr={currencyAbbr} price={price?.shipping} />
          </Box>
        </OrderDetailsItem>
      )}

      <OrderDetailsItem>
        {showHandlingShipping && (
          <Box>
            <span>Tax</span>
            <SummaryItem currencyAbbr={currencyAbbr} price={price?.tax} />
          </Box>
        )}
        <Box>
          <OrderDetailsItemTitleWrapper>
            <span>{promoCode?.applied ? "Promo Code" : "Discount"} </span>
            {showPromoCode && promoCode?.value ? (
              <span style={{ fontWeight: "500" }}>{promoCode?.value}</span>
            ) : (
              ""
            )}
          </OrderDetailsItemTitleWrapper>
          <Box>
            {price?.productsDiscount ? "-" : ""}{" "}
            <SummaryItem
              currencyAbbr={currencyAbbr}
              price={price?.productsDiscount}
            />
          </Box>
        </Box>
      </OrderDetailsItem>
      <OrderDetailsItem>
        <Box>
          <span>
            <b>Total price</b>
          </span>
          <BoldWrapper>
            <SummaryItem currencyAbbr={currencyAbbr} price={price?.total} />
          </BoldWrapper>
        </Box>
      </OrderDetailsItem>
    </OrderDetailsWrapper>
  );
};

export default OrderPricesDetailsV2;
