const catalogViewModes = {
  LIST: "list",
  CARD: "cards",
  SIMPLE: "SIMPLE",
};

export enum CatalogViewModes {
  LIST = "list",
  CARD = "cards",
  SIMPLE = "SIMPLE",
}

export default catalogViewModes;
