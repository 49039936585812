import { colors } from "@mui/material";
import { createTheme } from "@mui/material";
import { defaultTheme } from "./constants/theme";
import {
  accentColor,
  blue,
  borderColor,
  dangerColor,
  darkBlue,
  darkWarningColor,
  hoverDangerColor,
  hoverSuccessColor,
  lightBlue,
  lightGray,
  lineColor,
  progressBarDark,
  progressBarLight,
  successColor,
} from "./ui/colors";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1360,
      xl: 1536,
    },
  },
  palette: {
    borderColor: defaultTheme.borderColor,
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: colors.red.A700,
      // @TODO Check property and if it affects some parts of app
      // eslint-disable-next-line
      // @ts-expect-error
      text: "#d92d26",
    },
    background: {
      default: "white",
    },
  },
  typography: {
    h1: {
      fontSize: "32px",
      fontWeight: 700,
      letterSpacing: "normal",
    },
    h2: {
      fontSize: "28px",
      fontWeight: 700,
    },
    h3: {
      fontSize: "24px",
      fontWeight: 700,
    },
    h4: {
      fontSize: "22px",
      fontWeight: 600,
    },
    h5: {
      fontSize: "20px",
      fontWeight: 600,
    },
    h6: {
      fontSize: "18px",
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: 700,
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
    },
    caption: {
      fontSize: "13px",
      fontWeight: 400,
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        container: {
          minHeight: "100%",
        },
        paper: {
          boxShadow: "none",
          borderRadius: "8px",
          color: darkBlue,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          color: darkBlue,
          "&.Mui-error": {
            "& input": {
              "&::placeholder": {
                color: dangerColor,
                opacity: 1,
              },
            },
            "& select": {
              color: dangerColor,
            },
          },
        },
        input: {
          padding: "12px 14px",
          "&.MuiOutlinedInput-input": {
            padding: "12px 14px",
          },
          "&::placeholder": {
            color: `${borderColor}`,
            opacity: 1,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline":
            {
              borderWidth: "1px !important",
              borderColor: `${accentColor} !important`,
            },
        },

        notchedOutline: {
          borderColor: `${borderColor}`,
          "&:hover": {
            borderColor: "red",
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        icon: {
          color: darkBlue,
          width: "16px",
          height: "16px",
          right: "16px",
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "8px",
          transition: "none",
          fontSize: "18px",
          fontWeight: "400",
          minHeight: "48px",
          boxShadow: "none",
          padding: "6px 27px",
          letterSpacing: "normal",
          color: "#fff",
          "&.Mui-disabled": {
            backgroundColor: "#B3D6EC",
            color: "#fff",
            borderColor: "transparent",
            boxShadow: "none",
          },
          ...(ownerState.variant === "outlined" && {
            borderColor: accentColor,
            color: accentColor,
            background: "#fff",
            "&:hover": {
              color: "#fff",
              backgroundColor: accentColor,
              borderColor: accentColor,
            },
          }),
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "error" && {
              backgroundColor: "#fff",
              borderColor: dangerColor,
              color: dangerColor,
              "&:hover": {
                color: "#fff",
                backgroundColor: hoverDangerColor,
                borderColor: hoverDangerColor,
              },
            }),
          ...(ownerState.variant === "contained" && {
            backgroundColor: accentColor,
            "&:hover": {
              backgroundColor: hoverSuccessColor,
              boxShadow: "none",
            },
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "success" && {
              backgroundColor: successColor,
              "&:hover": {
                backgroundColor: hoverSuccessColor,
                boxShadow: "none",
              },
            }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "error" && {
              backgroundColor: dangerColor,
              "&:hover": {
                backgroundColor: hoverDangerColor,
                boxShadow: "none",
              },
            }),
        }),
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            borderRadius: "8px",
            minHeight: "48px",
          },
          "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: `${borderColor}`,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline":
              {
                borderWidth: "1px !important",
                borderColor: `${accentColor} !important`,
              },
          },

          "& .MuiFormHelperText-root.Mui-error": {
            fontSize: "14px",
            margin: "4px 0 0",
          },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          letterSpacing: "normal",
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiFormHelperText-root.Mui-error": {
            fontSize: "14px",
            margin: "4px 0 0",
          },
        },
      },
    },

    MuiRadio: {
      // @TODO Correct styles for MuiRadio global! Don't touch or recheck everywhere
      styleOverrides: {
        root: {
          color: borderColor,
          padding: "5px",
          "&.Mui-checked": {
            color: accentColor,
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment":
            {
              right: "16px",
            },
          "&.Mui-focused": {
            border: 0,
            outline: 0,
          },
          "& .MuiInputBase-root": {
            borderRadius: "8px",
            paddingLeft: "12px",
            paddingRight: "45px",
            "& input::placeholder": {
              color: borderColor,
              opacity: 1,
            },
            "& .MuiAutocomplete-input": {
              padding: "0 4px",
            },
          },
          "& .MuiChip-root": {
            height: "24px",
            margin: "3px 4px",
          },
          "& .MuiChip-label": { fontSize: "14px", paddingRight: "10px" },
          ".MuiAutocomplete-tag": {
            background: lightBlue,
          },
          "& .MuiOutlinedInput-notchedOutline": { borderColor: borderColor },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline":
            {
              borderWidth: "1px !important",
              borderColor: `${accentColor} !important`,
            },
          "& .MuiChip-root .MuiChip-deleteIcon": {
            fontSize: "12px",
            color: accentColor,
            marginRight: "10px",
          },
        },
      },
    },

    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "0",
        },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          border: `1px solid ${lightGray}`,
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          background: lightGray,
        },
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            padding: "10px 12px",
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          color: darkBlue,
          fontSize: "16px",
          padding: "8px 12px",
          borderBottom: `1px solid ${lineColor}`,
          "&.MuiTableCell-head": {
            fontSize: "16px",
            padding: "12px 18px",
            lineHeight: 1,
            color: darkBlue,
            border: "none",
          },
          "&.MuiTableCell-body": {
            padding: "8px 18px",
          },
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 9,
          borderRadius: 5,
          backgroundColor: progressBarLight,
        },
        bar: {
          backgroundColor: progressBarDark,
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 38,
          height: 20,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            transform: "translateX(2px)",
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              transform: "translateX(17px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                backgroundColor: accentColor,
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: accentColor,
              border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color: "red",
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.7,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 16,
            height: 16,
            boxShadow: "none",
          },
          "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: lineColor,
            opacity: 1,
          },
        },
      },
    },

    // @TODO Recheck this one and compare to MUIChip below
    // MuiChip: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: lightBlue,
    //       color: accentColor,
    //       "&.MuiChip-sizeSmall": {
    //         height: "26px",
    //       },
    //     },
    //     label: {
    //       padding: "0 12px",
    //       fontWeight: "500",
    //     },
    //   },
    // },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(23, 43, 78, 0.3)",
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        icon: {
          fontSize: "24px",
          marginRight: "8px",
        },
        standardInfo: {
          borderRadius: "8px",
          color: accentColor,
          backgroundColor: lightBlue,
          letterSpacing: "normal",
          fontSize: "16px",
          minHeight: "56px",
          alignItems: "center",
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: lightBlue,
          color: accentColor,
          "&.MuiChip-sizeSmall": {
            height: "26px",
          },
          ...(ownerState.color === "primary" && {
            borderColor: accentColor,
            color: accentColor,
            backgroundColor: lightBlue,
          }),
          ...(ownerState.color === "default" && {
            borderColor: blue,
            color: blue,
            backgroundColor: lightGray,
          }),
          ...(ownerState.color === "success" && {
            color: successColor,
            borderColor: successColor,
            backgroundColor: "rgba(20, 181, 113, 0.05)",
          }),
          ...(ownerState.color === "warning" && {
            color: darkWarningColor,
            borderColor: darkWarningColor,
          }),
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: darkBlue,
          "&:hover": {
            color: accentColor,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
  },
});

export default theme;
