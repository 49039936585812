import styled from "styled-components";
import { dangerColor } from "ui/colors";
import { Typography } from "@mui/material";

export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${dangerColor};
  margin-top: 12px;
`;

export const InputWrapper = styled.div`
  padding-bottom: 16px;
`;

export const ButtonWrapper = styled.div`
  padding-top: 8px;
`;

export const TitleTypography = styled(Typography)`
  display: flex;
  align-items: center;
  min-height: 48px;
`;
