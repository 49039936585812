import styled from "styled-components";
import { lineColor, lightGray, blue } from "ui/colors";

export const List = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid ${lineColor};
  border-radius: 8px;
`;
List.displayName = "List";

export const Row = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${lineColor};

  &:last-child {
    border: none;
  }
`;
Row.displayName = "Row";

export const Cell = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  border-right: 1px solid ${lineColor};
  width: 50%;

  &:last-child {
    border: none;
  }
`;
Cell.displayName = "Cell";

export const Label = styled.div`
  padding: 16px;
  padding-left: 32px;
  width: 244px;
  min-width: 244px;
  text-align: right;
  color: ${blue};
  font-weight: 600;
  background-color: ${lightGray};
  border-right: 1px solid ${lineColor};
`;
Label.displayName = "Label";

export const Value = styled.div`
  overflow: hidden;
  color: ${blue};
  font-weight: 400;
  padding: 16px;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
`;
Value.displayName = "Value";

export default {
  List,
  Row,
  Cell,
  Label,
  Value,
};
