import React from "react";
import { BucketItem } from "shared/types/cartItem";
import ProductCard from "./ProductCard";
import { useProductCardProps } from "shared/hooks/useProductCardProps";

type ProductCardContainerProps = {
  item: BucketItem;
  isRelated?: boolean;
  proposal: any;
  listMode?: any;
};

const ProductCardContainer = ({
  item = {},
  listMode,
  isRelated,
  proposal,
}: ProductCardContainerProps) => {
  const {
    buttonLabel,
    currency,
    currentPrice,
    readyToCart,
    href,
    handleClick,
    isAddingToCart,
    formik,
  } = useProductCardProps({
    item,
    isRelated,
    proposal,
  });

  return (
    <ProductCard
      item={item}
      handleClick={handleClick}
      code={item?.product?.code || ""}
      buttonLabel={buttonLabel}
      currentPrice={currentPrice}
      currency={currency}
      formik={formik}
      readyToCart={readyToCart}
      isAddingToCart={isAddingToCart}
      listMode={listMode}
      href={href}
    />
  );
};

export default ProductCardContainer;
