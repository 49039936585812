import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import { blue } from "../../../../../../ui/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 88px;
`;

export const TitleWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

export const CaptionWrapper = styled.div`
  font-size: 12px;
  color: ${blue};
  margin-top: 6px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
`;

export const Info = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${blue};
`;

export const Title = styled(Typography)`
  word-break: break-all;
  max-width: 250px;
  font-weight: 600;
`;

export const Image = styled.div`
  width: 88px;
  height: 88px;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
`;

export const Row = styled.div`
  min-width: 200px;
`;
