import { Container } from "@mui/material";
import { endpoints } from "constants";
import { events, useAmplitudeState } from "context/AmplitudeContext";
import { customFetchDelayedMiddleware } from "context/sseContext";
import useLoaderFullPage from "features/Loaders/hooks/useLoaderFullPage";
import { CatalogContainer } from "pages/CatalogV2/Catalog.container";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumb } from "shared/types/breadcrumb";
import { RequestMethods } from "utils/customFetch";
import { Breadcrumbs, Flex, Title } from "../../ui";
import { PageHeader } from "../../ui/page-header/page-header.styles";
import { SubCategoryWrapper } from "./SubCategory.styled";
import { CategoryItem, CategoryResponse } from "./SubCategoryTypes";
import SubCategoryCard from "./ui/SubCategoryCard";

const SubCategory = () => {
  const { showLoaderFillPage, hideLoaderFillPage } = useLoaderFullPage();
  const { path } = useParams<{ path: string }>();
  const { amplitude } = useAmplitudeState();
  const history = useHistory();
  const [subcategories, setSubcategories] = useState<CategoryResponse>(null);

  const fetchCategories = async () => {
    try {
      showLoaderFillPage();
      const response = await customFetchDelayedMiddleware({
        url: `${endpoints.CATALOG_CATEGORIES_HOME}`,
        method: RequestMethods.GET,
      });

      if (response[0]) {
        setSubcategories({ subcategories: response[0] } as CategoryResponse);
      } else {
        toast.error(response[1] as string);
      }
    } finally {
      hideLoaderFillPage();
    }
  };

  const fetchSubcategory = async () => {
    try {
      showLoaderFillPage();
      const response = await customFetchDelayedMiddleware({
        url: `${endpoints.CATALOG_CATEGORIES_BY_PATH}/${path}`,
        method: RequestMethods.GET,
      });
      if (response[0]) {
        setSubcategories(response[0] as CategoryResponse);
      } else {
        toast.error(response[1] as string);
      }
    } finally {
      hideLoaderFillPage();
    }
  };

  useEffect(() => {
    if (!path) fetchCategories();
    else fetchSubcategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const onSubcategoryCardClick = (subCategory: CategoryItem) => {
    amplitude.logEvent(events.category_click, { type: subCategory?.name });
    history.push(`/categories/${path ? `${path}/` : ""}${subCategory.urlName}`);
  };

  const preparedCodes = subcategories?.products
    ?.map(({ code }) => code)
    ?.join(", ");

  const breadcrumbItems = useMemo(() => {
    if (!subcategories?.parentsPath)
      return [
        {
          title: "Categories",
          href: "",
        },
      ];

    const breadCrumbs: Breadcrumb[] = [
      {
        title: "Categories",
        href: "/categories",
      },
    ];

    let accumulatedPath = "";
    subcategories.parentsPath.forEach((parent) => {
      accumulatedPath += `/${parent.urlName}`;
      breadCrumbs.push({
        title: parent.name,
        href: `/categories${accumulatedPath}`,
      });
    });
    breadCrumbs.push({
      title: subcategories.name,
      href: "",
    });
    return breadCrumbs;
  }, [subcategories]);

  return (
    <>
      {subcategories?.products?.length ? (
        <CatalogContainer
          productCodes={preparedCodes}
          breadcrumbs={breadcrumbItems}
        />
      ) : (
        <>
          <Breadcrumbs items={breadcrumbItems} />
          <Container>
            <PageHeader className="catalog-header">
              <Flex type="center-between">
                <Title variant="h1">{subcategories?.name}</Title>
              </Flex>
            </PageHeader>
            {subcategories?.subcategories && (
              <SubCategoryWrapper>
                {subcategories.subcategories.map((subcategory) => (
                  <SubCategoryCard
                    key={subcategory?.urlName}
                    cardItem={subcategory}
                    onCardClick={onSubcategoryCardClick}
                  />
                ))}
              </SubCategoryWrapper>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default SubCategory;
