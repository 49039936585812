import React from "react";
import { Switch, Route } from "react-router-dom";
import { Section } from "ui";
import { Documentation } from "./ui/Documentation/Documentation";
import apiDocsList from "./lib/searchApiConfig";
import { ApiContainer } from "./ui/Documentation/Documentation.styled";

export const DocumentationRoutes = () => {
  return (
    <ApiContainer>
      <Section
        fullHeight={undefined}
        ySpacing={undefined}
        deleteXSpacing={undefined}
      >
        <Switch>
          <Route path={["/documentation", "/documentation/search-api"]}>
            <Documentation docList={apiDocsList} />
          </Route>
        </Switch>
      </Section>
    </ApiContainer>
  );
};
