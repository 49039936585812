import { useHistory } from "react-router";
import { useQuery } from "hooks";
import { encode, makeParams } from "utils";
import { useCallback } from "react";

// @TODO Check purpose of this hook
export const useSearchRedirect = () => {
  const history = useHistory();
  const query = useQuery();
  const cat = query.get("cat");
  const q = query.get("q");
  const type = query.get("type");
  const sstype = query.get("sstype");
  const drawSearch = query.get("drawSearch");

  const makeRedirect = useCallback(
    (options) => {
      const queryParams = makeParams({
        q: encode(q),
        cat,
        type,
        sstype,
        drawSearch,
        curPage: 1,
        ...options,
      });

      history.push(`/catalog?${queryParams}`);
    },
    [history, sstype, type, cat, drawSearch, q]
  );

  return {
    makeRedirect,
  };
};
