import React from "react";
import { NotFoundIcon, SearchIcon } from "ui/icons";
import { Button, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { common } from "../../../../constants";
import RequestAnalogs from "../../../../shared/ui/RequestAnalogsDialog/RequestAnalogs";
import {
  Wrapper,
  ImageWrapper,
  SubTitleTypography,
  TitleTypography,
  DescriptionWrapper,
  ButtonsWrapper,
} from "./ProductNotFound.styled";

type ProductNotFoundProps = {
  code: string;
};

const ProductNotFound = ({ code }: ProductNotFoundProps) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <NotFoundIcon />
        <TitleTypography variant="h1">Product not found</TitleTypography>
      </ImageWrapper>

      <DescriptionWrapper>
        <SubTitleTypography variant="h6">
          Didn't find the exact compound you were searching for?
        </SubTitleTypography>
        <Typography variant="body1">
          Try searching below, request analogs, or{" "}
          <Link
            href={common.CONTACTS_HREF}
            target="_blank"
            rel="noopener noreferrer"
            color="#0076BE"
          >
            contact our team
          </Link>{" "}
          for assistance.
        </Typography>
      </DescriptionWrapper>

      <ButtonsWrapper>
        <Button variant="contained" startIcon={<SearchIcon />} href="/">
          Search
        </Button>
        <RequestAnalogs code={code} />
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default ProductNotFound;
