const selectItems = (state, key) => {
  return state.catalog.items?.[key] || [];
};

const selectCatalogItemsWithPrices = (state, key) => {
  return state.catalog.items?.[key];
};

const selectCatalogItemsAll = (state) => {
  return state.catalog.allItems;
};

const selectViewMode = (state) => {
  return state.catalog.viewMode;
};

const selectLoadingItems = (state) => {
  return state.catalog.loadingItems;
};

const selectPageSize = (state) => {
  return state.catalog.pageSize;
};

const selectCatalogFilters = (state) => {
  return state.catalog.catalogFilters;
};

export default {
  selectItems,
  selectLoadingItems,
  selectPageSize,
  selectViewMode,
  selectCatalogItemsWithPrices,
  selectCatalogItemsAll,
  selectCatalogFilters,
};
