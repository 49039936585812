import { ProductTypesV2 } from "constants/productTypes";

// @TODO Check this type across the project and delete if needed
export type ProductDetails = {
  catalog: ProductTypesV2;
  code: string;
  purity: number;
  storageConditions: string;
  transportConditions: string;
  name: string;
  smile: string;
  formula: string;
  molecularWeight: number;
  solubilityLogarithm: number;
  partitioningOctanolWaterLogarithm: number;
  rotatableBonds: number;
  polarSurfaceArea: number;
  hydrogenAcceptors: number;
  hydrogenDonors: number;
  transportationByAir: string;
  clogp: number;
  CAS: string;
  MFCD: string;
};

export enum ProductWeightStandard {
  WEIGHT_IS_NON_STANDARD = "WEIGHT_IS_NON_STANDARD",
  WEIGHT_IS_ENAMINE_STANDARD = "WEIGHT_IS_ENAMINE_STANDARD",
}
export type ProductWeight = {
  measure: string;
  amount: number;
  type: ProductWeightStandard;
};
