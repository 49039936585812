import styled from "@emotion/styled";
import { Link } from "ui";
import { accentColor } from "ui/colors";

export const Code = styled(Link)`
  box-sizing: border-box;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none !important;
`;

export const PurityWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${accentColor};
`;
