import React from "react";
import { Typography } from "@mui/material";
import { TitleTypography, Wrapper } from "./RequestAnalogsCatalog.styled";
import RequestAnalogs from "../../../../shared/ui/RequestAnalogsDialog/RequestAnalogs";

const RequestAnalogsCatalog = () => {
  return (
    <>
      <Wrapper>
        <TitleTypography variant="h5">
          Didn't find the exact compound you were searching for?
        </TitleTypography>

        <Typography variant="body1">
          Send us a request for analogs, and our team will help find similar
          options that match your needs.
        </Typography>

        <RequestAnalogs />
      </Wrapper>
    </>
  );
};

export default RequestAnalogsCatalog;
