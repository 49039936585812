import styled from "styled-components";
import { accentColor, darkBlue } from "ui/colors";

export const Wrapper = styled.div`
  min-width: 416px;
  position: relative;
`;
Wrapper.displayName = "SummaryWrapper";

export const InfoWrapper = styled.div`
  margin: 0;
  padding: 24px 0 32px;
  & > * {
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
Wrapper.displayName = "InfoWrapper";

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 18.75px;
`;

export const InfoCell = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: ${darkBlue};
    }

    &:hover {
      path {
        fill: ${accentColor};
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(256, 256, 256, 0.7);
  border-radius: 10px;
  z-index: 10;
`;

export const ButtonWrapper = styled.div`
  padding-bottom: 18px;
`;

export const InfoIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  top: -1px;

  svg {
    path {
      fill: ${darkBlue};
    }

    &:hover {
      path {
        fill: ${accentColor};
      }
    }
  }
`;
