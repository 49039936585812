import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { blue, borderColor } from "ui/colors";
import { NotFoundIcon } from "ui/icons";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
`;

export const NotFoundImage = styled(NotFoundIcon)`
  width: 180px;
  height: 180px;
  margin-bottom: 16px;
`;

export const Title = styled(Typography)`
  margin-bottom: 60px;
  color: ${borderColor};
  font-weight: 700;
`;

export const SubTitleTypography = styled(Typography)`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: ${blue};
`;

export const DescriptionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  margin-bottom: 36px;
`;
