export const BREADCRUMBS_ITEMS = [
  {
    title: "Contact Us",
    href: "",
  },
];

export const SUPPORT_OPTIONS = [
  {
    title: "Your account",
    description:
      "Issues and questions regarding your account, password reset etc.",
    link: "https://enamine.atlassian.net/servicedesk/customer/portal/23/group/81/create/334",
  },
  {
    title: "Payment",
    description:
      "Questions about the payment process and possible issues while checking out.",
    link: "https://enamine.atlassian.net/servicedesk/customer/portal/23/group/81/create/333",
  },
  {
    title: "Compound quality issues",
    description: "Details if you have an issue with the delivered compounds.",
    link: "https://enamine.atlassian.net/servicedesk/customer/portal/23/group/81/create/332",
  },
  {
    title: "Delivery",
    description: "Questions about the delivery and tracking.",
    link: "https://enamine.atlassian.net/servicedesk/customer/portal/23/group/81/create/331",
  },
  {
    title: "Order",
    description: "Issues with placing an order or checking out.",
    link: "https://enamine.atlassian.net/servicedesk/customer/portal/23/group/81/create/330",
  },
  {
    title: "Availability of compounds",
    description:
      "Compound is not available or wasn’t found at Enaminestore.com",
    link: "https://enamine.atlassian.net/servicedesk/customer/portal/23/group/81/create/467",
  },
  {
    title: "Integrations",
    description:
      "Questions access to API, Livelink integration or Punchout capabilities.",
    link: "https://enamine.atlassian.net/servicedesk/customer/portal/23/group/81/create/528",
  },
  {
    title: "Other questions",
    description:
      "Don’t see what you’re looking for? Select this option and we’ll help you out.",
    link: "https://enamine.atlassian.net/servicedesk/customer/portal/23/group/81/create/321",
  },
];

export const OFFICE_LOCATIONS = [
  {
    region: "USA",
    name: "Enamine",
    phone: "(732) 274 9150",
    tollFree: "+1 888 745 6132",
    email: "sales_usa@enamine.net",
  },
  {
    region: "EUROPE",
    name: "SIA Enamine",
    phone: "+380 44 333 5606",
    email: "sales_europe@enamine.net",
  },
  {
    region: "ASIA PACIFIC",
    name: "Enamine LTD",
    phone: "+380 44 333 5601",
    email: "info@enamine.net",
  },
];

export const DISTRIBUTORS = [
  {
    name: "ENAMINE Germany GmbH",
    address: "Industriepark Hoechst, G837 65926 Frankfurt am Main Germany",
    companyNumber: "HRB 130608",
    vatNumber: "DE360933809",
    website: "enamine.de",
  },
  {
    name: "SIA Enamine",
    address: "Vestienas iela 2 B LV - 1035 Riga Latvia",
    vatNumber: "LV40103730175",
  },
  {
    name: "Enamine US Inc",
    address: "1 Distribution WayMonmouth Jct., NJ 08852 USA",
  },
  {
    name: "Enamine LTD",
    address: "78 Winston Churchill Street 02094 Kyiv Ukraine",
  },
];
