import React, { ReactNode } from "react";
import Info from "./ProductDetailsList.styled";
import {
  convertItemValue,
  prepareListData,
  splitToColumns,
} from "../ProductDetails.utils";
import { DETAILS_LIST_CONFIG } from "./ProductDetailsConfig";
import { ProductDTO } from "typings/DTO/data-contracts";

type ProductInfoCellProps = {
  label: string;
  value: string;
  children: ReactNode;
};

const ProductInfoCell = ({ label, value, children }: ProductInfoCellProps) => (
  <Info.Cell>
    <Info.Label title={label}>{label}</Info.Label>
    <Info.Value title={value}>{children}</Info.Value>
  </Info.Cell>
);

type ProductDetailsListProps = {
  product: ProductDTO;
};

// @TODO We have to do refactoring here, and change structure from Info.List & Info.Row to
// @TODO MUIGrid that will provide us control of fullWidth items and items that have to be 50% of container (md={6})
export const ProductDetailsList = ({ product }: ProductDetailsListProps) => {
  const detailsList = prepareListData(product, DETAILS_LIST_CONFIG);
  const splitted = splitToColumns(detailsList);

  return (
    <Info.List className="details">
      {product?.name && (
        <Info.Row key="name">
          <ProductInfoCell label="Name" value={product.name}>
            {convertItemValue("name", product.name)}
          </ProductInfoCell>
        </Info.Row>
      )}
      {product?.smile && (
        <Info.Row key="SMILE">
          <ProductInfoCell label="SMILE" value={product.smile}>
            {convertItemValue("name", product.smile)}
          </ProductInfoCell>
        </Info.Row>
      )}
      {splitted.map((detailsTuple, idx) => (
        <Info.Row key={idx}>
          {detailsTuple.map(
            (detailItem) =>
              detailItem && (
                <ProductInfoCell
                  key={detailItem?.key}
                  label={detailItem?.title}
                  value={detailItem?.rawValue}
                >
                  {detailItem?.value}
                </ProductInfoCell>
              )
          )}
        </Info.Row>
      ))}
    </Info.List>
  );
};
