import { GroupUpdateFormValues } from "features/super-cart/model/SuperCart.types";
import { FormikProps } from "formik";
import React from "react";
import { Select } from "../../../../ui";

type SelectWeightProps = {
  formik: FormikProps<GroupUpdateFormValues>;
  options: { value: string; title: string }[];
  catalog: string;
};

const SelectWeight = ({
  formik,
  options,
  catalog,
  ...rest
}: SelectWeightProps) => {
  const onHandleChange = (event: any) => {
    const selectedValue = JSON.parse(event.target.value);
    formik.setFieldValue(`${catalog}.currentWeight`, selectedValue);
  };
  return (
    <Select
      formik={formik}
      label="Item Amount"
      name={`${catalog}.weight.amount`}
      options={options}
      onChange={onHandleChange}
      {...rest}
    />
  );
};

export default SelectWeight;
