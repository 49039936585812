import { ProductTypesV2 } from "../../../constants/productTypes";

export const CatalogConfig = {
  [ProductTypesV2.BB]: {
    catalog: { title: "Product class", order: 1 },
    CAS: { title: "CAS number", order: 2 },
    molecularWeight: { title: "MW", order: 3 },
    purity: { title: "Purity", order: 4 },
    formula: { title: "Formula", order: 5 },
    transportConditions: { title: "Transport conditions", order: 6 },
    storageConditions: { title: "Storage conditions", order: 7 },
  },
  [ProductTypesV2.SCR]: {
    catalog: { title: "Product class", order: 1 },
    // @TODO https://enamine.atlassian.net/browse/SHOP-6050 temporary hidden
    // collection: { title: "Screening Collection", order: 2 },
    molecularWeight: { title: "MW", order: 3 },
    purity: { title: "Purity", order: 4 },
    formula: { title: "Formula", order: 5 },
    transportConditions: { title: "Transport conditions", order: 6 },
    storageConditions: { title: "Storage conditions", order: 7 },
  },
  [ProductTypesV2.EBC]: {
    catalog: { title: "Product class", order: 1 },
    name: { title: "Name", order: 2 },
    CAS: { title: "CAS number", order: 3 },
    purity: { title: "Purity", order: 4 },
    formula: { title: "Formula", order: 5 },
    transportConditions: { title: "Transport conditions", order: 6 },
    storageConditions: { title: "Storage conditions", order: 7 },
  },
  [ProductTypesV2.MADE]: {
    catalog: { title: "Product class", order: 1 },
    name: { title: "Name", order: 2 },
    CAS: { title: "CAS number", order: 3 },
    purity: { title: "Purity", order: 4 },
    formula: { title: "Formula", order: 5 },
    transportConditions: { title: "Transport conditions", order: 6 },
    storageConditions: { title: "Storage conditions", order: 7 },
  },
  [ProductTypesV2.REALDB]: {
    catalog: { title: "Product class", order: 1 },
    clogp: { title: "CLogP", order: 2 },
    molecularWeight: { title: "MW", order: 3 },
    purity: { title: "Purity", order: 4 },
    tpsa: { title: "TPSA", order: 5 },
    transportConditions: { title: "Transport conditions", order: 6 },
    storageConditions: { title: "Storage conditions", order: 7 },
  },
};
