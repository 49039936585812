import React from "react";
import {
  DescriptionWrapper,
  NotFoundImage,
  SubTitleTypography,
  Title,
  Wrapper,
} from "./NotFound.styled";
import { Typography } from "@mui/material";
import RequestAnalogs from "../../../../shared/ui/RequestAnalogsDialog/RequestAnalogs";

type NotFoundProps = {
  message?: string;
};

const NotFound = ({ message = "Products not found" }: NotFoundProps) => {
  return (
    <>
      <Wrapper>
        <NotFoundImage />
        <Title variant="h3">{message}</Title>

        <DescriptionWrapper>
          <SubTitleTypography varniant="h6">
            Didn't find the exact compound you were searching for?
          </SubTitleTypography>
          <Typography variant="body1">
            Send us a request for analogs, and our team will help find similar
            options that match your needs.
          </Typography>
        </DescriptionWrapper>
        <RequestAnalogs />
      </Wrapper>
    </>
  );
};

export default NotFound;
