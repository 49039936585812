import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { Input, Wrapper } from "./DebouncedInput.styled";
import { Label } from "../../../ui/select/select.styles";

interface DebouncedInputProps {
  onHandleChange?: (value: number) => void;
  count: number;
  hideButtons?: boolean;
  disabled?: boolean;
  min?: number;
  label?: string;
  allowDecimal?: boolean;
  delay?: number;
}

const DebouncedInput = ({
  onHandleChange = () => {},
  count,
  hideButtons,
  disabled,
  min,
  label,
  allowDecimal,
  delay = 500,
  ...rest
}: DebouncedInputProps) => {
  const [inputValue, setInputValue] = useState(count);
  const [debouncedValue] = useDebounce(inputValue, delay);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    if (allowDecimal) {
      value = value.replace(/^0+(?=\d)/, "");
      const numericValue = parseFloat(value);
      const validValue =
        value.match(/^\d*\.?\d{0,2}$/) &&
        (!numericValue || numericValue <= 9999999999)
          ? value
          : inputValue;
      setInputValue(validValue);
    } else {
      if (value === "" || /^\d+$/.test(value)) {
        setInputValue(value === "" ? 1 : parseInt(value));
      }
    }
  };

  useEffect(() => {
    setInputValue(count);
  }, [count]);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();

    let numericValue = parseFloat(value.replace(/^0+(?=\d)/, ""));

    if (!numericValue || numericValue === 0) {
      numericValue = 0.01;
    }

    setInputValue(numericValue);

    if (inputValue !== numericValue) {
      onHandleChange(numericValue);
    }
  };

  useEffect(() => {
    if (allowDecimal) {
      const numericValue = parseFloat(debouncedValue);
      if (
        !isNaN(numericValue) &&
        numericValue !== count &&
        numericValue >= 0.01
      ) {
        onHandleChange(numericValue);
      }
    } else if (inputValue !== count) {
      onHandleChange(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Wrapper>
      {!hideButtons && (
        <button
          onClick={() => setInputValue((prev) => --prev)}
          disabled={inputValue <= 1}
        >
          -
        </button>
      )}

      {label && <Label>{label}</Label>}

      <Input
        type="number"
        min={min || 1}
        value={inputValue}
        onChange={handleInputChange}
        disabled={disabled}
        onBlur={handleBlur}
        {...rest}
      />

      {!hideButtons && (
        <button onClick={() => setInputValue((prev) => ++prev)}>+</button>
      )}
    </Wrapper>
  );
};

export default DebouncedInput;
