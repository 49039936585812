import React from "react";
import { FormControl } from "@mui/material";
import {
  BaseNativeSelectContainer,
  CustomWeightWrapper,
  InputLabelTypography,
  InputWrapper,
  StyledTextField,
} from "./CustomWeight.styled";
import { customWeightOptions } from "shared/constants/Pricing.constants";
import BaseNativeSelect from "../BaseNativeSelect/BaseNativeSelect";
import { FormikProps } from "formik";
import { ProductWeight } from "shared/types/product";
import { NormalizedCatalogName } from "constants/productTypes";

type CustomWeightProps = {
  formik: FormikProps<{
    counter: number;
    currentWeight: ProductWeight;
    customWeight: Omit<ProductWeight, "type">;
  }>;
  isOneProductCard?: boolean;
  catalog?: NormalizedCatalogName;
};

const CustomWeight = ({
  formik,
  isOneProductCard,
  catalog,
}: CustomWeightProps) => {
  const customWeightFormPath = catalog
    ? `${catalog}.customWeight`
    : "customWeight";

  const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replace(/^0+(?=\d)/, "");
    const numericValue = parseFloat(value);
    const validValue =
      value?.match(/^\d*\.?\d{0,2}$/) &&
      (!numericValue || numericValue <= 9999999999)
        ? value
        : formik.values[customWeightFormPath]?.amount;
    formik.setFieldValue(`${customWeightFormPath}.amount`, validValue);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let numericValue = parseFloat(
      e.target.value.trim().replace(/^0+(?=\d)/, "")
    );
    if (!numericValue || numericValue === 0) {
      numericValue = 0.01;
    }
    formik.setFieldValue(`${customWeightFormPath}.amount`, numericValue);
  };

  return (
    <CustomWeightWrapper isOneProductCard={isOneProductCard}>
      <InputWrapper>
        {catalog && (
          <InputLabelTypography variant="body1">Weight</InputLabelTypography>
        )}
        <StyledTextField
          isOneProductCard={isOneProductCard}
          fullWidth
          name="amount"
          placeholder="Enter weight"
          value={formik.values[customWeightFormPath]?.amount}
          onChange={onHandleChange}
          onBlur={handleOnBlur}
          type="number"
          inputProps={{ min: 0.01, step: 0.01 }}
        />
      </InputWrapper>
      <InputWrapper>
        {catalog && (
          <InputLabelTypography variant="body1">Unit</InputLabelTypography>
        )}
        <FormControl
          error={
            formik.touched[customWeightFormPath]?.measure &&
            Boolean(formik.errors[customWeightFormPath]?.measure)
          }
          sx={{ width: "100%" }}
        >
          <BaseNativeSelectContainer isOneProductCard={isOneProductCard}>
            <BaseNativeSelect
              fieldName={`${customWeightFormPath}.measure`}
              options={customWeightOptions}
              formik={formik}
            />
          </BaseNativeSelectContainer>
        </FormControl>
      </InputWrapper>
    </CustomWeightWrapper>
  );
};

export default CustomWeight;
