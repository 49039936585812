import { SearchAsTypesV2 } from "../../constants/searchAsTypes";
import { ProductTypesV2 } from "../../constants/productTypes";

export const generateInfoForRequestAnalogs = ({
  cat,
  searchBy,
  sstype,
  isDrawSearch,
}: {
  cat?: string;
  searchBy?: string;
  sstype?: string;
  isDrawSearch?: string;
}) => {
  const searchByTransform = {
    codes: "BY_CODES",
    CASs: "BY_CASES",
    MFCDs: "BY_MFCDS",
    SMILEs: "BY_SMILES",
    text: "BY_TEXT",
  };

  let searchType = searchByTransform.codes;

  if (isDrawSearch && sstype) {
    searchType = `BY_SMILES_${SearchAsTypesV2[sstype]}`;
  }
  if (searchBy) {
    searchType = searchByTransform[searchBy];
  }

  return {
    ...(cat && { catalog: ProductTypesV2[cat] }),
    searchType,
  };
};
