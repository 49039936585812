// @TODO This is for demo use only, DO NOT USE THIS CODE ANYWHERE! And remove it after DEMO!
export const DEMO_PRODUCTS_FOR_IMAGE_REPLACE_DELETE_AFTER_DEMO = [
  "EN300-52726384",
  "EN300-52726387",
  "EN300-52726374",
  "EN300-52726371",
  "EN300-52726388",
  "EN300-52726380",
  // @TODO Not image
  // "EN300-52518443",
  "EN300-52726381",
  "EN300-52705798",
  "EN300-52726372",
  "EN300-52726373",
  // @TODO Not image
  // "EN300-20637714",
];

export const isItemShouldHaveMockImage = (code: string) => {
  return DEMO_PRODUCTS_FOR_IMAGE_REPLACE_DELETE_AFTER_DEMO.includes(code);
};
