import React from "react";
import { ProductWithPricesDTO } from "../../../../../../typings/DTO/data-contracts";
import {
  ActionsWrapper,
  AddToCartButton,
  PriceSelectWrapper,
  Wrapper,
} from "./SimpleCard.styled";
import PriceSelect from "../../../../../../parts/price-select/PriceSelect";
import { Currency } from "../../../../../../shared/types/pricing";
import { CartIcon } from "../../../../../../ui/icons";
import { FormikProps } from "formik";
import { FormikValues } from "formik/dist/types";
import { Code, PurityWrapper } from "../ViewModes.styled";
import DebouncedInput from "../../../../../../features/super-cart/DebouncedInput/DebouncedInput";
import { ProductPurity } from "../../ProductPurity/ProductPurity";

type SimpleCardProps = {
  catalogItem: ProductWithPricesDTO;
  // @TODO Do we really need this href?
  href: string;
  handleClick: VoidFunction;
  currency: Currency;
  isAddingToCart: boolean;
  formik: FormikProps<FormikValues>;
};

const SimpleCard = ({
  catalogItem,
  href,
  handleClick,
  currency,
  isAddingToCart,
  formik,
}: SimpleCardProps) => {
  const handleItemCount = (count: number) => {
    formik.setFieldValue("counter", count);
  };

  return (
    <Wrapper>
      <PurityWrapper>
        <Code to={href} onClick={handleClick}>
          {catalogItem.product.code}
        </Code>
        <ProductPurity purityValue={catalogItem?.product?.purity} />
      </PurityWrapper>
      <ActionsWrapper>
        <PriceSelectWrapper>
          <PriceSelect
            prices={catalogItem?.prices}
            currency={currency}
            label={false}
            fullWidth
            formik={formik}
            isCustomWeightEnabled={false}
          />
        </PriceSelectWrapper>

        <DebouncedInput
          onHandleChange={handleItemCount}
          count={formik.values.counter}
          delay={0}
          hideButtons
        />

        <AddToCartButton
          iconLeft={<CartIcon />}
          fullWidth
          size="small"
          onClick={formik.handleSubmit}
          isLoading={isAddingToCart}
          data-test="add_to_cart_button"
        />
      </ActionsWrapper>
    </Wrapper>
  );
};

export default SimpleCard;
