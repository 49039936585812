import * as Yup from "yup";
import { FilterTypes } from "../../../model/Catalog.types";
import { productTypes } from "../../../../../constants";

export const catalogFiltersAvailableConfig = {
  [productTypes.BB]: {
    [FilterTypes.LOCATION]: "All",
    [FilterTypes.classes]: [],
    [FilterTypes.subclasses]: [],
    [FilterTypes.MW]: {
      from: "",
      to: "",
    },
    [FilterTypes.CLogP]: {
      from: "",
      to: "",
    },
  },
  [productTypes.SCR]: {
    [FilterTypes.collections]: [],
    [FilterTypes.MW]: {
      from: "",
      to: "",
    },
    [FilterTypes.CLogP]: {
      from: "",
      to: "",
    },
    [FilterTypes.RotB]: {
      from: "",
      to: "",
    },
    [FilterTypes.HAC]: {
      from: "",
      to: "",
    },
  },
  [productTypes.EBC]: {
    [FilterTypes.MW]: {
      from: "",
      to: "",
    },
    [FilterTypes.CLogP]: {
      from: "",
      to: "",
    },
  },
  [productTypes.MADE]: {
    [FilterTypes.MW]: {
      from: "",
      to: "",
    },
    [FilterTypes.CLogP]: {
      from: "",
      to: "",
    },
  },
  [productTypes.REALDB]: {
    [FilterTypes.MW]: {
      from: "",
      to: "",
    },
    [FilterTypes.CLogP]: {
      from: "",
      to: "",
    },
    [FilterTypes.HBA]: {
      from: "",
      to: "",
    },
    [FilterTypes.HBD]: {
      from: "",
      to: "",
    },
    [FilterTypes.RotB]: {
      from: "",
      to: "",
    },
    [FilterTypes.TPSA]: {
      from: "",
      to: "",
    },
    [FilterTypes.HAC]: {
      from: "",
      to: "",
    },
    [FilterTypes.Fsp3]: {
      from: "",
      to: "",
    },
  },
  DEFAULT: {
    [FilterTypes.LOCATION]: "All",
    [FilterTypes.classes]: [],
    [FilterTypes.subclasses]: [],
    [FilterTypes.collections]: [],
    [FilterTypes.MW]: {
      from: "",
      to: "",
    },
    [FilterTypes.CLogP]: {
      from: "",
      to: "",
    },
    [FilterTypes.HBA]: {
      from: "",
      to: "",
    },
    [FilterTypes.HBD]: {
      from: "",
      to: "",
    },
    [FilterTypes.RotB]: {
      from: "",
      to: "",
    },
    [FilterTypes.TPSA]: {
      from: "",
      to: "",
    },
    [FilterTypes.HAC]: {
      from: "",
      to: "",
    },
    [FilterTypes.Fsp3]: {
      from: "",
      to: "",
    },
  },
};

const patternTwoDigitsAfterComma = /^\d+(\.\d{0,2})?$/;

export const catalogFiltersRegisterSchema = Yup.object().shape({
  [FilterTypes.MW]: Yup.object().shape({
    from: Yup.number()
      .min(0, "MW can only be set in the range from 0.00")
      .test(
        "is-decimal",
        "The amount should be a decimal with maximum two digits after comma",
        (val: any) => {
          if (val !== undefined) {
            return patternTwoDigitsAfterComma.test(val);
          }
          return true;
        }
      ),
    to: Yup.number()
      .min(0, "MW can only be set in the range from 0.00")
      .test(
        "is-decimal",
        "The amount should be a decimal with maximum two digits after comma",
        (val: any) => {
          if (val !== undefined) {
            return patternTwoDigitsAfterComma.test(val);
          }
          return true;
        }
      )
      .when("from", (from, schema) => {
        return schema.test({
          name: "is-greater",
          exclusive: false,
          message: "From value should be lower than To value",
          test: function (to) {
            return to === undefined || from === undefined || to > from;
          },
        });
      }),
  }),
  [FilterTypes.CLogP]: Yup.object().shape({
    from: Yup.number()
      .min(
        -100,
        "ClogP can only be set in the range from -100.0000 to 100.0000"
      )
      .max(
        100,
        "ClogP can only be set in the range from -100.0000 to 100.0000"
      ),
    to: Yup.number()
      .min(
        -100,
        "ClogP can only be set in the range from -100.0000 to 100.0000"
      )
      .max(
        100,
        "ClogP can only be set in the range from -100.0000 to 100.0000"
      ),
  }),
  [FilterTypes.RotB]: Yup.object().shape({
    from: Yup.number().positive(),
    to: Yup.number().positive(),
  }),
  [FilterTypes.HAC]: Yup.object().shape({
    from: Yup.number().positive(),
    to: Yup.number().positive(),
  }),
  [FilterTypes.HBA]: Yup.object().shape({
    from: Yup.number().positive(),
    to: Yup.number().positive(),
  }),
  [FilterTypes.HBD]: Yup.object().shape({
    from: Yup.number().positive(),
    to: Yup.number().positive(),
  }),
  [FilterTypes.TPSA]: Yup.object().shape({
    from: Yup.number().positive(),
    to: Yup.number().positive(),
  }),
  [FilterTypes.similarity]: Yup.number().min(0).max(1),
});
