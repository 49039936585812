import { Button, Flex, Input, Paper, Textarea } from "ui";
import {
  ButtonWrapper,
  ErrorMessage,
  InputWrapper,
  TitleTypography,
} from "./Billing.styles";
import FailedInvoicesPopup from "./parts/failed-invoices-popup/FailedInvoicesPopup";

const Billing = ({ formik, error, handleSumChange, failedInvoicesPopup }) => {
  return (
    <>
      <FailedInvoicesPopup {...failedInvoicesPopup} />
      <Paper>
        <Flex type="center-between" marginBottom>
          <TitleTypography variant="h3">Billing</TitleTypography>
        </Flex>
        <form data-test="billing-submit-form" onSubmit={formik?.handleSubmit}>
          <InputWrapper>
            <Textarea
              label="Invoice Numbers"
              name="names"
              formik={formik}
              data-test="invoice-numbers-input"
              placeholder="Enter invoice numbers"
              style={{ minHeight: "71px" }}
            />
          </InputWrapper>

          <InputWrapper>
            <Input
              label="Total sum by invoices"
              name="sum"
              formik={formik}
              onChange={handleSumChange}
              data-test="total-sum-input"
              placeholder="Enter total sum"
            />
          </InputWrapper>
          <ButtonWrapper>
            <Button
              type="success"
              fullWidth
              disabled={formik?.isSubmitting}
              dataTestAttr="button-pay"
            >
              Pay Now
            </Button>
          </ButtonWrapper>
        </form>
        <ErrorMessage>{error}</ErrorMessage>
      </Paper>
    </>
  );
};

export default Billing;
