import { select, put } from "redux-saga/effects";
import { productActions } from "./index";
import { fetchProduct } from "./api";
import { isItemShouldHaveMockImage } from "../../shared/lib/REMOVE.utils";

function* applyProductImages(code: string, entityWithProducts, action) {
  const productsWithImages = entityWithProducts.map((productWithPrices) => ({
    ...productWithPrices,
    // @TODO REMOVE THIS LOGIC AFTER DEMO!
    image: isItemShouldHaveMockImage(productWithPrices.product.code)
      ? `/images/demoReplaceImages/${productWithPrices.product.code}.png`
      : `/api/v2/catalog/all/images/by-code/${productWithPrices.product.code}`,
  }));

  yield put(
    action({
      items: productsWithImages,
      code,
    })
  );
}

export function* requestProductWithPrices({ payload }) {
  const state = yield select();
  const currencyAbbr = state.currency.current.abbr;

  try {
    const { code, cat, includeAdditionalResults } = payload;
    const response = yield fetchProduct({
      code,
      cat,
      currencyAbbr,
      includeAdditionalResults,
    });

    if (response[0]) {
      const productWithImage = {
        ...response[0],
        // @TODO REMOVE THIS LOGIC AFTER DEMO!
        image: isItemShouldHaveMockImage(response[0].product.code)
          ? `/images/demoReplaceImages/${response[0].product.code}.png`
          : `/api/v2/catalog/all/images/by-code/${response[0].product.code}`,
      };

      const { equivalents, analogs, forms } = response[0].included;

      if (equivalents?.length) {
        yield applyProductImages(
          code,
          equivalents,
          productActions.setInStockEquivalents
        );
      }

      if (analogs?.length) {
        yield applyProductImages(
          code,
          analogs,
          productActions.setProductAnalogs
        );
      }

      if (forms?.length) {
        yield applyProductImages(
          code,
          forms,
          productActions.setProductSaltForms
        );
      }

      yield put(productActions.setProductWithPrices(productWithImage));
    }

    if (typeof payload?.onSuccess === "function") {
      yield payload?.onSuccess();
    }
  } catch (e) {
    console.log("Something went wrong!", e);
  }
}
