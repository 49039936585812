import {
  FormWrapper,
  InputStyled,
  TextareaStyled,
} from "../RequestAnalogsDialog/RequestAnalogsDialog.styled";
import React from "react";

export const AnonymousForm = ({ formik }) => (
  <FormWrapper>
    <InputStyled label="Full name" name="name" formik={formik} />
    <InputStyled label="Company" name="company" formik={formik} />
    <InputStyled label="Email" name="email" formik={formik} />
    <TextareaStyled
      label="Comment"
      name="text"
      formik={formik}
      dataTestAttr="request-comment"
    />
  </FormWrapper>
);
