import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import React from "react";
import {
  LoaderWrapper,
  ProgressBarContainer,
  ProgressValue,
  ProgressWrapper,
  TitleTypography,
} from "./LinearProgressBar.styled";

interface LinearProgressBarProps extends LinearProgressProps {
  progressValue: number;
  progressStatus?: string;
}

const LinearProgressBar = ({
  progressValue,
  progressStatus,
  ...rest
}: LinearProgressBarProps) => {
  return (
    <LoaderWrapper>
      <ProgressBarContainer>
        <ProgressWrapper>
          <LinearProgress
            variant="determinate"
            {...rest}
            value={progressValue}
          />
        </ProgressWrapper>
        <ProgressValue variant="body2">{`${Math.round(
          progressValue
        )}%`}</ProgressValue>
      </ProgressBarContainer>
      {progressStatus && (
        <TitleTypography variant="subtitle1">{progressStatus}</TitleTypography>
      )}
    </LoaderWrapper>
  );
};

export default LinearProgressBar;
