import { Box } from "@mui/material";
import { lineColor } from "../../../../ui/colors";
import styled from "styled-components";
import { Link } from "../../../../ui";

export const ReplacementItemWrapper = styled(Box)`
  display: flex;
  border: 1px solid ${lineColor};
  border-bottom: none;
  padding: 12px 28px;
  gap: 26px;
  &:last-child {
    border-bottom: 1px solid ${lineColor};
  }
`;

export const LinkCode = styled(Link)`
  font-weight: 600;
  font-size: 20px;
  margin: 0 0 18px 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none !important;
  word-break: break-all;
`;

export const InfoListWrapper = styled(Box)`
  margin-bottom: 18px;
`;
