import React, { useState } from "react";
import Analogs from "./analogs";
import { RequestAnalogsDialog } from "../../shared/ui/RequestAnalogsDialog/RequestAnalogsDialog/RequestAnalogsDialog";

const AnalogsContainer = ({ analogs, code, cat, currency }) => {
  const [isModalOpened, openModal] = useState(false);

  return (
    <>
      <Analogs
        // loading={analogs.loading}
        items={analogs.items}
        // expanded={analogs.data.expanded}
        // pagination={analogs.data.pagination}
        // handleClickLoadMore={analogs.handle.loadMoreClick}
        // handleClickPagination={analogs.handle.paginationClick}
        handleCustomerRequest={() => openModal(true)}
      />
      <RequestAnalogsDialog
        open={isModalOpened}
        setOpen={openModal}
        code={code}
      />
    </>
  );
};

export default AnalogsContainer;
