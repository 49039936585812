import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { whiteColor } from "ui/colors";

export const CategoriesContainer = styled.div`
  max-width: 952px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 60px;
`;

export const StyledButton = styled(Button)`
  margin-top: 28px;
  &:hover {
    svg path {
      stroke: ${whiteColor};
    }
  }
`;
