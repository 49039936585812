import React from "react";
import { TextField } from "@mui/material";
// @TODO Check logic of Autocomplete and use default MUI autocomplete if possible
import AutocompleteMultiselect from "ui/AutocompleteMultiselect/AutocompleteMultiselect";
import { FormikProps } from "formik";
import { FormikValues } from "formik/dist/types";
import { getFilterSubClasses } from "../../../../../api/Catalog.api";

type FilterClassesProps = {
  formik: FormikProps<FormikValues>;
  fieldName: string;
};

const FilterSubClasses = ({ formik, fieldName }: FilterClassesProps) => {
  const { data: filterSubClasses } = getFilterSubClasses();

  return (
    <>
      {filterSubClasses && (
        <AutocompleteMultiselect
          options={filterSubClasses[0]}
          value={formik?.values[fieldName]}
          onChange={(_, value) => {
            formik?.setFieldValue(fieldName, value);
          }}
          getOptionLabel={(option: string) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Start typing sub classes"
            />
          )}
        />
      )}
    </>
  );
};

export default FilterSubClasses;
