import { rootPages } from "constants";
import React, { useEffect, useState } from "react";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from "react-joyride";
import { useHistory, useLocation } from "react-router-dom";
import {
  PROFILE_ONBOARDING_STEPS,
  SPOTLIGHT_PADDING,
} from "../../model/Profile.contants";
import { OnboardingTooltip } from "./OnboardingTooltip/OnboardingTooltip";
import { useAmplitudeState } from "../../../../context/AmplitudeContext";
import { AmplitudeEvents } from "../../../../context/AmplitudeEvents";

const stepNavigation = [
  `/orders`,
  `/orders`,
  `/`,
  `/address-book`,
  `/contact-persons`,
  `/tax-free-certificates`,
  `/billing`,
  `/certificates-coa`,
];

export const OnboardingTour = () => {
  const { amplitude } = useAmplitudeState();
  const history = useHistory();
  const location = useLocation();
  const [startTour, setStartTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  useEffect(() => {
    const onboardingStatus = localStorage.getItem("onboardingStatus");
    const isOrdersPage = location.pathname === `/${rootPages.PROFILE}/orders`;
    if (!onboardingStatus && isOrdersPage && stepIndex === 0) {
      setStartTour(true);
      amplitude.logEvent(AmplitudeEvents.Onboarding.START);
    }
  }, [amplitude, location.pathname, stepIndex]);
  const navigateToStep = (index: number) => {
    const path = stepNavigation[index];
    if (path) {
      history.push(`/${rootPages.PROFILE}${path}`);
    }
  };
  const handleJoyrideCallback = ({
    action,
    index,
    status,
    type,
  }: CallBackProps) => {
    if (
      [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type) &&
      [ACTIONS.NEXT, ACTIONS.PREV].includes(action)
    ) {
      const newIndex = action === ACTIONS.NEXT ? index + 1 : index - 1;
      navigateToStep(newIndex);
      setStepIndex(newIndex);
    }

    if (status === STATUS.SKIPPED || status === STATUS.FINISHED) {
      amplitude.logEvent(
        status === STATUS.SKIPPED
          ? AmplitudeEvents.Onboarding.SKIP
          : AmplitudeEvents.Onboarding.FINISH
      );
    }

    if ([STATUS.FINISHED, STATUS.SKIPPED, ACTIONS.CLOSE].includes(status)) {
      setStartTour(false);
      localStorage.setItem("onboardingStatus", "completed");
    }
  };

  return (
    <Joyride
      steps={PROFILE_ONBOARDING_STEPS}
      run={startTour}
      stepIndex={stepIndex}
      callback={handleJoyrideCallback}
      tooltipComponent={OnboardingTooltip}
      spotlightPadding={SPOTLIGHT_PADDING}
      disableOverlayClose
      continuous
      scrollOffset={100}
      floaterProps={{
        hideArrow: true,
      }}
      styles={{
        options: {
          overlayColor: "rgba(0, 0, 0, 0.3)",
          width: 360,
          zIndex: 1000,
        },
        tooltip: {
          padding: 18,
        },
      }}
    />
  );
};
