import { Box } from "@mui/material";
import styled from "styled-components";
import { Button } from "../../../../../../ui";
import { lineColor } from "../../../../../../ui/colors";

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  gap: 12px;
  width: 100%;
  padding: 16px 24px;
  border-bottom: 1px solid ${lineColor};
  &:last-child {
    border-bottom: 0;
  }
`;

export const ActionsWrapper = styled(Box)`
  display: flex;
  gap: 10px;
`;

export const AddToCartButton = styled(Button)`
  width: 48px;
  height: 48px;
  padding: 0;
  flex-grow: 1;
  flex-shrink: 0;
  border-radius: 4px;
  & svg {
    width: 22px;
    height: 22px;
  }
`;

export const PriceSelectWrapper = styled(Box)`
  width: 285px;
`;
