import React, { useMemo } from "react";
import { Modal, Button } from "ui";
import DialogCore from "../../DialogCore/DialogCore";
import { AnonymousForm } from "../AnonymousForm/AnonymousForm";
import { RegisteredForm } from "../RegisteredForm/RegisteredForm";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../../redux/user";
import * as yup from "yup";
import validation from "../../../../constants/validation";
import { customFetch } from "../../../../utils";
import { toast } from "react-toastify";
import { CircleCheckIcon } from "../../../../ui/icons";
import { RequestMethods } from "../../../../utils/customFetch";
import { MarvinImage } from "../../../../features/marvin-image";
import { ImageWrapper } from "./RequestAnalogsDialog.styled";
import { useQuery } from "../../../../hooks";
import { AmplitudeEvents } from "../../../../context/AmplitudeEvents";
import { useAmplitudeState } from "../../../../context/AmplitudeContext";
import { generateInfoForRequestAnalogs } from "../../../lib/Analogs.ultils";

type RequestAnalogsFormValues = {
  email?: string;
  name?: string;
  text: string;
  subject: string;
  isAuth: boolean;
};

type RequestAnalogsDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  code?: string;
};

export const RequestAnalogsDialog = ({
  open,
  setOpen,
  code,
}: RequestAnalogsDialogProps) => {
  const { amplitude } = useAmplitudeState();
  const query = useQuery();
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const isDrawSearch = query.get("drawSearch");
  const q = query.get("q");
  const cat = query.get("cat");
  const sstype = query.get("sstype");
  const searchBy = query.get("searchBy");

  const requestAnalogsPayloadInfo = useMemo(() => {
    return generateInfoForRequestAnalogs({
      cat,
      searchBy,
      sstype,
      isDrawSearch,
    });
  }, [cat, searchBy, sstype, isDrawSearch]);

  const handleSubmit = async (formValues: RequestAnalogsFormValues) => {
    const params = isAuth
      ? {
          url: "/customers/current/requests/PRODUCT_ANALOGS",
          method: RequestMethods.POST,
          data: {
            text: formValues.text,
            subject: formValues.subject,
            ...requestAnalogsPayloadInfo,
          },
        }
      : {
          url: "/customers/anonymous/requests/PRODUCT_ANALOGS",
          method: RequestMethods.POST,
          data: {
            email: formValues.email,
            name: formValues.name,
            text: formValues.text,
            subject: formValues.subject,
            ...(formValues.company && { company: formValues.company }),
            ...requestAnalogsPayloadInfo,
          },
        };

    const response = await customFetch(params);
    amplitude.logEvent(AmplitudeEvents.Analogs.ANALOGS_SENT);

    if (response[1]) {
      toast.error(response[1]);
    } else {
      toast.success("Your request has been successfully sent", {
        icon: <CircleCheckIcon />,
      });
      setOpen(false);
    }
  };

  const formik = useFormik<RequestAnalogsFormValues>({
    initialValues: {
      text:
        (code || q) &&
        `I’d like to have an analogs list prepared for a product ${code || q}`,
      subject: code || q,
      email: "",
      company: "",
      name: "",
      isAuth,
    },
    validationSchema: yup.object({
      email: yup.string().when("isAuth", { is: false, then: validation.EMAIL }),
      company: yup.string(),
      name: yup.string().when("isAuth", {
        is: false,
        then: validation.FULL_NAME.required("Full name is required"),
      }),
      text: validation.COMMENT.required("Comment is required"),
    }),
    onSubmit: async (formValues) => {
      await handleSubmit(formValues);
    },
  });

  return (
    <DialogCore
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: {
          maxWidth: "500px",
          width: "100%",
        },
      }}
      title="Request Analogs"
    >
      {isDrawSearch && (
        <ImageWrapper>
          <MarvinImage smile={q} />
        </ImageWrapper>
      )}
      {isAuth ? (
        <RegisteredForm formik={formik} />
      ) : (
        <AnonymousForm formik={formik} />
      )}
      <Modal.Buttons>
        <Button fullWidth type="submit" onClick={formik.handleSubmit}>
          Send request
        </Button>
      </Modal.Buttons>
    </DialogCore>
  );
};
