import React from "react";
import { Button, Section } from "../index";
import { useHistory } from "react-router-dom";
import { CartMessage } from "ui/cart-message/cart-message";
import {
  ButtonWrapper,
  HeaderTypography,
  Wrapper,
} from "./EmptyCartView.styled";
import { Container } from "@mui/material";

const EmptyCartView = () => {
  const history = useHistory();
  const handleSearchClick = () => {
    history.push("/");
  };

  return (
    <Section>
      <Container>
        <Wrapper>
          <HeaderTypography variant="h1">Shopping cart</HeaderTypography>
        </Wrapper>
        <CartMessage variant="empty">
          <CartMessage.Title>Your cart is empty</CartMessage.Title>
          <CartMessage.Description>
            It seems you have not added anything to your cart yet. Explore our
            catalog below.
          </CartMessage.Description>
          <ButtonWrapper>
            <Button size="small" type="submit" onClick={handleSearchClick}>
              Search compounds
            </Button>
          </ButtonWrapper>
        </CartMessage>
      </Container>
    </Section>
  );
};

export default EmptyCartView;
