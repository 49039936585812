import { ProductWeightStandard } from "shared/types/product";

const selectSuperCart = (state) => {
  return state.superCart;
};

const selectCartItemsWithImages = (state) => state.superCart.itemsWithImages;
const selectCartSummary = (state) => state.superCart.summary;

const selectCartItemsInStock = (state) =>
  state.superCart.itemsWithImages?.filter((cartItem) => {
    const foundPrice = cartItem?.prices?.all.find(
      (price) =>
        price?.weight?.amount === cartItem?.addedToBucket?.weight?.amount &&
        price?.weight?.measure === cartItem?.addedToBucket?.weight?.measure
    );
    return foundPrice?.delivery.available === "IN_STOCK";
  });

const selectCartItemsSynthesis = (state) =>
  state.superCart.itemsWithImages?.filter((cartItem) => {
    const foundPrice = cartItem?.prices?.all.find(
      (price) =>
        price?.weight?.amount === cartItem?.addedToBucket?.weight?.amount &&
        price?.weight?.measure === cartItem?.addedToBucket?.weight?.measure
    );

    return (
      foundPrice?.delivery.available === "SYNTHESIS" ||
      cartItem?.addedToBucket?.weight?.type ===
        ProductWeightStandard.WEIGHT_IS_NON_STANDARD
    );
  });

const superCartSelectors = {
  selectSuperCart,
  selectCartItemsWithImages,
  selectCartItemsInStock,
  selectCartItemsSynthesis,
  selectCartSummary,
};

export default superCartSelectors;
