import productTypes, { ProductTypesV2 } from "constants/productTypes";
import { SearchStatus } from "../model/Catalog.types";
import { CatalogConfig } from "../model/Catalog.constants";

export const categoriesOrder = [
  productTypes.BB,
  productTypes.MADE,
  productTypes.REALDB,
  productTypes.SCR,
  productTypes.EBC,
];

export const generateList = (categories, selectedCategory) => {
  const categoriesList = Object.entries(categories);
  return categoriesOrder.reduce((list, currentCategoryKey) => {
    const item = categoriesList.find(([key]) => key === currentCategoryKey);

    if (item) {
      const [key, value] = item;
      return [
        ...list,
        {
          id: key,
          title: value,
          active: selectedCategory === key,
        },
      ];
    }
    return list;
  }, []);
};

export const convertSearchStatusIntoMessage = (completeState: string) => {
  return (
    SearchStatus[completeState] ??
    completeState
      .toLowerCase()
      .replace(/^_*(.)|_+(.)/g, (pattern, replacement, replacement2) =>
        replacement
          ? replacement.toUpperCase()
          : " " + replacement2.toUpperCase()
      ) + " ..."
  );
};

export const getConfigByCatalog = (catalog: ProductTypesV2) => {
  return {
    config: catalog ? CatalogConfig[catalog] : CatalogConfig[ProductTypesV2.BB],
    separators: [],
    limit: 7,
  };
};
