import React from "react";
import { PurityIconWrapper, PurityWrapper } from "./ProductPurity.styled";
import { BeakerAlertIcon, BeakerCheckIcon } from "ui/icons";

type ProductPurityProps = {
  purityValue: string | number;
};

export const ProductPurity = ({ purityValue }: ProductPurityProps) => {
  return (
    <PurityWrapper>
      <PurityIconWrapper>
        {Number(purityValue) >= 90 ? <BeakerCheckIcon /> : <BeakerAlertIcon />}
      </PurityIconWrapper>
      {`${purityValue}%`}
    </PurityWrapper>
  );
};
