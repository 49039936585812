import { Button, IconButton, Typography } from "@mui/material";
import styled from "styled-components";
import { accentColor, blue, darkBlue } from "ui/colors";

export const TooltipWrapper = styled.div`
  padding: 18px;
  background: #ffffff;
  border-radius: 8px;
  max-width: 346px;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
`;

export const TitleTypography = styled(Typography)`
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  color: ${darkBlue};
`;

export const TextTypography = styled(Typography)`
  margin-bottom: 18px;
  line-height: 24px;
  letter-spacing: 0;
  color: ${darkBlue};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  border-radius: 8px;
  min-width: unset;
  min-height: unset;
`;

export const StyledNextButton = styled(StyledButton)`
  margin-left: auto;
`;

export const CloseButtonStyled = styled(IconButton)`
  padding: 0;
  line-height: 1;

  svg path {
    fill: ${blue};
  }

  &:hover {
    background-color: transparent;
    svg path {
      fill: ${accentColor};
    }
  }
`;
