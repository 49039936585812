import { AddressEntity, ContactPersonEntity } from "./user";

export type OrderEventsType<T> = { status: T; time: string };

export enum OrderEventsPayStatusLabels {
  ORDER_PAY_ABSENT = "Unpaid",
  ORDER_PAY_DONE = "Paid",
  ORDER_PAY_RETURNED = "Returned",
}

export enum OrderEventsPayStatusTypes {
  ORDER_PAY_ABSENT = "ORDER_PAY_ABSENT",
  ORDER_PAY_DONE = "ORDER_PAY_DONE",
  ORDER_PAY_RETURNED = "ORDER_PAY_RETURNED",
}

export enum OrderItemEventsStatusTypes {
  ITEM_PROCESSING = "ITEM_PROCESSING",
  ITEM_REGISTERED_FOR_SHIPPING = "ITEM_REGISTERED_FOR_SHIPPING",
  ITEM_ACTION_REQUIRED = "ITEM_ACTION_REQUIRED",
  ITEM_SYNTHESIS_ALLOWED = "ITEM_SYNTHESIS_ALLOWED",
  ITEM_SYNTHESIS_COMPLETED = "ITEM_SYNTHESIS_COMPLETED",
  ITEM_SYNTHESIS_FAILED = "ITEM_SYNTHESIS_FAILED",
  ITEM_QUALITY_CONTROL_IN_PROGRESS = "ITEM_QUALITY_CONTROL_IN_PROGRESS",
  ITEM_QUALITY_CONTROL_FINISHED = "ITEM_QUALITY_CONTROL_FINISHED",
  ITEM_CANCELLED = "ITEM_CANCELLED",
  ITEM_PREPARED_FOR_SHIPPING = "ITEM_PREPARED_FOR_SHIPPING",
  ITEM_LOCATED_AT_WAREHOUSE = "ITEM_LOCATED_AT_WAREHOUSE",
  ITEM_PACKED = "ITEM_PACKED",
  ITEM_RETURNED = "ITEM_RETURNED",
  ITEM_SHIPPED = "ITEM_SHIPPED",
}

export enum OrderEventsOrderLabels {
  ORDER_PROCESSING = "Processing",
  ORDER_PARTIALLY_SHIPPED = "Partially shipped",
  ORDER_SHIPPED = "Shipped",
  ORDER_COMPLETED = "Completed",
  ORDER_RETURNED = "Returned",
  ORDER_CANCELLED = "Cancelled",
  ORDER_ACTION_REQUIRED = "Processing !Action Required",

  // QUOTE????
  QUOTE_USED = "Order",
}

export enum OrderEventsOrderStatusTypes {
  ORDER_PROCESSING = "ORDER_PROCESSING",
  ORDER_PARTIALLY_SHIPPED = "ORDER_PARTIALLY_SHIPPED",
  ORDER_SHIPPED = "ORDER_SHIPPED",
  ORDER_COMPLETED = "ORDER_COMPLETED",
  ORDER_RETURNED = "ORDER_RETURNED",
  ORDER_CANCELLED = "ORDER_CANCELLED",
  ORDER_ACTION_REQUIRED = "ORDER_ACTION_REQUIRED",

  // QUOTE????
  QUOTE_USED = "QUOTE_USED",
}

export enum OrderType {
  ORDER = "ORDER",
  QUOTE = "QUOTE",
}

export type OrderListItem = {
  uuid: string;
  type: OrderType;
  customerId: number;
  storeId: string;
  comment: any;
  purchaseOrderNumber?: string;
  paymentMethod: PaymentTypes;
  overnightDelivery: boolean;
  partialShipping: boolean;
  addresses: Addresses;
  persons: Persons;
  pay: OrderPay;
  shippingService: ShippingService;
  time: string;

  // Request param: includeEvents: true
  events?: {
    order: OrderEventsType<OrderEventsOrderStatusTypes>[];
    pay?: OrderEventsType<OrderEventsPayStatusTypes>[];
  };
};

export type OrderListItemWithDetailsLink = OrderListItem & {
  detailsLink: string;
};

export type Addresses = {
  billing: AddressEntity;
  shipping: AddressEntity;
  billingSameAsShipping: boolean;
};

export type Shipping = {
  time: string;
  company: string;
  country: string;
  city: string;
  address: string;
  zip: string;
  phone: string;
};

export type Persons = {
  billing: ContactPersonEntity;
  shipping: ContactPersonEntity;
  billingSameAsShipping: boolean;
};

export type OrderPay = {
  currency: string;
  sum: Sum;
  fullyCalculated: boolean;
  cannotCalculate: CannotCalculate;
};

export type Sum = {
  products: number;
  productsDiscount: number;
  productsTotal: number;
  shipping: number;
  handling: number;
  tax: number;
  total: number;
};

export type CannotCalculate = {
  reason: CanNotCalculateReasons;
  details: string;
};

export enum CanNotCalculateReasons {
  DANGEROUS_GOODS_REQUIRE_MANAGER_DECISION = "DANGEROUS_GOODS_REQUIRE_MANAGER_DECISION",
  SHIPPING_HANDLING_FEE_NOT_FOUND_BY_ADDRESSES = "SHIPPING_HANDLING_FEE_NOT_FOUND_BY_ADDRESSES",
  SOME_PRODUCTS_PRICES_NOT_FOUND = "SOME_PRODUCTS_PRICES_NOT_FOUND",
  USA_TAX_NOT_CALCULATED = "USA_TAX_NOT_CALCULATED",
  SOME_PRODUCTS_HAVE_NON_STANDARD_WEIGHT = "SOME_PRODUCTS_HAVE_NON_STANDARD_WEIGHT",
}

export type ShippingService = {
  name: string;
};

export enum PaymentTypes {
  CARD = "CARD",
  CHECK = "CHECK",
  WIRE = "WIRE",
}

export enum PaymentTypesLabels {
  CARD = "Card",
  CHECK = "Check",
  WIRE = "Wire",
}

export enum DeliveryDecision {
  DANGEROUS = "DANGEROUS",
  NON_DANGEROUS = "NON_DANGEROUS",
}

export enum ShippingServiceNames {
  ENAMINE = "Enamine",
  DHL = "DHL",
  FEDEX = "FedEx",
  TNT = "TNT",
  UPS = "UPS",
  CSE = "CSE",
}
