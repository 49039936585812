export const AmplitudeEvents = {
  Checkout: {
    CHECKOUT_CONTACT_BILLING: "checkout_contact_billing",
    CHECKOUT_CONTACT_SHIPPING: "checkout_contact_shipping",
    CHECKOUT_CONTACT_ADD: "checkout_contact_add",
    CHECKOUT_BILLING: "checkout_billing",
    CHECKOUT_SHIPPING: "checkout_shipping",
    CHECKOUT_NEW_ADDRESS: "checkout_new_address",
    CHECKOUT_SHIPPING_RESTRICTION: "Checkout_shipping_restriction",
    CHECKOUT_SHIPPING_OPTIONS: "Checkout_ShippingOptions",
    CHECKOUT_ORDER: "checkout_order",
    CHECKOUT_TO_ORDERS: "checkout_to_orders",
    CHECKOUT_TO_SEARCH: "checkout_to_search",
    CHECKOUT_TO_PAY: "checkout_to_pay",
  },
  Payment: {
    PAYMENT_ERROR: "payment_error",
    CHECKOUT_PAYMENT: "checkout_payment",
  },
  Quote: {
    QUOTE_SENT: "quote_sent",
  },
  Order: {
    ORDER_START: "order_start",
  },
  Analogs: {
    ANALOGS_OPEN: "search_nothing_found_analogs_open",
    ANALOGS_SENT: "search_nothing_found_analogs",
  },
  Onboarding: {
    START: "order_tutorial_start",
    SKIP: "order_tutorial_skip",
    FINISH: "order_tutorial_finished",
  },
};
