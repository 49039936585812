import React, { Dispatch, SetStateAction } from "react";
import {
  DownloadSdsWrapper,
  FullDescriptionWrapper,
  ImageWrapper,
  ProductDescription,
} from "./ProductDetails.styled";
import DownloadSds from "features/download-sds/download-sds";
import { ProductDTO } from "../../typings/DTO/data-contracts";
import { ProductDetailsList } from "./ProductDetailsList/ProductDetailsList";
import reagentsJson from "./reagentsInfo.json";
import { Box } from "@mui/material";

type ProductDetailsProps = {
  productDetailsText: string;
  product: ProductDTO;
  isRealdb: boolean;
  downloadSds: {
    handleDownloadSds: () => void;
    isLoadingSds: boolean;
    notFoundModal: {
      open: boolean;
      setOpen: Dispatch<SetStateAction<boolean>>;
    };
  };
};

export const ProductDetails = ({
  productDetailsText,
  product,
  isRealdb,
  downloadSds,
}: ProductDetailsProps) => {
  // @TODO Remove mocked data after BE implementation
  const foundProduct = reagentsJson.find((localProduct) => {
    return localProduct.id === product.code;
  });

  return (
    <>
      <ProductDescription>{productDetailsText}</ProductDescription>
      {/* @TODO Remove mocked data after BE implementation */}
      {foundProduct && (
        <FullDescriptionWrapper>
          <Box>{foundProduct.description}</Box>

          <ImageWrapper>
            <img src={`/images/reagentImages/${foundProduct.id}.png`} alt="" />
          </ImageWrapper>

          <Box>{foundProduct.fullDescription}</Box>
          <Box>
            <b>Synonyms:</b> {foundProduct.synonyms}
          </Box>
          <Box>{foundProduct.references}</Box>
        </FullDescriptionWrapper>
      )}
      <ProductDetailsList product={product} />
      {!isRealdb && (
        <DownloadSdsWrapper>
          <DownloadSds {...downloadSds} />
        </DownloadSdsWrapper>
      )}
    </>
  );
};
