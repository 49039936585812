// @ts-check

import styled from "styled-components";
import { Button } from "ui";
import { accentColor, darkBlue } from "ui/colors";

export const Wrapper = styled.div`
  display: flex;
  max-width: 940px;
  margin: 0 auto;
  position: relative;
  padding: 36px 10px 0 10px;
  gap: 18px;
  flex-wrap: wrap;
`;

export const Input = styled.input`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  flex-grow: 1;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: ${darkBlue};
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  padding: 0 14px;
  border: 1px white solid;
  min-height: 56px;

  &:focus {
    outline: none;

    border: 1px ${accentColor} solid;
  }
  &:hover {
    border: 1px ${accentColor} solid;
  }
`;

export const SubscribeButton = styled(Button)`
  min-width: 190px;
`;
