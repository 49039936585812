import { Typography } from "@mui/material";
import styled from "styled-components";
import { accentColor } from "ui/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
  padding-top: 32px;
`;

export const HeaderTypography = styled(Typography)`
  line-height: 32px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

export const ContactLink = styled.a`
  color: ${accentColor};
`;
