import { combineReducers } from "redux";
import userReducer from "./user/reducer";
import utilReducer from "./util/reducer";
import catalogReducer from "./catalog/reducer";
import productReducer from "./product/reducer";
import checkoutReducer from "./checkout/reducer";
import errorReducer from "./error/reducer";
import loadersReducer from "features/Loaders/redux/reducer";
import onboardingReducer from "redux/OnBoard/reducer";
import currencyReducer from "./currency/reducer";
import superCartReducer from "features/super-cart/redux/reducer";

const rootReducer = combineReducers({
  superCart: superCartReducer,
  currency: currencyReducer,
  user: userReducer,
  util: utilReducer,
  catalog: catalogReducer,
  product: productReducer,
  checkout: checkoutReducer,
  error: errorReducer,
  loaders: loadersReducer,
  onboarding: onboardingReducer,
});

export default rootReducer;
