import { OrderItemEntity } from "pages/profile/pages/OrderV2/lib/Order.types";
import { BucketItem } from "shared/types/cartItem";
import { ProductPriceIssue } from "shared/types/pricing";
import { ProductWeightStandard } from "shared/types/product";

export const getPriceText = (price, currency) => {
  const defaultPrice = price ?? 0;
  return `${defaultPrice?.toFixed(2)} ${currency}`;
};

export const getCompoundTooltip = (
  synthesisItems?: BucketItem[] | OrderItemEntity[]
): string => {
  const compoundTypes: string[] = [];

  if (
    synthesisItems?.some((item) => {
      const hasNonStandardWeightIssue =
        item?.addedToBucket?.pay?.cannotCalculate?.reason ===
        ProductPriceIssue.SOME_PRODUCTS_HAVE_NON_STANDARD_WEIGHT;

      const hasNonStandardWeightType =
        item?.weight?.type === ProductWeightStandard.WEIGHT_IS_NON_STANDARD;

      return hasNonStandardWeightIssue || hasNonStandardWeightType;
    })
  ) {
    compoundTypes.push("custom weight");
  }

  if (
    synthesisItems?.some((item) => {
      const hasPriceNotFoundIssue =
        item?.addedToBucket?.pay?.cannotCalculate?.reason ===
        ProductPriceIssue.SOME_PRODUCTS_PRICES_NOT_FOUND;

      const hasEnamineStandardWeightIssue =
        item?.weight?.type ===
          ProductWeightStandard.WEIGHT_IS_ENAMINE_STANDARD &&
        !item?.pay?.priceExistedAtPurchaseTime;

      return hasPriceNotFoundIssue || hasEnamineStandardWeightIssue;
    })
  ) {
    compoundTypes.push("feasible");
  }

  return compoundTypes.length > 0
    ? `Order includes ${compoundTypes.join(
        " and "
      )} compounds which will be confirmed by our sales representative`
    : "";
};
