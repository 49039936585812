import React from "react";
import { Button, Section } from "ui";
import { CartMessage } from "ui/cart-message/cart-message";
import {
  ButtonWrapper,
  ContactLink,
  HeaderTypography,
  Wrapper,
} from "./CartErrorView.styled";
import { Container } from "@mui/material";

export const CartErrorView = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <HeaderTypography variant="h1">Shopping cart</HeaderTypography>
        </Wrapper>
        <CartMessage variant="empty">
          <CartMessage.Title>Something went wrong</CartMessage.Title>
          <CartMessage.Description>
            Please try again in a few moments or{" "}
            <ContactLink href="https://enamine.atlassian.net/servicedesk/customer/portal/23">
              contact our team
            </ContactLink>
            .
          </CartMessage.Description>
          <ButtonWrapper>
            <Button
              onClick={() => window.location.reload()}
              size="small"
              fullWidth
            >
              Try again
            </Button>
          </ButtonWrapper>
        </CartMessage>
      </Container>
    </Section>
  );
};
