import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {
  ButtonWrapper,
  CloseButtonStyled,
  Header,
  StyledButton,
  StyledNextButton,
  TextTypography,
  TitleTypography,
  TooltipWrapper,
} from "./OnboardingTooltip.styled";
import { StepMerged, TooltipRenderProps } from "react-joyride";

type OnboardingTooltipProps = {
  skipProps?: TooltipRenderProps["skipProps"];
  primaryProps?: TooltipRenderProps["primaryProps"];
  backProps?: TooltipRenderProps["backProps"];
  tooltipProps?: TooltipRenderProps["tooltipProps"];
  index: number;
  size: number;
  step: StepMerged;
};

export const OnboardingTooltip = ({
  skipProps,
  primaryProps,
  backProps,
  step,
  index,
  size,
  tooltipProps,
}: OnboardingTooltipProps) => {
  const tooltipStyles = step?.styles?.tooltip ?? {};

  const buttonText =
    index === 0 ? "Start" : index < size - 1 ? "Next" : "Finish";

  return (
    <TooltipWrapper style={tooltipStyles} {...tooltipProps}>
      <Header>
        <TitleTypography variant="subtitle1">{step.title}</TitleTypography>
        {index !== 0 && (
          <CloseButtonStyled
            {...skipProps}
            size="medium"
            data-test="close-modal-button"
          >
            <CloseIcon />
          </CloseButtonStyled>
        )}
      </Header>
      <TextTypography variant="body2">{step.content}</TextTypography>
      <ButtonWrapper>
        {index === 0 && (
          <StyledButton
            {...skipProps}
            variant="outlined"
            color="secondary"
            size="small"
          >
            Skip
          </StyledButton>
        )}

        {index > 1 && (
          <StyledButton
            {...backProps}
            variant="outlined"
            color="secondary"
            size="small"
          >
            Back
          </StyledButton>
        )}

        <StyledNextButton
          {...primaryProps}
          variant="contained"
          color="primary"
          size="small"
        >
          {buttonText}
        </StyledNextButton>
      </ButtonWrapper>
    </TooltipWrapper>
  );
};
