import React from "react";
import { getConfigByCatalog } from "../../../lib/Catalog.utils";
import { CatalogViewModes } from "../../../model/Catalog.types";
import { GridCard } from "./GridCard/GridCard";
import { ListCard } from "./ListCard/ListCard";
import SimpleCard from "./SimpleCard/SimpleCard";
import { useProductCardProps } from "../../../../../shared/hooks/useProductCardProps";
import { ProductWithPricesDTO } from "../../../../../typings/DTO/data-contracts";
import { ProductTypesV2 } from "../../../../../constants/productTypes";

// @TODO Specify any
type ViewModesProps = {
  productWithPrices: ProductWithPricesDTO;
  images: any;
  image?: any;
  viewMode: CatalogViewModes;
  isRelated?: boolean;
  // @TODO Check property, and remove if needed
  proposal?: any;
};

export const ViewModes = ({
  productWithPrices = {},
  images,
  image,
  viewMode,
  isRelated,
  // @TODO Check property, and remove if needed
  proposal,
}: ViewModesProps) => {
  const {
    buttonLabel,
    currency,
    readyToCart,
    href,
    handleClick,
    formik,
    handleAddListItemToCart,
    isAddingToCart,
  } = useProductCardProps({
    item: productWithPrices,
    image,
    images,
    isRelated,
    proposal,
  });

  switch (viewMode) {
    case CatalogViewModes.SIMPLE:
      return (
        <SimpleCard
          catalogItem={productWithPrices}
          href={href}
          isAddingToCart={isAddingToCart}
          handleClick={handleClick}
          currency={currency}
          formik={formik}
        />
      );
    case CatalogViewModes.LIST:
      return (
        <ListCard
          catalogItem={productWithPrices}
          code={
            (productWithPrices?.product && productWithPrices?.product?.code) ||
            ""
          }
          handleClick={handleClick}
          href={href}
          isAddingToCart={isAddingToCart}
          handleAddListItemToCart={handleAddListItemToCart}
          currency={currency}
          formik={formik}
        />
      );
    case CatalogViewModes.CARD:
      return (
        <GridCard
          item={productWithPrices}
          infoListConfig={getConfigByCatalog(
            productWithPrices?.product?.catalog as ProductTypesV2
          )}
          code={
            (productWithPrices?.product && productWithPrices?.product?.code) ||
            ""
          }
          href={href}
          formik={formik}
          handleClick={handleClick}
          readyToCart={readyToCart}
          buttonLabel={buttonLabel}
          isAddingToCart={isAddingToCart}
          currency={currency}
        />
      );
    default:
      return (
        <GridCard
          item={productWithPrices}
          infoListConfig={getConfigByCatalog(
            productWithPrices?.product?.catalog as ProductTypesV2
          )}
          code={
            (productWithPrices?.product && productWithPrices?.product?.code) ||
            ""
          }
          href={href}
          formik={formik}
          handleClick={handleClick}
          readyToCart={readyToCart}
          buttonLabel={buttonLabel}
          isAddingToCart={isAddingToCart}
          currency={currency}
        />
      );
  }
};
