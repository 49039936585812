import React from "react";
import { FullWidthLoader } from "../../parts";

import { Breadcrumbs, OneProductCard } from "../../ui/index";
import {
  Wrapper,
  ProductSection,
  Columns,
  Column,
  Image,
  ImageWrapper,
  ProductName,
  InfoChipWrapper,
} from "./Product.styled";
import { ReplacementIcon } from "ui/icons";
import { isEmpty } from "utils";
import { ProductGroupsContainer } from "features/product-groups/product-groups.container";
import { Chip, Container } from "@mui/material";
import ProductNotFound from "./ui/ProductNotFound/ProductNotFound";

const Product = ({
  loading,
  item,
  image,
  price,
  breadcrumbs,
  isAddingToCart,
  code,
  cat,
  currency,
  showProductReplacements,
  onOpenProductReplacements,
  formik,
  buttonLabel,
}) => {
  return (
    <Wrapper className="product-page">
      {loading ? (
        <FullWidthLoader />
      ) : (
        <>
          <Breadcrumbs items={breadcrumbs} />
          <ProductSection>
            <Container>
              {!isEmpty(item) ? (
                <>
                  <Columns>
                    <Column image>
                      <ImageWrapper>
                        <Image
                          style={{
                            backgroundImage: `url(${image})`,
                          }}
                        />
                        <ProductName title={item.name}>{item.name}</ProductName>
                        <InfoChipWrapper>
                          {showProductReplacements && (
                            <Chip
                              label="Product replacement available"
                              size="small"
                              icon={<ReplacementIcon />}
                              onClick={onOpenProductReplacements}
                              disableRipple
                            />
                          )}
                        </InfoChipWrapper>
                      </ImageWrapper>
                    </Column>
                    <Column card>
                      <OneProductCard
                        formik={formik}
                        productItem={item}
                        price={price}
                        buttonLabel={buttonLabel}
                        isAddingToCart={isAddingToCart}
                      />
                    </Column>
                  </Columns>
                  <ProductGroupsContainer
                    code={code}
                    cat={cat}
                    currency={currency}
                  />
                </>
              ) : (
                <ProductNotFound code={code} />
              )}
            </Container>
          </ProductSection>
        </>
      )}
    </Wrapper>
  );
};

export default Product;
