import React from "react";
import { ProductPriceIssue } from "shared/types/pricing";
import { getPriceText } from "shared/utils/Cart.utils";
import { CartItemWithImage } from "../../../../../../shared/types/cart";
import { DeliveryDecision } from "../../../../../../shared/types/cartItem";
import CheckoutCartItem from "./CheckoutCartItem";

type CheckoutCartItemContainerProps = {
  cartItem: CartItemWithImage;
};

const CheckoutCartItemContainer = ({
  cartItem,
}: CheckoutCartItemContainerProps) => {
  const {
    addedToBucket: {
      count,
      pay: { pricePerOneCount, cannotCalculate },

      weight,
      deliveryDecision,
    },
    product: { code },
    prices: { currency },
    image,
  } = cartItem;

  const amount = `${weight.amount}${weight.measure}`;
  const price = `${getPriceText(pricePerOneCount, currency)}`;
  const isDangerousGood = deliveryDecision === DeliveryDecision.DANGEROUS;
  const isCustomWeight =
    cannotCalculate?.reason ===
    ProductPriceIssue.SOME_PRODUCTS_HAVE_NON_STANDARD_WEIGHT;
  const isFeasibleCompound =
    cannotCalculate?.reason ===
    ProductPriceIssue.SOME_PRODUCTS_PRICES_NOT_FOUND;

  return (
    <CheckoutCartItem
      isCustomWeight={isCustomWeight}
      isFeasible={isFeasibleCompound}
      count={count}
      amount={amount}
      price={price}
      title={code}
      image={image}
      isDangerousGood={isDangerousGood}
    />
  );
};

export default CheckoutCartItemContainer;
