// @ts-check
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Tabs from "ui/tabs/Tabs";
import { productSelectors } from "redux/product";
import { productTypes, ProductTypesV2 } from "constants/productTypes";
import { getProductDetailsText } from "utils";
import AnalogsContainer from "features/analogs/analogs.container";
import { InStockEquivalentsContainer } from "ui/instock-equivalents/instock-equivalents.container";
import { ProductSaltFormsContainer } from "ui/product-salt-forms/product-salt-forms.container";
import { ProductDetails } from "ui/ProductDetails/ProductDetails";
import { Wrapper, TabContentWrapper } from "./product-group.styles";
import { events, useAmplitudeState } from "context/AmplitudeContext";
import useDownloadSds from "features/download-sds/hooks/useDownloadSds";

const productDescriptionTabs = {
  Details: "details",
  Equivalents: "equivalents",
  Analogs: "analogs",
};

/**
 * @type {React.FC<
 *  import("react").PropsWithChildren & { tabName: string; activeTab: string }
 * >}
 */
const TabContent = ({ tabName, activeTab, children }) => {
  return tabName == activeTab ? (
    <TabContentWrapper>{children}</TabContentWrapper>
  ) : null;
};

export const ProductGroupsContainer = ({ code, cat, currency }) => {
  const { amplitude } = useAmplitudeState();
  const [activeTab, setActiveTab] = useState(
    productDescriptionTabs.Details
  );
  const productDetailsText = getProductDetailsText({ productType: cat });
  const isRealdb = cat === productTypes.REALDB;
  const downloadSds = useDownloadSds({ code });

  const { product } = useSelector((state) =>
    productSelectors.selectProduct(state, {
      catalog: ProductTypesV2[cat],
      code,
    })
  );

  const analogs = useSelector(productSelectors.selectProductAnalogs);
  const equivalents = useSelector(productSelectors.selectInStockEquivalents);

  useEffect(() => {
    if (equivalents?.items.length) {
      setActiveTab(productDescriptionTabs.Equivalents)
    }
  }, [equivalents]);

  const handleDetailsTabClick = () => {
    setActiveTab(productDescriptionTabs.Details);
    amplitude.logEvent(events.product_page_details_click);
  };

  const handleEquivalentsTabClick = () => {
    setActiveTab(productDescriptionTabs.Equivalents);
    amplitude.logEvent(events.product_page_equivalents_click);
  };

  const handleAnalogsTabClick = () => {
    setActiveTab(productDescriptionTabs.Analogs);
    amplitude.logEvent(events.product_page_analogs_click);
  };

  return (
    <Wrapper>
      <ProductSaltFormsContainer currency={currency?.abbr} />
      <div className="product-groups-tabs">
        <Tabs
          navigation={
            <>
              {Boolean(equivalents?.items?.length) && (
                <Tabs.Button
                  key={productDescriptionTabs.Equivalents}
                  active={activeTab === productDescriptionTabs.Equivalents}
                  onClick={handleEquivalentsTabClick}
                >
                  In-stock Equivalents
                </Tabs.Button>
              )}

              <Tabs.Button
                key={productDescriptionTabs.Details}
                active={activeTab === productDescriptionTabs.Details}
                onClick={handleDetailsTabClick}
              >
                Product Details
              </Tabs.Button>
              <Tabs.Button
                key={productDescriptionTabs.Analogs}
                active={activeTab === productDescriptionTabs.Analogs}
                onClick={handleAnalogsTabClick}
              >
                Product Analogs
              </Tabs.Button>
            </>
          }
        />
        <TabContent
          activeTab={activeTab}
          tabName={productDescriptionTabs.Equivalents}
        >
          <InStockEquivalentsContainer
            onSelectAnalogs={() => setActiveTab(productDescriptionTabs.Analogs)}
            equivalents={equivalents}
          />
        </TabContent>
        <TabContent
          activeTab={activeTab}
          tabName={productDescriptionTabs.Details}
        >
          <ProductDetails
            productDetailsText={productDetailsText}
            product={product}
            isRealdb={isRealdb}
            downloadSds={downloadSds}
          />
        </TabContent>
        <TabContent
          activeTab={activeTab}
          tabName={productDescriptionTabs.Analogs}
        >
          <AnalogsContainer analogs={analogs} code={code} cat={cat} currency={currency?.abbr} />
        </TabContent>
      </div>
    </Wrapper>
  );
};
