import { events } from "../../context/AmplitudeContext";

export const fireInitialEvent = (amplitude, timeNow: number) => {
  const date = new Date(timeNow);
  let month: string | number = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let day: string | number = date.getDate();
  day = day < 10 ? "0" + day : day;
  const year = date.getFullYear();
  const resultDate = `${year}${month}${day}`;

  // Create a new Identify instance
  const identifyObj = new amplitude.Identify();

  // Set properties on the Identify instance
  identifyObj.set("cohortday", resultDate);
  identifyObj.set("visitedNew", "yes");

  // Pass the Identify instance to the identify method
  amplitude.identify(identifyObj);

  // Track the event
  amplitude.track(events.session_start);
};
