import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { FormikProps } from "formik";
import { Weight } from "pages/profile/pages/OrderV2/lib/Order.types";
import * as React from "react";
import { BucketItem } from "shared/types/cartItem";
import { AvailabilityStatus } from "shared/types/delivery";
import CustomWeight from "shared/ui/CustomWeight/CustomWeight";
import { Currency } from "../../../../../../../shared/types/pricing";
import { Price } from "../../../../../../../typings/DTO/data-contracts";
import { CartIcon } from "../../../../../../../ui/icons";
import {
  AddToCartButton,
  TableCellCatalogList,
  TableHeadCatalogList,
} from "../ListCard.styled";
import { ProductWeightStandard } from "shared/types/product";

type CatalogListTableProps = {
  catalogItem: BucketItem;
  currency: Currency;
  handleAddListItemToCart: (
    cardItem: BucketItem,
    selectedCardItemPrice: Price
  ) => void;
  isAddingToCart: boolean;
  formik: FormikProps<{
    counter: number;
    currentWeight: Weight;
    customWeight: Omit<Weight, "type">;
  }>;
};

const ListCardTable = ({
  catalogItem,
  currency,
  handleAddListItemToCart,
  isAddingToCart,
  formik,
}: CatalogListTableProps) => {
  const tableDataRows = [
    ...(catalogItem?.prices?.all?.map((itemPrice) => {
      const pack = `${itemPrice?.weight?.amount} ${itemPrice?.weight?.measure}`;
      const price = `${itemPrice.price} ${currency?.abbr}`;
      const availability =
        itemPrice?.delivery?.available === AvailabilityStatus.IN_STOCK
          ? "In Stock"
          : "Synthesis";
      const stockLocations = itemPrice?.delivery?.stockLocations?.join(", ");

      return {
        pack,
        price,
        availability,
        stockLocations,
      };
    }) || []),
    {
      pack: "Custom weight",
      price: "Inquiry",
      availability: "customWeight",
      stockLocations: "customWeight",
    },
  ];

  const getProductPrice = (index: number): Price => {
    const selectedPrice = catalogItem.prices.all[index];

    return (
      selectedPrice ?? {
        weight: {
          measure: formik.values?.customWeight?.measure,
          amount: formik.values?.customWeight?.amount,
          type: catalogItem.prices?.all?.some(
            ({ weight }) =>
              weight?.measure === formik.values?.customWeight?.measure &&
              weight?.amount === formik.values?.customWeight?.amount
          )
            ? ProductWeightStandard.WEIGHT_IS_ENAMINE_STANDARD
            : ProductWeightStandard.WEIGHT_IS_NON_STANDARD,
        },
      }
    );
  };

  return (
    <TableContainer component={Paper} sx={{ maxWidth: "100%" }}>
      <Table sx={{ border: "none" }}>
        <TableHeadCatalogList>
          <TableRow>
            <TableCellCatalogList>Pack</TableCellCatalogList>
            <TableCellCatalogList align="right">Price</TableCellCatalogList>
            <TableCellCatalogList align="right">
              Availability
            </TableCellCatalogList>
            <TableCellCatalogList align="right">Location</TableCellCatalogList>
            <TableCellCatalogList align="right"></TableCellCatalogList>
          </TableRow>
        </TableHeadCatalogList>
        <TableBody>
          {tableDataRows?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCellCatalogList
                sx={{ fontWeight: "bold" }}
                component="th"
                scope="row"
              >
                {row.pack}
              </TableCellCatalogList>
              <TableCellCatalogList align="right">
                {row.price}
              </TableCellCatalogList>
              {row.availability === "customWeight" ? (
                <TableCellCatalogList align="right" colspan={2}>
                  <CustomWeight formik={formik} />
                </TableCellCatalogList>
              ) : (
                <TableCellCatalogList align="right">
                  {row.availability}
                </TableCellCatalogList>
              )}

              {row.stockLocations !== "customWeight" && (
                <TableCellCatalogList align="right">
                  {row.stockLocations}
                </TableCellCatalogList>
              )}
              <TableCellCatalogList align="right">
                <AddToCartButton
                  iconLeft={<CartIcon />}
                  fullWidth
                  size="small"
                  onClick={() =>
                    handleAddListItemToCart(catalogItem, getProductPrice(index))
                  }
                  isLoading={isAddingToCart}
                  data-test="add_to_cart_button"
                  disabled={!!formik.errors?.customWeight?.amount}
                />
              </TableCellCatalogList>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListCardTable;
