import React from "react";
import {
  DescriptionWrapper,
  Image,
  InfoWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from "./RestrictedItem.styled";
import { RestrictedCartItem } from "../../RestrictionDialog.types";
import { getPriceText } from "../../../../../../../shared/utils/Cart.utils";

type RestrictedItemProps = Partial<RestrictedCartItem>;

const RestrictedItem = ({
  code,
  image,
  quantity,
  weight,
  price,
  currency,
}: RestrictedItemProps) => {
  const priceText = `${getPriceText(price, currency)}`;
  return (
    <Wrapper>
      <Image style={{ backgroundImage: `url(${image.url})` }} />

      <InfoWrapper>
        <TitleWrapper>
          <Title variant="body1">{code}</Title>x {quantity} x {weight?.amount} x{" "}
          {weight?.measure}
        </TitleWrapper>
        <DescriptionWrapper>{priceText}</DescriptionWrapper>
      </InfoWrapper>
    </Wrapper>
  );
};

export default RestrictedItem;
