import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link as LinkMui } from "@mui/material";

const Link = ({ children, to, disableDecoration, ...rest }) => {
  return (
    <LinkMui
      component={RouterLink}
      to={to}
      style={{
        textDecoration: disableDecoration ? "none" : "underline",
      }}
      {...rest}
    >
      {children}
    </LinkMui>
  );
};

export default Link;
