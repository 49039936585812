import React from "react";
import { Wrapper, Cells, ControlButton, Input, Loader } from "./Counter.styled";
import { LinearLoader } from "../../../../ui/index";
import { MinusIcon, PlusIcon } from "ui/icons";
import { FormikProps, FormikValues } from "formik";
type CounterProps = {
  formik: FormikProps<FormikValues>;
  isLoading: boolean;
  variant?: "white";
};
const Counter = ({ formik, isLoading, variant }: CounterProps) => {
  const count = formik.values.counter;
  const handleIncrement = () => {
    formik.setFieldValue("counter", count + 1);
  };
  const handleDecrement = () => {
    if (count > 1) {
      formik.setFieldValue("counter", count - 1);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericValue = parseInt(inputValue.replace(/[^0-9]/g, ""), 10);
    if (!isNaN(numericValue)) {
      const value = Math.max(1, numericValue);
      formik.setFieldValue("counter", value);
    }
  };
  return (
    <Wrapper variant={variant} isLoading={isLoading}>
      <Cells>
        <ControlButton
          type="button"
          onClick={handleDecrement}
          disabled={count <= 1}
          position="left"
        >
          <MinusIcon />
        </ControlButton>
        <Input value={count} onChange={handleChange} />
        <ControlButton onClick={handleIncrement} position="right" type="button">
          <PlusIcon />
        </ControlButton>
      </Cells>
      {isLoading && (
        <Loader>
          <LinearLoader />
        </Loader>
      )}
    </Wrapper>
  );
};
export default Counter;
