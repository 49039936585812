import { ProductTypesV2 } from "constants/productTypes";
import { BucketItem, BucketItemWithImage } from "shared/types/cartItem";
import { Currency } from "shared/types/pricing";
import { CartItemWithImage } from "../../../shared/types/cart";

export const addItem = (
  cartItems: BucketItem[] | null,
  itemToAdd: BucketItem
): BucketItem[] => {
  const existingItems = cartItems ?? [];

  const foundItemIndex = existingItems.findIndex(
    (item) =>
      item.product.code === itemToAdd.product.code &&
      item.addedToBucket.weight.amount === itemToAdd.addedToBucket.weight.amount
  );

  if (foundItemIndex === -1) {
    return [...existingItems, itemToAdd];
  }

  const updatedItems = [...existingItems];
  updatedItems[foundItemIndex] = {
    ...updatedItems[foundItemIndex],
    addedToBucket: {
      ...updatedItems[foundItemIndex].addedToBucket,
      count:
        updatedItems[foundItemIndex].addedToBucket.count +
        itemToAdd.addedToBucket.count,
    },
  };

  return updatedItems;
};

export const addPrices = (prices, pricesToAdd) => {
  if (prices) {
    const newPrices = { ...prices };

    Object.keys(pricesToAdd).forEach((priceKey) => {
      newPrices[priceKey] = pricesToAdd[priceKey];
    });

    return newPrices;
  } else {
    return pricesToAdd;
  }
};

export const addImages = (items, itemsToAdd) => {
  return addPrices(items, itemsToAdd);
};

export const allToCart = (
  cartItems: BucketItemWithImage[] | null,
  itemsToAdd
) => {
  let newItems = cartItems ? [...cartItems] : [];

  itemsToAdd.forEach((itemToAdd) => {
    newItems = addItem(newItems, itemToAdd);
  });

  return newItems;
};

export const deleteItem = (
  items: BucketItemWithImage[],
  uuidToDelete: string
) => {
  const newItems = [...items];

  const findedIdx = items.findIndex(
    (item) => item.addedToBucket.uuid === uuidToDelete
  );

  if (findedIdx !== -1) {
    newItems.splice(findedIdx, 1);
  }

  return newItems;
};

export const getImagelessItems = ({ items, images }) => {
  const imageless = [];
  const currentDate = Date.now();

  items.forEach((item) => {
    const key = `${item.product.code}_${item.product.catalog}`;

    if (!images?.[key] || currentDate - images?.[key]?.updatedAt > 3_600_000) {
      imageless.push(item);
    }
  });
  return imageless;
};

export const generateImagesObj = (items) => {
  const imagesObj = {};

  items.forEach((item) => {
    const key = `${item.code}_${item.catalog}`;
    const itemToObj = {
      updatedAt: Date.now(),
      data: item,
      key,
    };
    imagesObj[key] = itemToObj;
  });

  return imagesObj;
};

export const seedPrices = ({ items, currency, prices }) => {
  const newItems = [];

  items.forEach((item) => {
    const key = `${item.product.code}_${item.productType}_${currency}`;
    const itemPrices = prices?.[key]?.data;

    newItems.push({
      ...item,
      prices: itemPrices,
    });
  });

  return newItems;
};

export const seedImages = ({
  items,
  images,
}: {
  items: BucketItem[];
  images: {
    [key: string]: {
      data: {
        catalog: ProductTypesV2;
      };
      code: string;
      url: string;
      updatedAt: number;
    };
  };
}) => {
  const newItems = [];

  items.forEach((item) => {
    const key = `${item.product.code}_${item?.product?.catalog}`;
    const image = images?.[key]?.data;

    newItems.push({
      ...item,
      image,
    });
  });

  return newItems;
};

export const updateItem = (
  items: CartItemWithImage[],
  updatedItem: CartItemWithImage
): CartItemWithImage[] => {
  let merged = false;

  return items
    .map((item) => {
      if (item.addedToBucket.uuid === updatedItem.addedToBucket.uuid) {
        return { ...item, ...updatedItem };
      }

      if (
        item.product.code === updatedItem.product.code &&
        item.addedToBucket.weight.measure ===
          updatedItem.addedToBucket.weight.measure &&
        item.addedToBucket.weight.amount ===
          updatedItem.addedToBucket.weight.amount &&
        item.addedToBucket.weight.type === updatedItem.addedToBucket.weight.type
      ) {
        merged = true;
        return {
          ...item,
          addedToBucket: {
            ...item.addedToBucket,
            count: (
              Number(item.addedToBucket.count) +
              Number(updatedItem.addedToBucket.count)
            ).toString(),
          },
        };
      }

      return item;
    })
    .filter((item) =>
      merged ? item.addedToBucket.uuid !== updatedItem.addedToBucket.uuid : true
    );
};

export const calculateTotal = (cartItems: CartItemWithImage[] | null) => {
  if (!cartItems || cartItems.length === 0) {
    return 0;
  }

  const totalSum = cartItems.reduce((acc, cartItem) => {
    return acc + (cartItem?.addedToBucket?.pay?.total || 0);
  }, 0);

  return totalSum;
};

export const prepareItemToAdd = ({ item, currentPrice, count = 1 }) => {
  const { product } = item;
  const { weight } = currentPrice;

  const itemToAdd = {
    code: product.code,
    catalog: product.catalog,
    count,
    weight,
  };

  return itemToAdd;
};

export const setItems = (items: BucketItem[]) => {
  if (!Array.isArray(items)) {
    return [];
  }

  return items.map((cartItem) => ({ ...cartItem }));
};

export const getPrice = (item: BucketItem, currency: Currency) => {
  let res = "";

  const allPrices = item?.prices?.all.find(
    (el) => el.weight.amount === item.addedToBucket.weight.amount
  );

  const quantity = item?.addedToBucket.count;
  const isInquiry = item?.addedToBucket.pay.fullyCalculated;
  const measure = item?.addedToBucket.weight.measure;
  const amount = item?.addedToBucket.weight.amount;
  const measureFactor = measure === "g" ? 1000 : 1;

  const stock = item?.stock;
  const desire = quantity * amount * measureFactor;

  if (isInquiry || stock < desire) {
    return "Inquiry";
  }

  if (allPrices?.price) {
    res = `${allPrices?.price.toFixed(2)} ${currency.abbr}`;
  }

  return res;
};

export const getDiscountPrice = (item: BucketItem, currency: Currency) => {
  let res = "";

  const sample = item?.prices?.all.find(
    (el) => el.weight.amount === item.addedToBucket.weight.amount
  );

  if (sample?.price) {
    if (sample?.priceOld > sample?.price) {
      res = `${sample?.priceOld.toFixed(2)} ${currency.abbr}`;
    }
  }

  return res;
};

export const generateSubtotal = ({ price, abbr }) => {
  let res = "";

  if (price) {
    res = `${price?.toFixed(2)} ${abbr}`;
  } else {
    res = `0.00 ${abbr}`;
  }

  return res;
};

export const getUniqueCodes = (items) => {
  const codesObj = {};
  const resultArr = [];

  items.forEach((item) => {
    codesObj[`${item.code}---${item.productType}`] = true;
  });

  Object.keys(codesObj).forEach((item) => {
    const splitted = item.split("---");

    resultArr.push({
      code: splitted[0],
      productType: splitted[1],
    });
  });

  return resultArr;
};

export const generateItemsWithImages = (superCart): BucketItemWithImage[] => {
  const listWithImages = seedImages({
    items: superCart.items,
    images: superCart.images,
  });

  return listWithImages;
};

// V2

export const deleteItems = (
  cartItems: CartItemWithImage[],
  uuidsToDelete: string[]
) => {
  return cartItems.filter(
    (cartItem) => !uuidsToDelete.includes(cartItem.addedToBucket.uuid)
  );
};
