import React from "react";
import { ProductTypesV2 } from "constants/productTypes";
import { CatalogViewModes } from "../../../../constants/catalogViewModes";
import { useProductCardProps } from "shared/hooks/useProductCardProps";
import { GridCard } from "../../../CatalogV2/ui/Content/ViewModes/GridCard/GridCard";
import { ListCard } from "../../../CatalogV2/ui/Content/ViewModes/ListCard/ListCard";

const config = {
  [ProductTypesV2.BB]: {
    catalog: { title: "Product class", order: 1 },
    CAS: { title: "CAS number", order: 2 },
    molecularWeight: { title: "MW", order: 3 },
    formula: { title: "Formula", order: 4 },
    transportConditions: { title: "Transport conditions", order: 5 },
    storageConditions: { title: "Storage conditions", order: 6 },
  },
  [ProductTypesV2.SCR]: {
    catalog: { title: "Product class", order: 1 },
    // @TODO https://enamine.atlassian.net/browse/SHOP-6050 temporary hidden
    // collection: { title: "Screening Collection", order: 2 },
    molecularWeight: { title: "MW", order: 3 },
    formula: { title: "Formula", order: 4 },
    transportConditions: { title: "Transport conditions", order: 5 },
    storageConditions: { title: "Storage conditions", order: 6 },
  },
  [ProductTypesV2.EBC]: {
    catalog: { title: "Product class", order: 1 },
    name: { title: "Name", order: 2 },
    CAS: { title: "CAS number", order: 3 },
    formula: { title: "Formula", order: 4 },
    transportConditions: { title: "Transport conditions", order: 5 },
    storageConditions: { title: "Storage conditions", order: 6 },
  },
  [ProductTypesV2.MADE]: {
    catalog: { title: "Product class", order: 1 },
    name: { title: "Name", order: 2 },
    CAS: { title: "CAS number", order: 3 },
    formula: { title: "Formula", order: 4 },
    transportConditions: { title: "Transport conditions", order: 5 },
    storageConditions: { title: "Storage conditions", order: 6 },
  },
  [ProductTypesV2.REALDB]: {
    catalog: { title: "Product class", order: 1 },
    clogp: { title: "CLogP", order: 2 },
    molecularWeight: { title: "MW", order: 3 },
    tpsa: { title: "TPSA", order: 4 },
    transportConditions: { title: "Transport conditions", order: 5 },
    storageConditions: { title: "Storage conditions", order: 6 },
  },
};

const getConfigByCatalog = (catalog: ProductTypesV2) => {
  return {
    config: catalog ? config[catalog] : config[ProductTypesV2.BB],
    separators: [],
    limit: 6,
  };
};

type CatalogCardContainerProps = {
  item: any;
  images: any;
  image?: any;
  viewMode: CatalogViewModes;
  isRelated?: any;
  proposal?: any;
};

export const CatalogCardContainer = ({
  item,
  viewMode,
  isRelated,
  proposal,
}: CatalogCardContainerProps) => {
  const {
    buttonLabel,
    currency,
    currentPrice,
    readyToCart,
    href,
    handleClick,
    formik,
    handleAddListItemToCart,
    isAddingToCart,
  } = useProductCardProps({
    item,
    isRelated,
    proposal,
  });

  switch (viewMode) {
    case CatalogViewModes.SIMPLE:
      return <></>;
    case CatalogViewModes.LIST:
      return (
        <ListCard
          catalogItem={item}
          code={(item?.product && item?.product?.code) || ""}
          handleClick={handleClick}
          href={href}
          isAddingToCart={isAddingToCart}
          handleAddListItemToCart={handleAddListItemToCart}
          currency={currency}
          formik={formik}
        />
      );
    case CatalogViewModes.CARD:
      return (
        <GridCard
          item={item}
          code={(item?.product && item?.product?.code) || ""}
          handleClick={handleClick}
          href={href}
          formik={formik}
          isAddingToCart={isAddingToCart}
          handleAddListItemToCart={handleAddListItemToCart}
          currency={currency}
        />
      );
    default:
      return (
        <GridCard
          item={item}
          infoListConfig={getConfigByCatalog(item?.product?.catalog)}
          code={(item?.product && item?.product?.code) || ""}
          href={href}
          formik={formik}
          handleClick={handleClick}
          readyToCart={readyToCart}
          buttonLabel={buttonLabel}
          isAddingToCart={isAddingToCart}
          currency={currency}
          currentPrice={currentPrice}
        />
      );
  }
};
