// @ts-check
import React from "react";
import { productForms as descriptionText } from "constants/productGroups";
import { ProductsLoader, Title } from "ui";
import { ProductGrid, ProductItem } from "ui/product-grid/product-grid.styles";
import { Wrapper } from "./product-salt-forms.styles";
import { ProductDescription } from "ui/ProductDetails/ProductDetails.styled";
import { proposalKeys } from "constants/index";
import ProductCardContainer from "../../pages/Product/ui/ProductCard/ProductCard.container";

/**
 * @type {React.FC<{
 *  productForms: import("features/product-groups/data/productInfo").ProductInfoFull[];
 *  loading: boolean
 * }>}
 */
export const ProductSaltForms = ({ productForms, loading }) => {
  return (
    <Wrapper>
      <Title>Product Forms</Title>
      <ProductDescription>{descriptionText}</ProductDescription>
      {loading ? (
        <ProductsLoader />
      ) : (
        <ProductGrid>
          {productForms.map((item) => {
            return (
              <ProductItem key={item?.product?.code}>
                <ProductCardContainer
                  item={item}
                  isRelated={false}
                  listMode={false}
                  proposal={proposalKeys.form}
                />
              </ProductItem>
            );
          })}
        </ProductGrid>
      )}
    </Wrapper>
  );
};
