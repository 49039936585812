// @TODO Check this specific constant, use ENUM instead and move it into shared or catalog scope
import searchAsTypes from "../../../constants/searchAsTypes";

const SstypeTitles = {
  [searchAsTypes.EXACT]: "Exact",
  [searchAsTypes.SUB]: "Substructure",
  [searchAsTypes.SIM]: "Similarity",
};

export const sstypeOptions = [
  {
    id: searchAsTypes.EXACT,
    title: SstypeTitles[searchAsTypes.EXACT],
    active: false,
  },
  {
    id: searchAsTypes.SUB,
    title: SstypeTitles[searchAsTypes.SUB],
    active: false,
  },
  {
    id: searchAsTypes.SIM,
    title: SstypeTitles[searchAsTypes.SIM],
    active: false,
  },
];
