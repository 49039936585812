const selectData = (state) => {
  return state.checkout.data;
};

const selectPaymentMethods = (state) => {
  return state.checkout.paymentMethods;
};

const selectAccordion = (state) => {
  return state.checkout.accordion;
};

// V2

const selectPersonBillingUuid = (state) =>
  state.checkout.requestData?.persons?.billingUuid;
const selectPersonShippingUuid = (state) =>
  state.checkout.requestData?.persons?.shippingUuid;

const selectAddressBillingUuid = (state) =>
  state.checkout.requestData?.addresses?.billingUuid;
const selectAddressShippingUuid = (state) =>
  state.checkout.requestData?.addresses?.shippingUuid;

const selectShippingService = (state) =>
  state.checkout.requestData.shippingService;

const selectPartialShipping = (state) =>
  state.checkout.requestData.partialShipping;

const selectRequestData = (state) => state.checkout.requestData;

// @TODO Probably could be removed use selectCalculations
const selectCalculatedPaymentMethods = (state) => {
  return state.checkout.calculations?.pay?.currencies?.allowed;
};

// @TODO Probably could be removed use selectCalculations
const selectCalculatedSummary = (state) => {
  return state.checkout.calculations?.pay?.sum;
};

const selectCalculations = (state) => {
  return state.checkout.calculations;
};

const selectOvernightDelivery = (state) => {
  return state.checkout?.conditionsByAddressShipping?.overnightDelivery;
};

const selectCheckoutConditions = (state) => {
  return state.checkout?.conditionsByAddressShipping?.checkout;
};

export default {
  selectData,
  selectAccordion,
  selectPaymentMethods,

  // V2
  selectRequestData,

  selectPersonBillingUuid,
  selectPersonShippingUuid,

  selectAddressBillingUuid,
  selectAddressShippingUuid,

  selectShippingService,
  selectPartialShipping,
  selectOvernightDelivery,

  selectCalculatedPaymentMethods,
  selectCalculatedSummary,
  selectCalculations,

  selectCheckoutConditions,
};
