import styled from "styled-components";
import { lightBlue, successColor } from "ui/colors";

export const Wrapper = styled.div`
  background-color: ${lightBlue};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 32px;
  width: 100%;
`;

export const Name = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 32px;
  line-height: 37px;
  word-break: break-all;
`;

export const StockAndCounterGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ListWrapper = styled.div`
  margin-bottom: 28px;
  margin-top: 28px;
`;

export const PriceWrapper = styled.div`
  margin-bottom: 16px;
`;

export const Message = styled.div`
  background: #fff;
  font-size: 14px;
  color: ${successColor};
  padding: 10px 12px;
  line-height: 24px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const PriceSelectWrapper = styled.div`
  svg {
    width: 16px !important;
    height: 16px !important;
  }
`;

export const CustomWeightWrapper = styled.div`
  margin-bottom: 16px;
`;
