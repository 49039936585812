import { useEffect, useState } from "react";
import { customFetch } from "utils";
import { RequestMethods } from "../../../../../utils/customFetch";
import { endpoints } from "constants";
import { isItemShouldHaveMockImage } from "../../../../../shared/lib/REMOVE.utils";

const useFetchOrderItems = (orderId: string) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await fetchOrderItems(orderId);

      if (response[0]) {
        const orderItems = response[0].map((orderItem) => ({
          ...orderItem,
          // @TODO REMOVE THIS LOGIC AFTER DEMO!
          image: isItemShouldHaveMockImage(orderItem.code)
            ? `/images/demoReplaceImages/${orderItem.code}.png`
            : `/api/v2/catalog/all/images/by-code/${orderItem.code}`,
        }));

        setData(orderItems);
      }

      setLoading(false);
    })();
  }, [orderId]);

  return { loading, data };
};

export const fetchOrderItems = (orderId: string) => {
  return customFetch({
    url: `${endpoints.ORDERS_ORDER_CONTENT}?id=${orderId}`,
    method: RequestMethods.POST,
  });
};

export default useFetchOrderItems;
