import {
  ADD_ITEM,
  ALL_TO_CART,
  CLEAR_CART,
  DELETE_ITEM,
  DELETE_ITEMS,
  RESET_CART_INFO,
  SET_CART_INFO,
  SET_ITEMS,
  SET_SUPER_CART,
  SET_SUPER_CART_LOADING,
  SET_SUPER_CART_ERROR,
  UPDATE_ITEM,
} from "./types";
import {
  addItem,
  allToCart,
  deleteItem,
  deleteItems,
  setItems,
  updateItem,
} from "./utils";

const cartInfoInitialState = {
  loading: true,
  data: null,
  error: "",
};

const initialState = {
  itemsWithImages: null,
  summary: null,
  itemsWithImagesLoading: false,
  itemsWithImagesError: false,

  // @TODO We should remove it in the future, cause it deprecated
  cartInfo: cartInfoInitialState,
};

const superCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUPER_CART:
      return {
        ...state,
        ...action.payload,
      };

    case ALL_TO_CART:
      return {
        ...state,
        itemsWithImages: allToCart(state.itemsWithImages, action.payload),
      };

    // V2
    case SET_ITEMS:
      return {
        ...state,
        itemsWithImages: setItems(action.payload),
      };
    case ADD_ITEM:
      return {
        ...state,
        itemsWithImages: addItem(state.itemsWithImages, action.payload),
      };
    case UPDATE_ITEM:
      return {
        ...state,
        itemsWithImages: updateItem(state.itemsWithImages, action.payload),
      };
    case DELETE_ITEM:
      return {
        ...state,
        itemsWithImages: deleteItem(state.itemsWithImages, action.payload),
      };
    case CLEAR_CART:
      return initialState;
    case DELETE_ITEMS:
      return {
        ...state,
        itemsWithImages: deleteItems(state.itemsWithImages, action.payload),
      };
    case SET_SUPER_CART_LOADING:
      return {
        ...state,
        itemsWithImagesLoading: action.payload,
      };
    case SET_SUPER_CART_ERROR:
      return {
        ...state,
        itemsWithImagesError: action.payload,
      };
    default:
      return state;
  }
};

export default superCartReducer;
