import { Container } from "@mui/material";
import { rootPages } from "constants/index";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { onboardingActions } from "redux/OnBoard";
import { HomeLink } from "ui/home-link/HomeLink";
import { FacebookIcon, InstagramIcon, LinkedinIcon, XIcon } from "../icons";
import { Logo } from "../index";
import { Wrapper } from "./Footer.styled";

const Footer = () => {
  const dispatch = useDispatch();

  const handleClickGoToOldVersion = (event) => {
    event.preventDefault();
    dispatch(onboardingActions.setShowSorryPopup(true));
  };

  const currentYear = new Date().getFullYear();

  return (
    <Wrapper className="footer">
      <Container>
        <div className="footer__row footer__row--top">
          <div className="footer__row-content">
            <div className="footer__logo">
              <Logo dataTestAttr="footer-logo" />
            </div>
            <nav>
              <HomeLink to="/" className="footer__link">
                Search
              </HomeLink>
              <Link
                to="/draw-search"
                className="footer__link"
                data-test="footer-nav-item-draw-search"
              >
                Structure Search
              </Link>
              <Link
                to={rootPages.CONTACTS}
                className="footer__link"
                data-test="footer-nav-item-contact-us"
              >
                Contact Us
              </Link>
              <Link
                to="/privacy-policy"
                className="footer__link"
                data-test="footer-nav-item-privacy-policy"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-of-use"
                className="footer__link"
                data-test="footer-nav-item-terms-of-use"
              >
                Terms of Use
              </Link>
            </nav>
          </div>
        </div>
        <div className="footer__row footer__row--bottom">
          <div className="footer__row-content">
            <p className="footer__copyright">
              © {currentYear} EnamineStore. All rights reserved.
            </p>
            <Link
              to=""
              className="footer__link footer__link--small"
              onClick={handleClickGoToOldVersion}
            >
              Back to enaminestore.com
            </Link>
            <div className="footer__social">
              <a
                href="https://www.facebook.com/enamineltd/"
                rel="noreferrer"
                target="_blank"
                className="footer__social-button"
                data-test="social-button-facebook"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://twitter.com/enamineltd"
                rel="noreferrer"
                target="_blank"
                className="footer__social-button"
                data-test="social-button-twitter"
              >
                <XIcon />
              </a>
              <a
                href="https://www.instagram.com/enamine_company/"
                rel="noreferrer"
                target="_blank"
                className="footer__social-button"
                data-test="social-button-instagram"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/enamine-ltd-/"
                rel="noreferrer"
                target="_blank"
                className="footer__social-button"
                data-test="social-button-linkedin"
              >
                <LinkedinIcon />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default Footer;
