import styled from "styled-components";
import { borderColor, lightBlue, lightGray } from "ui/colors";
import { Box } from "@mui/material";

export const Wrapper = styled.div``;

export const ProductSection = styled.div`
  background-color: white;
  padding-top: 5px;
  padding-bottom: 100px;
  padding-right: 20px;
  padding-left: 20px;
`;

export const Columns = styled.div`
  display: flex;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${borderColor};
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  max-height: 400px;
`;

export const Column = styled.div`
  position: relative;
  display: flex;

  ${(props) => {
    if (props.card) {
      return `
        width: 432px;
        padding-left: 16px;
      `;
    }
  }}

  ${(props) => {
    if (props.image) {
      return `
        flex-grow: 1;
        padding-right: 16px;
      `;
    }
  }}
`;

export const ProductName = styled.div`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background-color: ${lightGray};
  font-size: 12px;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoChipWrapper = styled(Box)`
  position: absolute;
  top: 20px;
  left: 20px;
  min-height: 26px;
  svg {
    width: 18px;
    height: 18px;
    margin-left: 12px !important;
  }
  & > * {
    background-color: ${lightBlue};
    &:hover {
      background-color: ${lightBlue};
    }
  }
`;
