import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Order from "./Order";
import {
  fetchOrderByUuid,
  fetchCancelOrderByUuid,
  fetchConvertQuoteToOrderByUuid,
  fetchDownloadQuotePdfByUuid,
} from "../api/Order.api";
import useBackToOrdersLink from "../../ArchivedOrder/hooks/useBackToOrdersLink";
import { Loader } from "../../../../../ui";
import DialogCancelOrder from "./DialogCancelOrder/DialogCancelOrder";
import { customFetchDelayedMiddleware } from "../../../../../context/sseContext";
import { RequestMethods } from "../../../../../utils/customFetch";
import { rootPages } from "../../../../../constants";
import DialogConvertToOrder from "./DialogConvertToOrder/DialogConvertToOrder";
import { toast } from "react-toastify";
import { LoaderWrapper } from "./Order.styled";
import { isItemShouldHaveMockImage } from "../../../../../shared/lib/REMOVE.utils";

export const OrderContainer = () => {
  const history = useHistory();
  const { id: orderId } = useParams<{ id: string }>();
  const backToOrdersLink = useBackToOrdersLink();
  const { data: order, isLoading, refetch } = fetchOrderByUuid(orderId);
  const { mutateAsync: fetchCancelOrder } = fetchCancelOrderByUuid(order?.uuid);
  const { mutateAsync: fetchConvertQuoteToOrder } =
    fetchConvertQuoteToOrderByUuid(order?.uuid);
  const { mutate: fetchDownloadQuotePdf } = fetchDownloadQuotePdfByUuid(
    order?.uuid,
    order?.storeId,
    order?.type
  );

  const [orderWithImages, setOrderWithImages] = useState(null);
  const [cancelOrderDialogOpen, setCancelOrderDialogOpen] = useState(false);
  const [convertToOrderDialogOpen, setConvertToOrderDialogOpen] =
    useState(false);

  useEffect(
    () => {
      if (order) {
        const updatedOrder = {
          ...order,
          items: order.items.map((orderItem) => ({
            ...orderItem,
            // @TODO REMOVE THIS LOGIC AFTER DEMO!
            image: isItemShouldHaveMockImage(orderItem.product.code)
              ? `/images/demoReplaceImages/${orderItem.product.code}`
              : `/api/v2/catalog/all/images/by-code/${orderItem.product.code}`,
          })),
        };
        setOrderWithImages(updatedOrder);
      }
    },
    // eslint-disable-next-line
    [order]
  );

  const handleCancelOrder = async () => {
    const response = await fetchCancelOrder();
    if (response[2] === 200) {
      refetch();
      setCancelOrderDialogOpen(false);
    } else if (response[1]) {
      toast.error(response[1]);
    }
  };

  const handleConvertQuoteToOrder = async (formValues: {
    purchaseOrderNumber: string;
  }) => {
    const result = await fetchConvertQuoteToOrder(formValues);
    if (result[0]) {
      setConvertToOrderDialogOpen(false);
    }
  };

  const handlePayOrder = async () => {
    const response = await customFetchDelayedMiddleware({
      method: RequestMethods.POST,
      url: `/customers/current/purchases/by/uuid/${order.uuid}/payments/lock`,
    });

    if (response[0]) {
      const paymentToken = response[0].paymentToken;

      localStorage.setItem("payment-token", paymentToken);

      history.replace(`/${rootPages.SUBMIT_PAYMENT_FORM}`);
    }

    if (response[1]) {
      toast.error(response[1]);
    }
  };

  const handleOpenDialogCancelOrder = () => {
    setCancelOrderDialogOpen(true);
  };

  const handleOpenDialogConvertToOrder = () => {
    setConvertToOrderDialogOpen(true);
  };

  const handleDownloadPdf = () => {
    void fetchDownloadQuotePdf();
  };

  const handleGoRelatedOrderQuote = () => {
    history.push(
      `/profile/orders/${
        order?.relatedQuote?.storeId || order?.relatedOrder?.storeId
      }${history.location.search}`
    );
  };

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader fullWidth />
      </LoaderWrapper>
    );
  }

  return (
    <>
      {orderWithImages && (
        <Order
          backToOrdersLink={backToOrdersLink}
          isLoading={isLoading}
          order={orderWithImages}
          onCancelOrder={handleOpenDialogCancelOrder}
          onPayOrder={handlePayOrder}
          onDownloadPdf={handleDownloadPdf}
          onConvertToOrder={handleOpenDialogConvertToOrder}
          handleGoRelatedOrderQuote={handleGoRelatedOrderQuote}
        />
      )}

      <DialogCancelOrder
        open={cancelOrderDialogOpen}
        setOpen={(openStatus) => setCancelOrderDialogOpen(openStatus)}
        onCancelOrder={handleCancelOrder}
      />

      <DialogConvertToOrder
        open={convertToOrderDialogOpen}
        setOpen={(openStatus) => setConvertToOrderDialogOpen(openStatus)}
        onConvertQuoteToOrder={handleConvertQuoteToOrder}
      />
    </>
  );
};

export default OrderContainer;
