import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import { blue } from "../../../../ui/colors";

export const Wrapper = styled(Box)`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 18px;

  width: 416px;
  padding: 24px;
  border-radius: 16px;
  background-color: white;
`;

export const TitleTypography = styled(Typography)`
  /* h5 */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: ${blue};

  max-width: 300px;
  margin: 0 auto;
`;
