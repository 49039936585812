export const SET_SUPER_CART = "FEATURES/SUPER_CART/SET_SUPER_CART";
export const ADD_ITEM = "FEATURES/SUPER_CART/ADD_ITEM";
export const UPDATE_ITEM = "FEATURES/SUPER_CART/UPDATE_ITEM";
export const ALL_TO_CART = "FEATURES/SUPER_CART/ALL_TO_CART";
export const DELETE_ITEM = "FEATURES/SUPER_CART/DELETE_ITEM";
export const SET_ITEMS = "FEATURES/SUPER_CART/SET_ITEMS";
export const CLEAR_CART = "FEATURES/SUPER_CART/CLEAR_CART";
export const POST_CART_CONTENT = "FEATURES/SUPER_CART/POST_CART_CONTENT";

export const RESET_CART_INFO = "FEATURES/SUPER_CART/RESET_CART_INFO";
export const REQUEST_CART_INFO = "FEATURES/SUPER_CART/REQUEST_CART_INFO";
export const SET_CART_INFO = "FEATURES/SUPER_CART/SET_CART_INFO";

// V2
export const REQUEST_ITEMS_AND_IMAGES =
  "FEATURES/SUPER_CART/REQUEST_ITEMS_AND_IMAGES";

export const REQUEST_CART_DELETE_ITEMS =
  "FEATURES/SUPER_CART/REQUEST_CART_DELETE_ITEMS";
export const DELETE_ITEMS = "FEATURES/SUPER_CART/DELETE_ITEMS";
export const SET_SUPER_CART_LOADING =
  "FEATURES/SUPER_CART/SET_SUPER_CART_LOADING";
export const SET_SUPER_CART_ERROR = "FEATURES/SUPER_CART/SET_SUPER_CART_ERROR";
