import styled from "@emotion/styled";
import { TextField, Typography } from "@mui/material";
import { accentColor } from "ui/colors";

export const StyledTextField = styled(TextField)<{
  isOneProductCard?: boolean;
}>`
  & .MuiOutlinedInput-root {
    & fieldset {
      ${({ isOneProductCard }) =>
        isOneProductCard && `border-color: transparent !important; `}
    }
  }
`;

export const CustomWeightWrapper = styled.div<{ isOneProductCard?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ isOneProductCard }) => (isOneProductCard ? "16px" : "10px")};

  & > * {
    flex: 1 1 0;
    min-width: 0;
  }
`;

export const BaseNativeSelectContainer = styled.div<{
  isOneProductCard?: boolean;
}>`
  & .MuiNativeSelect-root {
    ${({ isOneProductCard }) => isOneProductCard && "border: transparent;"}

    &:hover {
      outline: 1px solid ${accentColor};
    }
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const InputLabelTypography = styled(Typography)`
  font-size: 14px;
  margin-bottom: 6px;
`;
